import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BusinessHours, Validators } from '@peca/commons';
import { Carrier } from '../../models/carrier.model';

export class CarrierForm {
  private form: FormGroup;

  constructor() {
    this.form = this.buildCarrierForm();
  }

  get instance() {
    return this.form;
  }

  get value() {
    return {
      active: this.form.value.active,
      id: this.form.value.id,
      companyName: this.form.value.companyName,
      cnpj: this.form.value.cnpj,
      commercialName: this.form.value.commercialName,
      tradingName: this.form.value.tradingName,
      responsible: this.form.value.responsible,
      externalReference: this.form.value.externalReference,
      description: this.form.value.description,
      address: this.form.value.address,
      sellersWhitelistIds: this.form.value.sellersWhitelistIds,
      sellersBlacklistIds: this.form.value.sellersBlacklistIds,
      businessHours: this.form.value.businessHours,
    } as Carrier;
  }

  setValue(carrier: Carrier) {
    this.form.get('active')?.setValue(carrier.active);
    this.form.get('id')?.setValue(carrier.id);
    this.form.get('companyName')?.setValue(carrier.companyName);
    this.form.get('cnpj')?.setValue(carrier.cnpj);
    this.form.get('commercialName')?.setValue(carrier.commercialName);
    this.form.get('tradingName')?.setValue(carrier.tradingName);
    this.form.get('externalReference')?.setValue(carrier.externalReference);
    this.form.get('description')?.setValue(carrier.description);
    this.form.get('address')?.setValue(carrier.address);
    this.form.get('sellersBlacklistIds')?.setValue(carrier.sellersBlacklistIds);
    this.form.get('sellersWhitelistIds')?.setValue(carrier.sellersWhitelistIds);
    this.form.get('responsible')?.setValue(carrier.responsible);
    this.setBusinessHours(carrier.businessHours);
  }

  get controls() {
    return {
      active: this.form.get('active') as FormControl,
    };
  }

  private buildCarrierForm() {
    const group = new FormGroup({
      active: new FormControl(''),
      id: new FormControl('', []),
      companyName: new FormControl('', [Validators.required]),
      cnpj: new FormControl('', [Validators.required, Validators.cnpj]),
      commercialName: new FormControl('', [Validators.required]),
      tradingName: new FormControl('', [Validators.required]),
      externalReference: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
      responsible: new FormControl(null),
      address: new FormControl(''),
      sellersWhitelistIds: new FormControl([], []),
      sellersBlacklistIds: new FormControl([], []),
      businessHours: new FormArray([]),
    });

    group.addValidators(this.settingsValidator(group));

    return group;
  }

  setBusinessHours(businessHours: BusinessHours[]) {
    const group = this.form.get('businessHours') as FormArray;
    group.controls = [];
    businessHours.forEach((i) =>
      group.push(
        new FormGroup({
          dayOfWeek: new FormControl(i.dayOfWeek),
          openHour: new FormControl(i.openHour),
          closeHour: new FormControl(i.closeHour),
        })
      )
    );
  }

  private settingsValidator(group: FormGroup) {
    return () => {
      const { sellersWhitelistIds, sellersBlacklistIds } = group.controls;
      const whiteList = sellersWhitelistIds.value as Array<string | number>;
      const blackList = sellersBlacklistIds.value as Array<string | number>;

      if (!whiteList.some((id) => blackList.includes(id))) return null;

      return {
        settings: {
          message: 'Existe um conflito entre a lista de vendedores atendidos e excluidos',
        },
      };
    };
  }
}
