export const environment = {
  storefront: {
    url: 'https://qa.peca.ai',
  },
  mixpanel: {
    token: 'ffb45de9ea41021962ab10ff46d7232f',
    configuration: { debug: true, track_pageview: false, persistence: 'localStorage' },
    environment: 'HOM',
    platform: 'backoffice',
  },
  environment: 'qa',
  dashboardURL:
    'https://app.powerbi.com/view?r=eyJrIjoiNWQ2NDNlZDAtOTRmNi00ZjkzLTkzMmMtOTQxZmViYmQxM2ZjIiwidCI6ImZkOTliM2EzLWUzODctNGE1MC05NTRhLTUzMDc5NGY5NWYwMCJ9',
  businessUnit: [
    { label: 'Minas Gerais', id: '204767519' },
    { label: 'São Paulo', id: '204180583' },
  ],
  gateway: {
    url: 'https://gw.qa.peca.ai',
  },
  graphql: {
    url: 'https://qa.peca.ai/graphql',
  },
  keycloak: {
    config: {
      url: 'https://accounts.qa.peca.ai',
      realm: 'pecaai',
      clientId: 'backoffice-admin',
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
  },
};
