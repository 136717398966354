<div class="product-table">
  <ng-container *ngIf="!loading && !failure && !products">
    <h2 class="product-table__title">Faça uma busca para visualizar produtos.</h2>
    <p class="product-table__disclaimer">É necessário aplicar ao menos um filtro.</p>
  </ng-container>

  <ng-container *ngIf="loading">
    <h2>carregando...</h2>
  </ng-container>

  <ng-container *ngIf="failure">
    <h2>Não foi possível listar os produtos. Por favor, tente novamente mais tarde.</h2>
  </ng-container>

  <ng-container *ngIf="products && products.totalItems === 0">
    <h2 class="product-table__title">Nenhum produto encontrado.</h2>
    <p class="product-table__disclaimer">Verifique os filtros utilizados e tente novamente.</p>
  </ng-container>

  <ng-container *ngIf="products && products.items.length">
    <h2 class="product-table__title">Resultado</h2>
    <p class="product-table__disclaimer">{{ products.totalItems | number }} produto(s) encontrado(s)</p>

    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Código</th>
            <th>Marca</th>
            <th>Grupo</th>
            <th>Descrição</th>
            <th>Vendedores</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let product of products.items">
            <tr>
              <td>{{ product.partNumber }}</td>
              <td>{{ product.brand.name }}</td>
              <td>{{ product.applications | group }}</td>
              <td>{{ product.applications | description }}</td>
              <td>{{ product.distributors | sellers }}</td>
              <td>
                <button pdsButton="icon" (click)="handleDetailsClick(product.id)">
                  <span class="icon">visibility</span>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </pds-table>
  </ng-container>
</div>
