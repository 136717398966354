import { Component, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'apps/backoffice/src/environments/environment';

@Component({
  standalone: true,
  template: `<div class="wrapper">
    <iframe [title]="title" [src]="src" frameborder="0" allowFullScreen="true"></iframe>
  </div>`,
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  title: string;
  sanitizer: DomSanitizer;
  src: SafeResourceUrl;

  constructor() {
    this.sanitizer = inject(DomSanitizer);
    this.title = 'DashBackoffice';
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(environment.dashboardURL);
  }
}
