import { Component, OnInit, inject } from '@angular/core';
import { PurchaseOrderService } from '../../services/purchase.service';
import { BehaviorSubject } from 'rxjs';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Log } from '@peca/backoffice/models/logs/log.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'peca-purchase-order-details-logs',
  templateUrl: 'purchase-order-details-logs.component.html',
  styleUrls: ['./purchase-order-details-logs.component.scss'],
})
export class PurchaseorderDetailsLogsComponentComponent implements OnInit {
  purchaseOrderService: PurchaseOrderService;
  logs$: BehaviorSubject<AsyncState<Log[]>>;
  route: ActivatedRoute;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.purchaseOrderService = inject(PurchaseOrderService);
    this.logs$ = new BehaviorSubject<AsyncState<Log[]>>({ loading: true });
  }

  get orderId() {
    return this.route.snapshot.parent?.params['id'] as string;
  }

  ngOnInit() {
    this.fetchLogsFromOrder(this.orderId);
  }

  fetchLogsFromOrder(id: string) {
    this.purchaseOrderService.fetchLogsFromOrder(id).subscribe({
      next: (data) => this.logs$.next({ data }),
      error: () => this.logs$.next({ failure: true }),
    });
  }
}
