<pds-form-section>
  <pds-form-row *ngIf="types$ | async; let types">
    <ng-container *ngIf="types.loading"><strong>carregando...</strong></ng-container>
    <ng-container *ngIf="types.failure"><strong>Falha ao carregar tipo de produtos.</strong></ng-container>
    <ng-container *ngIf="types.data && !types.data.length"><strong>Nenhum tipo de produto encontrado.</strong></ng-container>

    <ng-container *ngFor="let type of types.data">
      <div>
        <pds-checkbox [label]="type.value" [(ngModel)]="type.selected" (ngModelChange)="onCheckType()" [disabled]="disabled" />
      </div>
    </ng-container>
  </pds-form-row>

  <pds-form-row grid="1-1-1" *ngIf="!disabled">
    <pds-form-field label="Adicionar tipo de produto" action="add" (clicked)="onClickAdd()">
      <input type="text" [(ngModel)]="newValue" />
    </pds-form-field>
  </pds-form-row>
</pds-form-section>
