export const StatusValues = [
  'WAITING_FOR_SELLER',
  'IN_SEPARATION',
  'DELIVERED',
  'PROCESSING_PAYMENT',
  'PAYMENT_ACCEPT',
  'PAYMENT_REFUSED',
  'REQUIRES_MANUAL_ADJUSTMENT',
  'AWAITING_COLLECTION',
  'ON_ROUTE',
  'PARTIAL_DELIVERED',
  'PROCESSING_RETURN',
  'PARTIAL_RETURN',
  'TOTAL_RETURN',
  'MISPLACED',
  'CANCELED',
];
