<div class="product-details-images">
  <h3 class="mb-24">Imagens</h3>

  <ng-container *ngIf="!product.images.length">
    <h4>Este produto não possui imagens</h4>
  </ng-container>

  <div class="product-details-images__images">
    <ng-container *ngFor="let image of product.images">
      <div class="product-details-images__images__item">
        <img [src]="image | thumbnail" [alt]="product.partNumber" />
      </div>
    </ng-container>
  </div>
</div>
