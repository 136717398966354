<pds-modal #modal title="Recusar cadastro de comprador" (dismiss)="close()" [width]="420">
  <pds-form [formGroup]="form">
    <pds-form-row>
      <pds-form-field label="Justificativa">
        <input type="text" formControlName="reason" />
      </pds-form-field>
    </pds-form-row>
    <pds-form-actions>
      <pds-button (click)="onClickSubmit()">salvar</pds-button>
    </pds-form-actions>
  </pds-form>
</pds-modal>
