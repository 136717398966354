import { Route } from '@angular/router';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderDetailsProductsComponent } from './components/order-details/order-details-products/order-details-products.component';
import { OrderDetailsPurchasesComponent } from './components/order-details/order-details-purchases/order-details-purchases.component';
import { OrderDetailsDeliveriesComponent } from './components/order-details/order-details-deliveries/order-details-deliveries.component';
import { OrderDetailsPaymentsComponent } from './components/order-details/order-details-payments/order-details-payments.component';
import { OrderDetailsLogsComponent } from './components/order-details/order-details-logs/order-details-logs.component';

export const routes: Route[] = [
  {
    path: ':orderId',
    component: OrderDetailsComponent,
    children: [
      {
        path: 'produtos',
        component: OrderDetailsProductsComponent,
      },
      {
        path: 'compras',
        component: OrderDetailsPurchasesComponent,
      },
      {
        path: 'entregas',
        component: OrderDetailsDeliveriesComponent,
      },
      {
        path: 'pagamentos',
        component: OrderDetailsPaymentsComponent,
      },
      {
        path: 'logs',
        component: OrderDetailsLogsComponent,
      },
      {
        path: '**',
        redirectTo: 'produtos',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    component: OrderListComponent,
  },
];

export const OrdersModuleLoad = () =>
  import('./orders.module').then(({ OrdersModule }) => OrdersModule);
