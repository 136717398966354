import { Component, Input } from '@angular/core';
import { ProductDetails } from '../../../models/product-details.model';

@Component({
  selector: 'peca-product-details-images[product]',
  templateUrl: './product-details-images.component.html',
  styleUrls: ['./product-details-images.component.scss'],
})
export class ProductDetailsImagesComponent {
  @Input()
  product!: ProductDetails;
}
