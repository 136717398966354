<ng-container *ngIf="details$ | async; let details">
  <ng-template #detailsTemplate>
    <div class="order-list-details">
      <div class="order-list-details__col">
        <h4 class="order-list-details__title">Produtos</h4>
        <ng-container *ngIf="!details.data?.products?.length">
          <strong>Nenhum produto encontrado.</strong>
        </ng-container>

        <pds-table *ngIf="details.data?.products?.length">
          <table>
            <tr>
              <th>Partnumber</th>
              <th>Qtd.</th>
            </tr>
            <tr *ngFor="let product of details.data?.products">
              <td>{{product.partNumber}}</td>
              <td>{{product.quantity}}</td>
            </tr>
          </table>
        </pds-table>
      </div>
      <div class="order-list-details__col">
        <h4 class="order-list-details__title">Pagamentos</h4>
        <ng-container *ngIf="!details.data?.payments?.length">
          <strong>Nenhum pagamento encontrado.</strong>
        </ng-container>

        <pds-table *ngIf="details.data?.payments?.length">
          <table>
            <tr>
              <th>Método</th>
              <th>Status</th>
              <th>Valor</th>
            </tr>
            <tr *ngFor="let payment of details.data?.payments">
              <td>{{payment.paymentMethod.name}}</td>
              <td>{{payment.status === 'AUTHORIZED'? 'Autorizado' : 'Negado'}}</td>
              <td>{{payment.amount | currency:'BRL'}}</td>
            </tr>
          </table>
        </pds-table>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="details.loading">
    <strong>carregando...</strong>
  </ng-container>
  <ng-container *ngIf="details.failure">
    <strong>Falha ao obter detalhes do pedido.</strong>
  </ng-container>

  <ng-container *ngIf="details.data; then detailsTemplate">
    <strong>carregando...</strong>
  </ng-container>
</ng-container>
