import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { LogTypeStatusPipe } from '@peca/backoffice/pipes/logs/order-log-type-status.pipe';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { AutocompleteItemSource, ModalComponent } from '@peca/design-system';
import { ToastService } from '@peca/commons';
import { AnalyticsService } from '@peca/commons';

import { OrderService } from '../../services/order.service';
import { OrderStatusType } from '../../models/order-status';
import { OrderOperationsValidadorService } from '../../services/order-operations-validador.service';
import { OrderChangeStatusForm } from '../../forms/order-change-status.form';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'peca-order-change-status',
  templateUrl: './order-change-status.component.html',
  styleUrls: ['./order-change-status.component.scss'],
})
export class OrderChangeStatusComponent {
  @Output()
  adjust: EventEmitter<void>;
  @ViewChild('modal')
  modal!: ModalComponent;
  orderId: string | null;
  statusSoruce: { value: OrderStatusType; label: string }[];
  confirmable: ConfirmableActionService;
  statusPipe: LogTypeStatusPipe;
  orderService: OrderService;
  toast: ToastService;
  analytics: AnalyticsService;
  orderOps: OrderOperationsValidadorService;
  form: OrderChangeStatusForm;
  orders$: BehaviorSubject<AutocompleteItemSource>;

  constructor() {
    this.adjust = new EventEmitter();
    this.statusPipe = new LogTypeStatusPipe();
    this.orderId = null;
    this.statusSoruce = [];
    this.form = new OrderChangeStatusForm();
    this.orders$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.confirmable = inject(ConfirmableActionService);
    this.orderService = inject(OrderService);
    this.toast = inject(ToastService);
    this.analytics = inject(AnalyticsService);
    this.orderOps = inject(OrderOperationsValidadorService);
  }

  getStatusSource(currentStatus: OrderStatusType) {
    const status = this.orderOps.getPossibleStatus(currentStatus);

    return status.map((i) => ({
      value: i as OrderStatusType,
      label: this.statusPipe.transform(i) as string,
    }));
  }

  open(orderId: string, status: OrderStatusType) {
    this.orderId = orderId;
    this.form.controls.currentStatus.setValue(status);
    this.statusSoruce = this.getStatusSource(status);
    this.modal.open();
  }

  close() {
    this.orderId = null;
    this.statusSoruce = [];
    this.form.clear();
    this.orders$.next({});
    this.modal.close();
  }

  onDismiss() {
    this.close();
  }

  onClickSubmit() {
    if (this.form.group.invalid || this.form.group.pristine) return;

    const { parentOrder, newStatus, currentStatus } = this.form.value;
    const parsedCurrentStatus = this.statusPipe.transform(currentStatus);
    const parsedNewStatus = this.statusPipe.transform(newStatus);
    const message = `Você está alterando o status do pedido de "${parsedCurrentStatus}" para "${parsedNewStatus}"`;

    this.confirmable.confirm(message, this.orderId, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.orderService.updateStatus(confirmation.payload, { status: newStatus, parentOrder }).subscribe({
        next: () => {
          this.toast.success('Status do pedido alterado com sucesso.');
          this.adjust.next();
          this.close();
        },
        error: () => {
          this.toast.failure('Falha ao alterar o status do pedido.');
        },
      });
    });
  }

  onCompleteOrders(search: string) {
    if (search.length < 3) return;

    this.orders$.next({ loading: true });
    this.orderService.suggestOrders(search).subscribe({
      next: (data) => this.orders$.next({ data }),
      error: () => this.orders$.next({ failure: true }),
    });
  }
}
