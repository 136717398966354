<pds-module-header title="Cupons" ctaLabel="Voltar" ctaPath="/cupons">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <pds-form [formGroup]="form">
    <pds-form-section title="Cadastro de Cupom">
      <pds-form-row grid="1-1-1-1">
        <pds-form-field label="Código do cupom">
          <input type="text" formControlName="code" placeholder="Código do cupom" />
        </pds-form-field>

        <pds-form-field label="Nome">
          <input type="text" formControlName="name" placeholder="Nome interno do cupom" />
        </pds-form-field>

        <pds-form-field label="Atribuição">
          <select formControlName="assignKind">
            <option value="" disabled selected>Selecione</option>
            <option value="SHIPPING">Entrega</option>
            <option value="PRODUCTS">Produtos</option>
          </select>
        </pds-form-field>

        <pds-form-field label="Descrição" class="description">
          <textarea formControlName="description" placeholder="Descrição do cupom"></textarea>
        </pds-form-field>

        <pds-form-field label="Tipo">
          <select formControlName="type">
            <option value="FIXED">Valor fixo</option>
            <option value="PERCENT">Porcentagem (%)</option>
          </select>
        </pds-form-field>

        <pds-form-field label="Valor">
          <ng-container *ngIf="form.get('type')?.value === 'FIXED'">
            <input type="text" formControlName="value" placeholder="Valor do cupom" pdsCurrencyMask />
          </ng-container>
          <ng-container *ngIf="form.get('type')?.value === 'PERCENT'">
            <input type="text" formControlName="value" placeholder="Valor do cupom" pdsNumberMask />
          </ng-container>
        </pds-form-field>

        <pds-form-field label="Valor máximo">
          <ng-container *ngIf="form.get('type')?.value === 'FIXED'">
            <input type="text" formControlName="limitAmountValue" placeholder="Valor máximo do cupom" pdsCurrencyMask />
          </ng-container>
          <ng-container *ngIf="form.get('type')?.value === 'PERCENT'">
            <input type="text" formControlName="limitAmountValue" placeholder="Valor máximo do cupom" pdsNumberMask />
          </ng-container>
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>
    <pds-form-section title="Campanha">
      <pds-form-row grid="1-1-1-1">
        <pds-form-field label="Início">
          <pds-date-input formControlName="campaignInit"></pds-date-input>
        </pds-form-field>
        <pds-form-field label="Fim">
          <pds-date-input formControlName="campaignEnd"></pds-date-input>
        </pds-form-field>
        <pds-form-field label="Publicado">
          <select formControlName="published" class="published-field">
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>
    <pds-form-section title="Código Legado">
      <pds-form-row grid="1-1-1-1">
        <pds-form-field label="Código do cupom">
          <input type="text" formControlName="legacyCode" placeholder="Código do cupom" />
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>
  </pds-form>

  <div class="submit">
    <ng-container *ngIf="this.submitOperation === 'update'">
      <pds-button (click)="onClickToggleStatus()"> {{ isActive.value ? 'Desativar' : 'Ativar' }} Cupom </pds-button>

      <pds-button (click)="onClickToggleEditMode()">
        {{ this.readOnlyMode.value ? 'Editar' : 'Cancelar' }}
      </pds-button>

      <pds-button *ngIf="!this.readOnlyMode.value" type="submit" (click)="onClickSubmit()">
        {{ isPublished.value ? 'Salvar publicação' : 'Salvar Rascunho' }}
      </pds-button>

      <pds-button *ngIf="!isPublished.value && !this.readOnlyMode.value" modifier="outlined" (click)="onClickPublish()"> Publicar </pds-button>
    </ng-container>

    <ng-container *ngIf="this.submitOperation === 'create'">
      <pds-button type="submit" (click)="onClickSubmit()">Salvar</pds-button>
    </ng-container>
  </div>
</pds-module-header>
