<dl>
  <div>
    <dt>Vendedor</dt>
    <dd>{{ order.branch.companyName }}</dd>
  </div>

  <div>
    <dt>Filial</dt>
    <dd>{{ order.branch.externalReference }}</dd>
  </div>

  <div>
    <dt>Und. de Negócio</dt>
    <dd>{{ order.branch.businessUnit | businessUnit }}</dd>
  </div>

  <div>
    <dt>NFe</dt>
    <dd>-</dd>
  </div>

  <div>
    <dt>Contato</dt>
    <dd>{{ order.branch.contact.contact }}</dd>
  </div>

  <div>
    <dt>Endereço de coleta</dt>
    <dd>{{ order.branch.address | address }}</dd>
  </div>
</dl>
