import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { environment } from 'apps/backoffice/src/environments/environment';
import { PurchaseOrderQuery } from '../models/purchase-order-query.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { PurchaseOrder } from '../models/purchase-order.model';
import { catchError, map, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PurchaseOrderProduct } from '../models/purchase-order-product.model';
import { Log } from '@peca/backoffice/models/logs/log.model';

@Injectable({ providedIn: 'root' })
export class PurchaseOrderService extends BaseHttpClient {
  readonly baseUrl: string;

  constructor() {
    super();
    this.baseUrl = `${environment.gateway.url}/backoffice/v1/purchases`;
  }

  fetchOrderDetails(id: string) {
    const resource = `${this.baseUrl}/${id}`;
    return this.http.get<PurchaseOrder>(resource);
  }

  fetchOrders(query: PurchaseOrderQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<PurchaseOrder>>(this.baseUrl, { params });
  }

  uploadNfe(orderId: string, purchaseId: string, file: FormData) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/purchases/${purchaseId}/invoice`;
    return this.http.post(resource, file);
  }

  downloadNFe(orderId: string, purchaseId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/purchases/${purchaseId}/invoice`;
    const responseType = 'arraybuffer';
    const observe = 'response';

    return this.http.get(resource, { responseType, observe }).pipe(
      map((res) => {
        const { status, body } = res;
        const contentType = res.headers.get('Content-Type');

        if (body && contentType && status === 200) return URL.createObjectURL(new Blob([body], { type: contentType }));

        return throwError(() => new Error(res.statusText));
      }),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 404) return of(null);

        return throwError(() => new Error(e.statusText));
      })
    );
  }

  fetchProductsFromOrder(id: string) {
    const resource = `${this.baseUrl}/${id}/products`;
    return this.http.get<PurchaseOrderProduct[]>(resource);
  }

  fetchLogsFromOrder(id: string) {
    const resource = `${this.baseUrl}/${id}/logs`;
    return this.http.get<Log[]>(resource);
  }
}
