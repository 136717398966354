<ng-container *ngIf="logs.state$ | async; let logs">
  @if (logs.data && logs.data.length) {
  <pds-table>
    <table>
      <thead>
        <tr>
          <th colspan="3">Evento</th>
          <th rowspan="2">Data / Hora</th>
          <th rowspan="2">Usuário</th>
        </tr>
        <tr>
          <th>Tipo de evento</th>
          <th>De</th>
          <th>Para</th>
        </tr>
      </thead>
      <tbody>
        @for (log of logs.data; track $index) {
        <tr>
          <td style="width: 20%">{{ log.changeType | logType }}</td>
          <td style="width: 20%">{{ log | log : 'from' | status }}</td>
          <td style="width: 20%">{{ log | log : 'to' | status }}</td>
          <td>{{ log.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{ log.author || 'SISTEMA' }}</td>
        </tr>
        }
      </tbody>
    </table>
  </pds-table>
  } @else if (logs.data && !logs.data.length) {
  <strong>Nenhum log encontrado.</strong>
  } @else if (logs.loading){
  <strong>carregando...</strong>
  } @else if (logs.failed) {
  <peca-retry context="logs" (retry)="onRetryEvent()" />
  }
</ng-container>
