import { Pipe, PipeTransform } from '@angular/core';
import { OrderInvoiceStatus } from '../models/order-invoice-status.model';

@Pipe({
  name: 'orderInvoiceStatusColor',
})
export class OrderInvoiceStatusColorPipe implements PipeTransform {
  transform(value: OrderInvoiceStatus) {
    switch (value) {
      case 'PENDING':
      case 'PROCESSING':
      case 'ISSUED':
        return 'default';
      case 'FAILED':
        return 'primary';
      default:
        return 'default';
    }
  }
}
