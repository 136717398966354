import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, inject } from '@angular/core';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { AnalyticsService } from '@peca/commons';

import { PurchaseOrderService } from '../../services/purchase.service';
import { PurchaseOrderProduct } from '../../models/purchase-order-product.model';

type State = AsyncState<PurchaseOrderProduct[]>;

@Component({
  selector: 'peca-purchase-order-details-products',
  templateUrl: './purchase-order-details-products.component.html',
  styleUrls: ['./purchase-order-details-products.component.scss'],
})
export class PurchaseorderDetailsProductsComponentComponent implements OnInit {
  analytics: AnalyticsService;
  purchaseOrderService: PurchaseOrderService;
  route: ActivatedRoute;
  products$: BehaviorSubject<State>;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.purchaseOrderService = inject(PurchaseOrderService);
    this.route = inject(ActivatedRoute);
    this.products$ = new BehaviorSubject<State>({ loading: true });
  }

  get orderId() {
    return this.route.parent?.snapshot.params['id'] as string;
  }

  ngOnInit() {
    this.fetchProducts(this.orderId);
  }

  fetchProducts(id: string) {
    this.products$.next({ loading: true });
    this.purchaseOrderService.fetchProductsFromOrder(id).subscribe({
      next: (data: PurchaseOrderProduct[]) => {
        this.products$.next({ data });
      },
      error: () => this.products$.next({ failure: true }),
    });
  }

  onRetry() {
    this.fetchProducts(this.orderId);
  }
}
