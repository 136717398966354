<div *ngIf="products$ | async as products" class="seller-details-products">
  <pds-table *ngIf="products.data && products.data.length">
    <pds-form [formGroup]="form">
      <pds-form-row>
        <pds-form-field label="Busca" action="search">
          <input type="text" formControlName="search" placeholder="Busque por partnumber, descrição ou filial" />
        </pds-form-field>
      </pds-form-row>
    </pds-form>

    <table class="mt-24">
      <thead>
        <tr>
          <th>CNPJ</th>
          <th>Razão Social</th>
          <th>Código</th>
          <th>Und. Negócio</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>

    <hr />
  </pds-table>
  <ng-container *ngIf="products.loading"><strong>carregando...</strong></ng-container>
  <ng-container *ngIf="products.failed"><strong>Não foi possível carregar as produtos.</strong></ng-container>
</div>
