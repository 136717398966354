import { Route } from '@angular/router';
import { OrdersReturnListComponent } from './components/orders-return-list/orders-return-list.component';
import { OrdersReturnDetailsComponent } from './components/orders-return-details/orders-return-details.component';
import { OrderReturnDetailsLogsComponent } from './components/orders-return-details/orders-return-details-logs/orders-return-details-logs.component';
import { OrderReturnDetailsProductsComponent } from './components/orders-return-details/orders-return-details-products/orders-return-details-products.component';

export const routes: Route[] = [
  {
    path: ':id',
    component: OrdersReturnDetailsComponent,
    children: [
      {
        path: 'produtos',
        component: OrderReturnDetailsProductsComponent,
      },
      {
        path: 'logs',
        component: OrderReturnDetailsLogsComponent,
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'produtos',
      },
    ],
  },
  {
    path: '',
    component: OrdersReturnListComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const OrdersReturnModuleLoad = () => import('./orders-return.module').then(({ OrdersReturnModule }) => OrdersReturnModule);
