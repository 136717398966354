import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { SellerQuery } from '../../services/seller-query.model';

@Component({
  selector: 'peca-seller-list-search',
  templateUrl: './seller-list-search.component.html',
  styleUrls: ['./seller-list-search.component.scss'],
})
export class SellerListSearchComponent {
  @Output()
  readonly filter: EventEmitter<SellerQuery>;

  readonly form: FormGroup;
  private readonly searchFormInitialValue = {
    search: '',
    salesModel: '',
    active: '',
    sort: 'audit.createdAt,asc',
  };

  constructor() {
    this.filter = new EventEmitter<SellerQuery>();
    this.form = new FormGroup({
      search: new FormControl(''),
      salesModel: new FormControl(''),
      active: new FormControl(''),
      sort: new FormControl('audit.createdAt,asc'),
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
  }

  onSubmitSearch() {
    this.filter.emit(this.form.value);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onSubmitSearch();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) this.form.markAsPristine();
  }
}
