import { Pipe, PipeTransform } from '@angular/core';
import { ProductApplication } from '../models/product-search-state.model';

@Pipe({ name: 'description' })
export class ProductDescriptionPipe implements PipeTransform {
  transform(applications: ProductApplication[]) {
    if (!applications) return '-';

    return applications
      .filter((application) => !!application)
      .map(({ description }) => description)
      .join(',');
  }
}
