<pds-module-header title="Usuários" ctaPath="/usuarios" ctaLabel="Voltar" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumb"></pds-new-breadcrumb>

  <pds-panel *ngIf="user$ | async; let user">
    <ng-container *ngIf="user.loading"><strong>carregando...</strong></ng-container>
    <ng-container *ngIf="user.failure"><strong>Não foi possível carregar os dados do usuário.</strong></ng-container>

    <ng-container *ngIf="!user.loading && !user.failure">
      <pds-form [formGroup]="form.group">
        <pds-form-section title="Dados do usuário">
          <pds-form-row grid="1-1-1">
            <pds-form-field label="Nome">
              <input type="text" formControlName="firstName" />
            </pds-form-field>
            <pds-form-field label="Sobrenome">
              <input type="text" formControlName="lastName" />
            </pds-form-field>
          </pds-form-row>
          <pds-form-row grid="1-1-1">
            <pds-form-field label="CPF">
              <input type="text" formControlName="document" pdsMask="999.999.999-99" />
            </pds-form-field>
            <pds-form-field label="E-mail">
              <input type="text" formControlName="email" pdsMask="email" [alias]="true" />
            </pds-form-field>
            <pds-form-field label="Data de nascimento">
              <input type="date" formControlName="birthday" />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Controle de acesso">
          <pds-form-row grid="2-1">
            <pds-form-field label="Grupos">
              <pds-select formControlName="groups" [options]="groupOptions" [disableSearch]="true" />
            </pds-form-field>
          </pds-form-row>

          <pds-form-row>
            <pds-form-field label="Permissões">
              <peca-user-role-form formControlName="roles" />
            </pds-form-field>
          </pds-form-row>
        </pds-form-section>

        <pds-form-section title="Vendedores">
          @if (!form.getValue().branches.length) {
          <strong>Nenhum vendedor cadastrado</strong>
          } @else {
          <pds-table>
            <table>
              <thead>
                <tr>
                  <th>Vendedor</th>
                  <th>Filial</th>
                  <th>CNPJ</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                @for (branch of form.controls.branches.value; track $index) {
                <tr>
                  <td>{{ branch.commercialName }}</td>
                  <td>{{ branch.externalReference }}</td>
                  <td>{{ branch.cnpj }}</td>
                  <td pdsColActions>
                    <button pdsButton="icon" [disabled]="!form.enabled" title="Remover" (click)="onClickRemoveBranch(branch)">
                      <span class="icon">remove</span>
                    </button>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </pds-table>
          } @if (form.enabled) {
          <div>
            <button pdsButton title="Adicionar vendedores" (click)="onClickAddSellers()">
              <span>Adicionar Vendedores</span>
            </button>
          </div>
          }
        </pds-form-section>

        <hr />

        <pds-form-actions>
          <ng-container *ngIf="isUpdateOperation && !form.group.enabled">
            <pds-button (click)="onClickChangeStatus()" modifier="outlined">
              <span>{{ form.getValue().active ? 'Desativar' : 'Ativar' }}</span>
            </pds-button>
            <pds-button (click)="onClickEnable()">Editar</pds-button>
          </ng-container>

          <ng-container *ngIf="form.group.enabled">
            <ng-container *ngIf="form.getValue().id && form.group.enabled">
              <pds-button (click)="onClickCancel()" modifier="outlined">Cancelar</pds-button>
            </ng-container>
            <pds-button (click)="onClickSubmit()" [disabled]="form.group.invalid">salvar</pds-button>
          </ng-container>
        </pds-form-actions>
      </pds-form>
    </ng-container>
  </pds-panel>

  <peca-users-branches-modal #usersBranchesModal (add)="onAddBranches($event)" [selecteds]="form.controls.branches.value" />
</pds-module-header>
