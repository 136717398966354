import { Routes } from '@angular/router';
import { SalesChannelsListComponent } from './components/sales-channels/sales-channels-list.component';
import { SalesChannelsFormComponent } from './components/sales-channels-form/sales-channels-form.component';

export const routes: Routes = [
  {
    path: 'novo',
    component: SalesChannelsFormComponent,
  },
  {
    path: ':channelId',
    component: SalesChannelsFormComponent,
  },
  {
    path: '',
    component: SalesChannelsListComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const SalesChannelsModuleLoad = () =>
  import('./sales-channels.module').then(
    ({ SalesChannelsModule }) => SalesChannelsModule
  );
