<pds-module-header title="Compradores" ctaLabel="Voltar" ctaPath="/compradores/pessoa-fisica" modifier="outlined" *ngIf="buyerDetails.state$ | async; let buyerDetails">
  @if (!buyerDetails.failed && !buyerDetails.loading) {
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-form [formGroup]="form.group">
    <pds-form-section title="Dados pessoais">
      <pds-form-row grid="1-1-1-1">
        <pds-form-field label="CPF">
          <input type="text" formControlName="cpf" pdsMask="999.999.999-99" [pdsFormImmutable]="!!id" />
        </pds-form-field>
        <pds-form-field label="Nome">
          <input type="text" formControlName="name" />
        </pds-form-field>
        <pds-form-field label="Sobrenome">
          <input type="text" formControlName="surname" />
        </pds-form-field>
        <pds-form-field label="Data de nascimento">
          <input type="date" formControlName="birthDate" />
        </pds-form-field>
      </pds-form-row>

      <pds-form-row grid="1-1-1-1">
        <pds-form-field label="E-mail">
          <input type="text" formControlName="email" pdsMask="email" [alias]="true" />
        </pds-form-field>
        <pds-form-field label="Celular">
          <input type="text" formControlName="cellphone" pdsMask="(99) 99999-9999" />
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-section title="Endereço">
      <pds-form-row>
        <peca-address formControlName="shippingAddress" />
      </pds-form-row>
    </pds-form-section>

    <hr />

    <pds-form-actions>
      @if (form.group.enabled && form.value.id) {
      <button type="button" pdsButton title="Cancelar" modifiers="outlined" (click)="onClickCancel()">Cancelar</button>
      } @if (form.group.enabled) {
      <button type="button" pdsButton title="Salvar" [disabled]="form.group.invalid || form.group.pristine" (click)="onClickSubmit()">Salvar</button>
      } @else {
      <button type="button" pdsButton title="Editar" (click)="onClickEnable()">Editar</button>
      }
    </pds-form-actions>
  </pds-form>
  } @else if (buyerDetails.loading) {
  <strong>carregando...</strong>
  } @else {
  <peca-retry context="Comprador" (retry)="onEventRetry()" />
  }
</pds-module-header>
