<pds-module-header title="B2B" ctaLabel="Voltar" ctaPath="/b2b" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <pds-form [formGroup]="form.group">
    <pds-form-section title="Identificação" formGroupName="identification">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="Comprador">
          <ng-container *ngIf="!isOperationUpdate; else buyerTemplate">
            <pds-autocomplete formControlName="buyer" [source]="buyers$" (autocomplete)="onAutocompleteBuyers($event)" />
          </ng-container>
          <ng-template #buyerTemplate>
            <input type="text" disabled readonly [value]="value.buyer.label" *ngIf="value.buyer" />
          </ng-template>
        </pds-form-field>
      </pds-form-row>

      <pds-form-row grid="1-1-1">
        <pds-form-field label="Client ID">
          <ng-container *ngIf="!isOperationUpdate; else clientIdTemplate">
            <input type="text" formControlName="clientId" pdsMask="b2b-a{1,32}" style="text-transform: lowercase" />
          </ng-container>
          <ng-template #clientIdTemplate>
            <input type="text" disabled readonly [value]="value.clientId" *ngIf="value.clientId" />
          </ng-template>
        </pds-form-field>

        <pds-form-field label="User ID">
          <input type="text" formControlName="userId" />
        </pds-form-field>
      </pds-form-row>

      <ng-container *ngIf="isOperationUpdate">
        <pds-form-row grid="2-1">
          <pds-form-field label="Client Secret" action="refresh" (clicked)="onClickRefreshToken()">
            <input [type]="form.group.disabled ? 'password' : 'text'" formControlName="clientSecret" readonly />
          </pds-form-field>
        </pds-form-row>
      </ng-container>
    </pds-form-section>

    <pds-form-section title="Pagamento" formGroupName="payment">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="Cupons">
          <pds-autocomplete formControlName="vouchers" [source]="vouchers$" (autocomplete)="onAutocompleteVouchers($event)" />
        </pds-form-field>

        <pds-form-field label="Método de pagamento">
          <select formControlName="paymentMethodId">
            <option value="">Selecione</option>
            <ng-container *ngFor="let method of paymentMethods$ | async">
              <option [value]="method.id">{{ method.name }}</option>
            </ng-container>
          </select>
        </pds-form-field>

        <pds-form-field label="Taxa de conveniência (%)">
          <input type="number" formControlName="convenienceFee" placeholder="Taxa de conveniência em percentual" />
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-section title="Carrinho" formGroupName="cart">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="Estratégia de carrinho">
          <select formControlName="applyCartStrategy">
            <option [value]="false">Não</option>
            <option [value]="true">Sim</option>
          </select>
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-section title="Sistema de busca" formGroupName="search">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="Exibir part number">
          <select formControlName="showPartNumber">
            <option value="false">Não</option>
            <option value="true">Sim</option>
          </select>
        </pds-form-field>

        <pds-form-field label="Método de busca">
          <select formControlName="faster">
            <option value="false">Mais barato</option>
            <option value="true">Mais rápido</option>
          </select>
        </pds-form-field>

        <pds-form-field label="Tempo de entrega (horas)">
          <input type="number" formControlName="deliveryTime" />
        </pds-form-field>

        <pds-form-field label="Habilitar Dialect">
          <select formControlName="applyDialect">
            <option value="true">Não</option>
            <option value="false">Sim</option>
          </select>
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-section title="Restrições">
      <pds-form-row grid="1-1-1-1">
        <pds-form-field label="Visualização da busca">
          <select formControlName="vehicleSearchView">
            <option value="">Selecione</option>
            <option value="COMPLETE">Completa</option>
            <option value="PARTIAL">Parcial</option>
            <option value="BLOCK">Bloqueda</option>
          </select>
        </pds-form-field>
        <pds-form-field label="Limite de busca de placa">
          <input type="number" formControlName="searchLimit" />
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-actions>
      <ng-container *ngIf="isOperationUpdate && !form.group.enabled">
        <button pdsButton="default" modifiers="outlined" (click)="onClickChangeStatus()">
          <span>{{ value.active ? 'Desativar' : 'Ativar' }}</span>
        </button>

        <button pdsButton="default" (click)="onClickEnable()">
          <span>Editar</span>
        </button>
      </ng-container>

      <ng-container *ngIf="form.group.enabled">
        <ng-container *ngIf="value.id && form.group.dirty">
          <button pdsButton="default" modifiers="outlined" (click)="onClickCancel()">Cancelar</button>
        </ng-container>

        <button pdsButton="default" modifiers="outlined" (click)="onClickCancel()" [disabled]="form.group.invalid || form.group.pristine" (click)="onClickSubmit()">Salvar</button>
      </ng-container>
    </pds-form-actions>
  </pds-form>
</pds-module-header>
