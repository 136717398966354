import { BehaviorSubject, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { Pagination, PaginationInitialState, BreadcrumbItem } from '@peca/design-system';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@peca/commons';

import { AsyncState } from '../../../../core/models/async-state.model';
import { Pageable } from '../../../../core/models/pageable.model';
import { CarrierService } from '../../services/carrier.service';
import { Carrier } from '../../models/carrier.model';
import { CarrierQuery } from '../../models/carrier-query.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';

@Component({
  selector: 'peca-carriers-list',
  templateUrl: './carriers-list.component.html',
  styleUrls: ['./carriers-list.component.scss'],
})
export class CarriersListComponent implements OnInit, OnDestroy {
  destroyRef$: Subject<void>;
  service: CarrierService;
  carriers$: BehaviorSubject<AsyncState<Array<Carrier>>>;
  pagination$: BehaviorSubject<Pagination>;
  router: Router;
  toast: ToastService;
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Listagem de Transportadoras', path: '/transportadoras' }];
  lastQuery: CarrierQuery;
  confirmable: ConfirmableActionService;

  constructor() {
    this.service = inject(CarrierService);
    this.router = inject(Router);
    this.toast = inject(ToastService);
    this.confirmable = inject(ConfirmableActionService);
    this.destroyRef$ = new Subject<void>();
    this.carriers$ = new BehaviorSubject<AsyncState<Array<Carrier>>>({
      loading: true,
    });
    this.lastQuery = { sort: 'id,asc', ...PaginationInitialState };
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
  }

  ngOnInit() {
    const query = this.lastQuery;
    this.fetchCarriers(query);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  fetchCarriers(query: CarrierQuery) {
    this.carriers$.next({ loading: true });
    this.service
      .fetchCarriers(query)
      .pipe(takeUntil(this.destroyRef$), distinctUntilChanged())
      .subscribe({
        next: this.onFetchCarriersSuccess.bind(this),
        error: this.onFetchCarriersFailure.bind(this),
      });
  }

  onFetchCarriersSuccess(response: Pageable<Carrier>) {
    this.paginate(response);
    this.carriers$.next({ data: response.items });
  }

  onFetchCarriersFailure(e: unknown) {
    this.carriers$.next({ failure: true });
  }

  updateStatus(carrier: Carrier) {
    const { id, active } = carrier;
    this.service
      .updateStatus(id, active)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: this.onUpdateStatusSuccess.bind(this),
        error: (e) => this.onUpdateStatusFailure(e, carrier),
      });
  }

  onUpdateStatusSuccess() {
    this.toast.success('O status da transportadora foi alterado com sucesso.');
  }

  onUpdateStatusFailure(e: unknown, carrier: Carrier) {
    const message = 'Ocorreu um erro ao alterar o status da transportadora.';

    this.toast.failure(message);
    carrier.active = !carrier.active;
  }

  handleSearchEvent(query: CarrierQuery) {
    this.fetchCarriers(query);
  }

  handleStatusChanges(carrier: Carrier, event: boolean) {
    carrier.active = event;
    this.confirmable.confirm('Você está alterando o status de uma transportadora.').subscribe(({ confirmed }) => {
      if (!confirmed) {
        carrier.active = !carrier.active;
        return;
      }

      this.updateStatus(carrier);
    });
  }

  handleDetailsClickEvent(carrier: Carrier) {
    this.router.navigate(['/transportadoras', carrier.id]);
  }

  onRetry() {
    this.ngOnInit();
  }
}
