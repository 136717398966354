<div class="peca-payment-method-form" *ngIf="methods$ | async; let methods">
  <ng-container *ngIf="methods.loading">
    <span>carregando...</span>
  </ng-container>

  <ng-container *ngIf="methods.failure">
    <span>Falha ao obter métodos de pagamento.</span>
  </ng-container>

  <ng-container *ngIf="methods.data && !methods.data.length">
    <span>Nenhum método de pagamento encontrado.</span>
  </ng-container>

  <div class="peca-payment-method-form__fields">
    <ng-container *ngFor="let method of methods.data">
      <pds-checkbox [label]="method.name" [disabled]="disabled" [(ngModel)]="method.selected" (ngModelChange)="onChangeParent(method)" />

      <ng-container *ngIf="method.children?.length && method.selected">
        <ng-container *ngFor="let child of method.children">
          <div class="ml-24">
            <pds-checkbox [label]="child.name" [disabled]="disabled || child.disabled" [(ngModel)]="child.selected" (ngModelChange)="onChangeChild(method, child)" />
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
