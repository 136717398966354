import { Component, OnInit, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem, MultipleItem } from '@peca/design-system';
import { ToastService, Validators } from '@peca/commons';
import { BehaviorSubject } from 'rxjs';

import { SallesChannelRequest } from '../../models/sales-channel-request.model';
import { SalesChannelsService } from '../../services/sales-channels.service';
import { ConfirmableActionService } from '../../../../core/services/confirmable-action.service';

@Component({
  templateUrl: './sales-channels-form.component.html',
  styleUrls: ['./sales-channels-form.component.scss'],
})
export class SalesChannelsFormComponent implements OnInit {
  breadcrumb: BreadcrumbItem[];
  route: ActivatedRoute;
  form: FormGroup;
  service: SalesChannelsService;
  router: Router;
  toast: ToastService;
  confirmable: ConfirmableActionService;
  paymentMethods$: BehaviorSubject<MultipleItem[]>;
  cartStrategies$: BehaviorSubject<MultipleItem[]>;
  buyers$: BehaviorSubject<MultipleItem[]>;

  constructor() {
    this.confirmable = inject(ConfirmableActionService);
    this.buyers$ = new BehaviorSubject<MultipleItem[]>([]);
    this.paymentMethods$ = new BehaviorSubject<MultipleItem[]>([]);
    this.cartStrategies$ = new BehaviorSubject<MultipleItem[]>([
      { id: 'BEST_PRICE', label: 'BEST_PRICE' },
      { id: 'BEST_PRICE_WITH_STOCK', label: 'BEST_PRICE_WITH_STOCK' },
      { id: 'SELLER_AGGREGATE', label: 'SELLER_AGGREGATE' },
    ]);
    this.toast = inject(ToastService);
    this.router = inject(Router);
    this.service = inject(SalesChannelsService);
    this.breadcrumb = [{ label: 'Canais de Vendas', path: '/canais-de-venda' }];
    this.route = inject(ActivatedRoute);
    this.form = new FormGroup({
      id: new FormControl(''),
      active: new FormControl(true),
      name: new FormControl('', {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      clientId: new FormControl('', {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      userId: new FormControl('', {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      clientSecret: new FormControl(''),
      convenienceFee: new FormControl(0, [Validators.required, Validators.number.min(0), Validators.number.max(100)]),
      buyersIds: new FormControl([]),
      paymentMethodsIds: new FormControl([], [Validators.required]),
      applyCartStrategy: new FormControl(true),
      cartStrategy: new FormControl([]),
      showPartNumber: new FormControl(true),
      faster: new FormControl(true),
      deliveryTime: new FormControl('', [Validators.required, Validators.number.min(1)]),
      applyDialect: new FormControl(true),
      searchByPlate: new FormControl(true),
      vehicleSearchView: new FormControl('', [Validators.required]),
      searchLimit: new FormControl(''),
      searchByFragaId: new FormControl('', [Validators.required]),
      freightType: new FormControl('', [Validators.required]),
    });
  }

  get channelId() {
    const { channelId } = this.route.snapshot.params;
    return channelId;
  }

  get isEditing() {
    return !!this.channelId;
  }

  get controls() {
    return {
      id: this.form.get('id') as FormControl,
      active: this.form.get('active') as FormControl,
      name: this.form.get('name') as FormControl,
      clientId: this.form.get('clientId') as FormControl,
      userId: this.form.get('userId') as FormControl,
      clientSecret: this.form.get('clientSecret') as FormControl,
      convenienceFee: this.form.get('convenienceFee') as FormControl,
      buyersIds: this.form.get('buyersIds') as FormControl,
      paymentMethodsIds: this.form.get('paymentMethodsIds') as FormControl,
      applyCartStrategy: this.form.get('applyCartStrategy') as FormControl,
      cartStrategy: this.form.get('cartStrategy') as FormControl,
      showPartNumber: this.form.get('showPartNumber') as FormControl,
      faster: this.form.get('faster') as FormControl,
      deliveryTime: this.form.get('deliveryTime') as FormControl,
      applyDialect: this.form.get('applyDialect') as FormControl,
      searchByPlate: this.form.get('searchByPlate') as FormControl,
      vehicleSearchView: this.form.get('vehicleSearchView') as FormControl,
      searchLimit: this.form.get('searchLimit') as FormControl,
      searchByFragaId: this.form.get('searchByFragaId') as FormControl,
      freightType: this.form.get('freightType') as FormControl,
    };
  }

  get value() {
    const value = {
      id: this.controls.id.getRawValue(),
      active: JSON.parse(this.controls.active.getRawValue()),
      name: this.controls.name.getRawValue(),
      clientId: (this.controls.clientId.getRawValue() as string).toLocaleLowerCase(),
      userId: (this.controls.userId.getRawValue() as string).toLocaleLowerCase(),
      clientSecret: this.controls.clientSecret.getRawValue(),
      convenienceFee: this.controls.convenienceFee.getRawValue(),
      buyersIds: this.controls.buyersIds.getRawValue(),
      paymentMethodsIds: this.controls.paymentMethodsIds.getRawValue(),
      applyCartStrategy: JSON.parse(this.controls.applyCartStrategy.getRawValue()),
      cartStrategy: this.controls.cartStrategy.getRawValue(),
      showPartNumber: this.controls.showPartNumber.getRawValue(),
      faster: this.controls.faster.getRawValue(),
      deliveryTime: this.controls.deliveryTime.getRawValue(),
      applyDialect: this.controls.applyDialect.getRawValue(),
      searchByPlate: this.controls.searchByPlate.getRawValue(),
      vehicleSearchView: this.controls.vehicleSearchView.getRawValue(),
      searchLimit: this.controls.searchLimit.getRawValue(),
      searchByFragaId: this.controls.searchByFragaId.getRawValue(),
      freightType: this.controls.freightType.getRawValue(),
    };

    return value as SallesChannelRequest;
  }

  ngOnInit() {
    const channelId = this.channelId;

    this.fetchPaymentMethods();
    this.fetchBuyers();
    this.form.disable();

    if (!channelId) {
      this.initCreateFlow();
    } else {
      this.initEditFlow(channelId);
    }
  }

  initCreateFlow() {
    this.controls.name.setAsyncValidators((control: AbstractControl) => this.service.validateAvailability(control.value, 'name', 'nome'));
    this.controls.clientId.setAsyncValidators((control: AbstractControl) => this.service.validateAvailability(control.value, 'clientId', 'Client ID'));
    this.controls.userId.setAsyncValidators((control: AbstractControl) => this.service.validateAvailability(control.value, 'userId', 'User ID'));
    this.breadcrumb.push({ label: 'Novo canal de vendas' });
    this.form.enable();
  }

  initEditFlow(channelId: string) {
    this.service.fetchDetails(channelId).subscribe({
      next: (channel) => {
        this.form.patchValue({ ...channel });
        this.breadcrumb.push({ label: channel.name });
        this.buyers$.next(this.buyers$.value.map((buyer) => ({ ...buyer, selected: channel.buyersIds.some((id) => id === buyer.id) })));
        this.paymentMethods$.next(
          this.paymentMethods$.value.map((method) => ({
            ...method,
            selected: channel.paymentMethodsIds.some((id) => id === method.id),
          }))
        );
        this.cartStrategies$.next(
          this.cartStrategies$.value.map((method) => ({
            ...method,
            selected: channel.cartStrategy.includes(method.id as string),
          }))
        );
      },
      error: (e: unknown) => {
        this.toast.failure('Não foi possível carregar os dados do canal de vendas.');
      },
    });
  }

  onSubmit() {
    if (this.form.invalid) return;
    const payload = this.value;

    this.form.disable();
    this.service.save(payload).subscribe({
      next: (response: { id: string }) => {
        this.toast.success('Canal de venda salvo com sucesso.');
        if (this.isEditing) this.ngOnInit();
        else this.router.navigate(['/canais-de-venda', response.id]);
      },
      error: (e: unknown) => {
        this.form.enable();
        this.toast.failure('Falha ao salvar canal de vendas.');
      },
    });
  }

  onClickRefreshToken() {
    const message = `Você está atualizando as credenciais do canal de vendas "${this.value.name}".`;
    const channelId = this.channelId;

    this.confirmable.confirm<string>(message, channelId, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.service.refreshClientSecret(confirmation.payload).subscribe({
        next: () => {
          this.toast.success('Client secret atualizado com sucesso.');
          this.ngOnInit();
        },
        error: () => {
          this.toast.failure('Falha ao atualizar o client secret');
        },
      });
    });
  }

  onClickEdit() {
    this.controls.clientSecret.enable();
    this.controls.convenienceFee.enable();
    this.controls.buyersIds.enable();
    this.controls.paymentMethodsIds.enable();
    this.controls.applyCartStrategy.enable();
    this.controls.cartStrategy.enable();
    this.controls.showPartNumber.enable();
    this.controls.faster.enable();
    this.controls.deliveryTime.enable();
    this.controls.applyDialect.enable();
    this.controls.searchByPlate.enable();
    this.controls.vehicleSearchView.enable();
    this.controls.searchLimit.enable();
    this.controls.freightType.enable();
  }

  onClickCancel() {
    this.ngOnInit();
  }

  onClickChangeStatus() {
    const { active, name } = this.value;
    const operation = active ? 'desativando' : 'ativando';
    const message = `Você está ${operation} o canal de vendas "${name}"`;
    const payload = this.channelId;

    this.confirmable.confirm<string>(message, payload).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      const id = confirmation.payload;

      this.controls.active.setValue(!active);
      this.service.updateStatus(id, !active).subscribe({
        next: () => this.toast.success('Status alterado com sucesso'),
        error: () => {
          this.toast.success('Falha ao alterar o status.');
          this.controls.active.setValue(active);
        },
      });
    });
  }

  private fetchPaymentMethods() {
    this.service.fetchPaymentMethods().subscribe((items) => this.paymentMethods$.next(items));
  }

  private fetchBuyers() {
    this.service.fetchBuyers().subscribe((items) => this.buyers$.next(items));
  }
}
