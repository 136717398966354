import { PageableQuery } from '@peca/backoffice/models/pageable-query.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { BaseHttpClient } from '@peca/commons';

import { environment } from 'apps/backoffice/src/environments/environment';
import { Delivery } from '../models/delivery.model';
import { FreightRequest } from '../models/freight-request.model';
import { DeliveryType } from '../models/delivery-type.model';
import { map } from 'rxjs';

export class FreightService extends BaseHttpClient {
  private gateway: string;

  constructor() {
    super();
    this.gateway = environment.gateway.url;
  }

  fetchFreights(query: PageableQuery) {
    const params = this.prepareQuery(query);
    const resource = `${this.gateway}/backoffice/v1/freights`;
    return this.http.get<Pageable<Delivery>>(resource, { params });
  }

  createShippingRequest(payload: FreightRequest) {
    const resource = `${environment.gateway.url}/backoffice/v1/shippings`;
    return this.http.post(resource, payload);
  }

  fetchDeliveryTypes(carrierId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/carriers/${carrierId}/delivery-types`;
    return this.http.get<DeliveryType[]>(resource);
  }

  fetchSalesChannels() {
    const resource = `${environment.gateway.url}/backoffice/v1/sales-channel?size=100&sort=name`;
    return this.http.get<{ items: Array<{ id: string; name: string }> }>(resource).pipe(
      map(({ items }) => {
        const options = items.map(({ id, name }) => ({ id, label: name }));
        const index = options.findIndex((item) => item.label === 'Peça Aí - Storefront');

        if (index !== -1) {
          const [storefrontItem] = options.splice(index, 1);
          options.unshift(storefrontItem);
        }

        return options;
      })
    );
  }
}
