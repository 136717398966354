import { ToastService } from '@peca/commons';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalComponent } from '@peca/design-system';
import { AnalyticsService } from '@peca/commons';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';

import { OrderService } from '../../services/order.service';
import { Order } from '../../models/order.model';

@Component({
  selector: 'peca-order-observation',
  templateUrl: './order-observation.component.html',
  styleUrls: ['./order-observation.component.scss'],
})
export class OrderObservationComponent {
  @ViewChild('modal')
  modal!: ModalComponent;
  @Output()
  adjust: EventEmitter<void>;
  form: FormGroup;
  order: Order | null;
  orderService: OrderService;
  toast: ToastService;
  analytics: AnalyticsService;

  constructor() {
    this.toast = inject(ToastService);
    this.orderService = inject(OrderService);
    this.order = null;
    this.adjust = new EventEmitter<void>();
    this.form = new FormGroup({
      invoiceObservations: new FormControl(''),
      publicObservations: new FormControl(''),
    });
    this.analytics = inject(AnalyticsService);
  }

  open(order: Order) {
    const { invoiceObservations, publicObservations, processedDate } = order;
    this.order = order;
    this.form.get('invoiceObservations')?.setValue(invoiceObservations);
    this.form.get('publicObservations')?.setValue(publicObservations);
    this.modal.open();

    if (processedDate) {
      this.form.get('invoiceObservations')?.disable();
    }
  }

  close() {
    this.form.get('publicObservations')?.setValue('');
    this.form.get('invoiceObservations')?.setValue('');
    this.form.get('invoiceObservations')?.enable();
    this.modal.close();
    this.order = null;
  }

  onDismiss() {
    this.close();
  }

  onClickSubmit() {
    if (!this.order || this.form.invalid) return;

    this.orderService.updateObservations(this.order.id, this.form.value).subscribe({
      next: () => {
        this.toast.success('Observações atualizadas com sucesso.');
        this.close();
        this.adjust.emit();
      },
      error: () => {
        this.toast.failure('Não foi possível atualizar as observações.');
      },
    });
  }
}
