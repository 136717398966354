import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category',
})
export class OrderReturnCategoryPipe implements PipeTransform {
  transform(category: string) {
    switch (category) {
      case 'EXCHANGE':
        return 'Troca';
      case 'EXCHANGE_WARRANTY':
        return 'Garantia';
      case 'WITHDRAWAL':
        return 'Desistência';
      case 'ORDER_CANCEL':
        return 'Cancelamento';
      default:
        return category;
    }
  }
}
