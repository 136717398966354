import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { catchError, map, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Pageable } from '../../../core/models/pageable.model';
import { B2B } from '../models/b2b.model';
import { B2BQuery } from '../models/b2b-query.model';
import { B2BRequest } from '../models/b2b-request.model';
import { Voucher } from '../models/b2b-voucher.model';

@Injectable({ providedIn: 'root' })
export class B2BService extends BaseHttpClient {
  private readonly api = `${environment.gateway.url}/backoffice/v1/buyers/b2bs`;

  constructor() {
    super();
  }

  get backofficeUrl() {
    return `${environment.gateway.url}/backoffice/v1`;
  }

  save(b2b: B2BRequest, update: boolean) {
    if (update) {
      return this.update(b2b);
    } else {
      return this.create(b2b);
    }
  }

  fetch(query: B2BQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<B2B>>(this.api, { params });
  }

  fetchDetails(b2bId: number) {
    const resource = `${this.backofficeUrl}/buyers/b2bs/${b2bId}`;
    return this.http.get<B2B>(resource);
  }

  updateStatus(b2bId: number, active: boolean) {
    const resource = `${this.api}/${b2bId}/active`;
    return this.http.patch(resource, { active });
  }

  fetchBuyer(buyerId: string) {
    return this.http.get<any>(`${this.backofficeUrl}/buyers/${buyerId}`).pipe(map((buyer) => buyer.paymentMethods));
  }

  validateBuyerAvailability(value: string) {
    const resource = `${this.backofficeUrl}/buyers/b2bs/buyerId/available`;
    const params = { value };
    return this.http.get(resource, { params }).pipe(
      map(() => null),
      catchError((e) => {
        const message = 'Já existe uma configuração cadastrada para esse comprador';

        if (e.status === 409) {
          return of({ conflicted: { message } });
        }

        // TODO: add observatility
        return of(null);
      })
    );
  }

  validateClientIdAvailability(value: string) {
    const resource = `${this.backofficeUrl}/buyers/b2bs/clientId/available`;
    const params = { value };

    return this.http.get(resource, { params }).pipe(
      map(() => null),
      catchError((e) => {
        const message = `O client ID "${value}" já está em uso por outra configuração`;

        if (e.status === 409) {
          return of({ conflicted: { message } });
        }

        // TODO: add observatility
        return of(null);
      })
    );
  }

  suggestBuyers(search: string) {
    const params = { search };
    const resource = `${this.backofficeUrl}/buyers/suggestions`;
    return this.http.get<any[]>(resource, { params }).pipe(map((items) => items.map(({ id, name }) => ({ id, label: name }))));
  }

  suggestVouchers(search: string) {
    const params = { search };
    const resource = `${this.backofficeUrl}/vouchers/suggestions`;
    return this.http.get<Voucher[]>(resource, { params }).pipe(map((items) => items.map(({ id, name }) => ({ id, label: name }))));
  }

  refreshClientSecret(b2bId: string | number) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/b2bs/${b2bId}/client-secret`;
    return this.http.patch<B2B>(resource, {});
  }

  private create(b2b: B2BRequest) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${b2b.buyer.id}/b2bs`;
    return this.http.post(resource, b2b);
  }

  private update(b2b: B2BRequest) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/b2bs/${b2b.id}`;
    return this.http.patch(resource, b2b);
  }
}
