<div class="product-search">
  <div class="mb-24"></div>
  <pds-form [formGroup]="form">
    <pds-form-row grid="1-1-1">
      <pds-form-field label="Marca">
        <pds-autocomplete
          formControlName="brand"
          placeholder="Nome da marca"
          noSearchMessage="Utilize ao menos 2 caracteres para sua busca."
          (autocomplete)="handleBrandsAutocomplete($event)"
          [source]="brands$"
          [history]="brandsHistory$"
        ></pds-autocomplete>
      </pds-form-field>

      <pds-form-field label="Grupo">
        <pds-autocomplete
          formControlName="group"
          placeholder="Grupo de produto ou descrição de aplicação"
          noSearchMessage="Utilize ao menos 3 caracteres para sua busca."
          maxlength="32"
          (autocomplete)="handleGroupsAutocomplete($event)"
          [source]="groups$"
          [history]="groupsHistory$"
        ></pds-autocomplete>
      </pds-form-field>

      <pds-form-field label="Busca">
        <input type="text" formControlName="search" placeholder="Código da peça, fornecedor ou descrição de aplicação" maxlength="32" />
      </pds-form-field>
    </pds-form-row>

    <ng-container *ngIf="storefrontSearch.value">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="CEP">
          <input type="text" formControlName="zipcode" placeholder="CEP do comprador" pdsMask="99999-999" />
        </pds-form-field>

        <pds-form-field label="Placa">
          <input type="text" formControlName="plate" placeholder="Placa do veículo" pdsMask="AAA9&99" />
        </pds-form-field>
      </pds-form-row>
    </ng-container>

    <div class="product-search__actions">
      <button pdsButton type="submit" [disabled]="disabled" (click)="handleSearchClickEvent()">Buscar</button>
    </div>
  </pds-form>
</div>
