import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Pageable } from '../../../core/models/pageable.model';
import { ProductDetails } from '../models/product-details.model';
import { ProductStorefront } from '../models/storefront/product-storefront.model';
import { storefrontSearchSchema } from '../models/storefront/storefront-query-schema.model';
import { StorefrontSearchResponse } from '../models/storefront/storefront-query-response.model';
import { ProductSearchQuery } from '../models/product-search-query.model';
import { Product } from '../models/product-search-state.model';

@Injectable({ providedIn: 'root' })
export class ProductService {
  private readonly api = `${environment.gateway.url}/backoffice/v1/products`;

  constructor(private http: HttpClient) {}

  getProductDetails(id: string) {
    return this.http.get<ProductDetails>(`${this.api}/${id}`);
  }

  search(query: ProductSearchQuery) {
    const params = { ...this.prepareQuery(query) };

    return this.http.get<Pageable<Product>>(this.api, { params });
  }

  storefrontSearch(query: ProductSearchQuery) {
    const schema = storefrontSearchSchema(query);
    const headers = { 'Content-type': 'application/json' };

    return this.http
      .post<StorefrontSearchResponse>(environment.graphql.url, schema, {
        headers,
      })
      .pipe(
        map((response) => {
          const products = response.data.catalogSearchWithZipcode.products;
          return {
            totalItems: products.totalCount,
            totalPages: products.pageInfo.totalPages,
            pageSize: products.pageInfo.pageSize,
            currentPage: query.page || 0,
            items: products.edges,
          } as Pageable<ProductStorefront>;
        })
      );
  }

  private prepareQuery(query: ProductSearchQuery) {
    return Object.keys(query)
      .filter((key) => !!query[key as keyof ProductSearchQuery])
      .reduce(
        (prev, key) => ({
          ...prev,
          [key]: query[key as keyof ProductSearchQuery],
        }),
        {}
      );
  }
}
