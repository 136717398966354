import { map } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';

import { environment } from '../../../../environments/environment';
import { CarrierQuery } from '../models/carrier-query.model';
import { Pageable } from '../../../core/models/pageable.model';
import { Carrier } from '../models/carrier.model';
import { SellerSuggestion } from '../models/seller-suggestion.model';
import { DeliveryType } from '../models/delivery-type.model';

@Injectable({ providedIn: 'root' })
export class CarrierService extends BaseHttpClient {
  private readonly api: string;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1`;
  }

  fetchCarrierDetails(id: string) {
    const resource = `${this.api}/carriers/${id}`;
    return this.http.get<Carrier>(resource);
  }

  fetchCarriers(query: CarrierQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<Carrier>>(`${this.api}/carriers`, { params });
  }

  updateStatus(id: number, active: boolean) {
    const resource = `${this.api}/carriers/${id}/active`;
    return this.http.patch(resource, { active });
  }

  createCarrier(carrier: Carrier) {
    const resource = `${this.api}/carriers`;
    return this.http.post(resource, carrier);
  }

  updateCarrier(id: string, carrier: Carrier) {
    const resource = `${this.api}/carriers/${id}`;
    return this.http.patch(resource, carrier);
  }

  fetchSellers(search?: string) {
    const query = { search };
    const params = this.prepareQuery(query);
    const resource = `${this.api}/sellers/suggestions`;
    return this.http.get<SellerSuggestion[]>(resource, { params }).pipe(map((sellers) => sellers.map(({ id, name }) => ({ id, label: name }))));
  }

  fetchDeliveryTypes(carrier: string) {
    const deliveries = `${this.api}/carriers/${carrier}/delivery-types`;
    return this.http.get<DeliveryType[]>(deliveries);
  }

  createDeliveryType(carrier: string, deliveryType: DeliveryType) {
    const resource = `${this.api}/carriers/${carrier}/delivery-types`;
    return this.http.post(resource, deliveryType);
  }

  updateDeliveryType(carrier: string, deliveryType: DeliveryType) {
    const { id } = deliveryType;
    const resource = `${this.api}/carriers/${carrier}/delivery-types/${id}`;
    return this.http.patch(resource, deliveryType);
  }
}
