import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Async } from '@peca/backoffice/utils/async.util';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { ToastService } from '@peca/commons';
import { Branch } from '../../models/branch.model';
import { SellerService } from '../../services/seller.service';
import { AddProductManuallyComponent } from '../add-product-manully/add-product-manully.component';

@Component({
  selector: 'peca-seller-details-branches',
  templateUrl: './seller-details-branches.component.html',
  styleUrls: ['./seller-details-branches.component.scss'],
})
export class SellerDetailsBranchesComponent implements OnInit {
  @ViewChild('addProductsManuallyModal')
  addProductsManuallyModal!: AddProductManuallyComponent;
  branches: Async<Branch[]>;
  sellerService: SellerService;
  route: ActivatedRoute;
  sellerId: string;
  confirmable: ConfirmableActionService;
  toast: ToastService;
  router: Router;

  constructor() {
    this.sellerService = inject(SellerService);
    this.branches = new Async<Branch[]>();
    this.route = inject(ActivatedRoute);
    this.sellerId = '';
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.router = inject(Router);
  }

  get sellerId$() {
    return this.route.parent?.params.pipe(map((params) => params['sellerId'] as string)) as Observable<string>;
  }

  get branches$() {
    return this.branches.state$;
  }

  ngOnInit() {
    this.sellerId$
      .pipe(
        tap(() => this.branches.loading()),
        tap((id) => (this.sellerId = id)),
        switchMap((id) => this.sellerService.fetchSellerBranches(id)),
        catchError((e) => {
          this.branches.failed();
          return throwError(() => new Error(e));
        })
      )
      .subscribe((branches) => this.branches.loaded(branches));
  }

  onClickSwitchStatus(branch: Branch) {
    const message = `Você está ${branch.active ? 'desativando' : 'ativando'} a filial ${branch.externalReference}.`;

    this.confirmable.confirm(message, branch).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        branch.active = !branch.active;
        return;
      }

      this.sellerService.updateBranchStatus(branch.id, branch.active).subscribe({
        next: () => this.toast.success('O status da filial foi alterado com sucesso.'),
        error: () => {
          this.toast.failure('Ocorreu um erro ao alterar o status da filiial.');
          branch.active = !branch.active;
        },
      });
    });
  }

  onClickNewBranch() {
    this.router.navigate(['/vendedores', this.sellerId, 'filiais', 'nova']);
  }

  onClickGoToDetails(branch: Branch) {
    const { sellerId, id } = branch;
    this.router.navigate(['/vendedores', sellerId, 'filiais', id]);
  }

  onClickOpenAddProductManually(branch: Branch) {
    this.addProductsManuallyModal.open(branch);
  }
}
