<ng-container>
  <div class="buyer-list-search">
    <pds-form [formGroup]="searchForm">
      <pds-form-row grid="1-1-1">
        <pds-form-field label="Número da fatura">
          <input type="text" placeholder="Pesquise pelo Número da fatura" formControlName="code" />
        </pds-form-field>

        <pds-form-field label="Data da fatura">
          <pds-range-form formControlName="date" />
        </pds-form-field>

        <pds-form-field label="Valor">
          <input type="text" placeholder="Pesquise pelo valor da fatura" formControlName="amount" pdsCurrencyMask />
        </pds-form-field>
      </pds-form-row>
      <pds-form-actions>
        <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="searchForm.pristine">Limpar filtros</button>
        <button pdsButton type="submit" (click)="onClickSearch()">Buscar</button>
      </pds-form-actions>
    </pds-form>
  </div>
  <hr />
  <ng-container *ngIf="bills$ | async; let bills">
    @if(bills.data?.length){
    <pds-table>
      <table>
        <thead>
          <th>Número da fatura</th>
          <th>Data de fechamento da fatura</th>
          <th>Total da fatura</th>
          <th>Status de pagamento</th>
          <th>Fatura</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let bill of bills?.data">
            <tr>
              <td>{{ bill.code }}</td>
              <td>{{ bill.endDate | date : 'dd/MM/yyyy' }}</td>
              <td>{{ bill.totalAmount | currency : 'BRL' }}</td>
              <td>
                <span [pdsChip]="bill.status | billStatusColor">
                  {{ bill.status | billStatus }}
                </span>
              </td>
              <td>
                <a pdsButton="icon" target="_blank" [href]="acessBillBaseUrl + '/cobrancas/' + bill?.accessHash">
                  <span class="icon">visibility</span>
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)" />
    </pds-table>
    } @else if (bills.loading) {
    <strong>carregando...</strong>
    } @else if (bills.failure) {
    <ng-container>
      <peca-retry context="faturas do comprador" (retry)="onRetry()" />
    </ng-container>
    } @else if(bills.data && !bills.data.length) {
    <strong>Nenhuma venda encontrada.</strong>
    }
  </ng-container>
</ng-container>
