import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'contacts' })
export class ResponsibleContactPipe implements PipeTransform {
  transform(contacts: Array<any>, type: 'EMAIL' | 'CELLPHONE' | 'PHONE') {
    const contact = contacts.find((contact) => contact.type === type);

    if (!contact) return '-';

    return contact.contact;
  }
}
