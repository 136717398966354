import { CurrencyPipe } from '@angular/common';
import { Validators } from '@peca/commons';
import { FormControl, FormGroup } from '@angular/forms';

import { OrderProduct } from '../models/order-product.modal';
import { OrderSimilarProduct } from '../models/order-similar-product.model';

export class OrderReplaceProductForm {
  group: FormGroup;
  currencyPipe: CurrencyPipe;

  constructor() {
    this.currencyPipe = new CurrencyPipe('pt-BR');
    this.group = new FormGroup({
      stock: new FormControl(0),
      quantity: new FormControl(0),
      unitPrice: new FormControl(0),
      totalPrice: new FormControl(0),
      currentTotalPrice: new FormControl(0),
      reason: new FormControl('', [Validators.required]),
    });
    this.controls.quantity.addValidators(this.validateQuantity());
  }

  get controls() {
    return {
      stock: this.group.get('stock') as FormControl,
      quantity: this.group.get('quantity') as FormControl,
      unitPrice: this.group.get('unitPrice') as FormControl,
      totalPrice: this.group.get('totalPrice') as FormControl,
      currentTotalPrice: this.group.get('currentTotalPrice') as FormControl,
      reason: this.group.get('reason') as FormControl,
    };
  }

  get totalPrice() {
    return this.controls.unitPrice.getRawValue() * this.controls.quantity.getRawValue();
  }

  get diffPrice() {
    return this.totalPrice - this.controls.currentTotalPrice.getRawValue();
  }

  get value() {
    return {
      reason: this.controls.reason.value as string,
      quantity: this.controls.quantity.value as number,
      stock: this.controls.stock.value as number,
      unitPrice: {
        formmated: this.currencyPipe.transform(this.controls.unitPrice.value, 'BRL'),
        raw: this.controls.unitPrice.getRawValue() as number,
      },
      totalPrice: {
        formmated: this.currencyPipe.transform(this.totalPrice, 'BRL'),
        raw: this.totalPrice as number,
      },
      diffPrice: {
        formmated: this.currencyPipe.transform(this.diffPrice, 'BRL'),
        raw: this.diffPrice as number,
      },
    };
  }

  setValue(current: OrderProduct, substitute: OrderSimilarProduct) {
    this.controls.stock.setValue(substitute.stock);
    this.controls.quantity.setValue(current.quantity);
    this.controls.unitPrice.setValue(substitute.salePrice);
    this.controls.currentTotalPrice.setValue(current.totalPrice);
    this.controls.quantity.addValidators([Validators.required, Validators.number.min(1), Validators.number.max(current.quantity)]);
    this.group.updateValueAndValidity();
  }

  private validateQuantity() {
    return () => {
      if (this.value.quantity > this.value.stock) {
        return { stock: { message: `Estoque disponível ${this.value.stock} und.` } };
      }

      return null;
    };
  }
}
