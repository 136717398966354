import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@peca/commons';
import { BuyerNaturalPerson } from '../models/buyer-natural-person-request.model';

export class BuyerNaturalPersonForm {
  group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      id: new FormControl(''),
      shippingAddress: new FormControl(null, [Validators.required]),
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required, Validators.cpf]),
      birthDate: new FormControl('', [Validators.required, Validators.date]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl('', [Validators.required, Validators.contactType('CELLPHONE')]),
    });
  }

  get controls() {
    return {
      id: this.group.get('id') as FormControl,
      shippingAddress: this.group.get('shippingAddress') as FormControl,
      name: this.group.get('name') as FormControl,
      surname: this.group.get('surname') as FormControl,
      cpf: this.group.get('cpf') as FormControl,
      birthDate: this.group.get('birthDate') as FormControl,
      email: this.group.get('email') as FormControl,
      cellphone: this.group.get('cellphone') as FormControl,
    };
  }

  get value() {
    return {
      id: this.controls.id.getRawValue(),
      shippingAddress: this.controls.shippingAddress.getRawValue(),
      name: this.controls.name.getRawValue(),
      surname: this.controls.surname.getRawValue(),
      cpf: this.controls.cpf.getRawValue(),
      birthDate: this.controls.birthDate.getRawValue(),
      email: this.controls.email.getRawValue(),
      cellphone: this.controls.cellphone.getRawValue(),
    };
  }

  set value(value: BuyerNaturalPerson) {
    this.controls.id.setValue(value.id);
    this.controls.shippingAddress.setValue(value.shippingAddress);
    this.controls.name.setValue(value.name);
    this.controls.surname.setValue(value.surname);
    this.controls.cpf.setValue(value.cpf);
    this.controls.birthDate.setValue(value.birthDate);
    this.controls.email.setValue(value.email);
    this.controls.cellphone.setValue(value.cellphone);
  }
}
