import { BehaviorSubject } from 'rxjs';
import { ToastService } from '@peca/commons';
import { ModalComponent } from '@peca/design-system';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';

import { OrderService } from '../../services/order.service';
import { OrderProduct } from '../../models/order-product.modal';
import { OrderSimilarProduct } from '../../models/order-similar-product.model';
import { OrderProductRequest } from '../../models/order-product-request.model';
import { OrderReplaceProductForm } from '../../forms/order-replace-product.form';

@Component({
  selector: 'peca-order-replace-seller',
  templateUrl: './order-replace-seller.component.html',
  styleUrls: ['./order-replace-seller.component.scss'],
})
export class OrderReplaceSellerComponent {
  @ViewChild('modal')
  modal!: ModalComponent;

  @Output()
  adjust: EventEmitter<void>;

  orderId!: string;
  currentProduct: OrderProduct | undefined;
  substituteProduct: OrderSimilarProduct | undefined;
  form: OrderReplaceProductForm;
  orderService: OrderService;
  products$: BehaviorSubject<AsyncState<OrderSimilarProduct[]>>;
  toast: ToastService;
  onlySameSeller: boolean;

  constructor() {
    this.products$ = new BehaviorSubject<AsyncState<OrderSimilarProduct[]>>({ loading: true });
    this.form = new OrderReplaceProductForm();
    this.orderService = inject(OrderService);
    this.toast = inject(ToastService);
    this.adjust = new EventEmitter<void>();
    this.onlySameSeller = false;
  }

  open(orderId: string, product: OrderProduct) {
    this.orderId = orderId;
    this.currentProduct = product;
    this.modal.open();
    this.fetchSimilarProductsByBranch(orderId, product.id);
  }

  close() {
    this.modal.close();
    this.products$.next({});
    this.form.group.patchValue({
      agreement: true,
      stock: 0,
      quantity: 0,
      unitPrice: 0,
      totalPrice: 0,
      currentTotalPrice: 0,
    });
    this.form.controls.quantity.clearValidators();
  }

  fetchSimilarProductsByBranch(orderId: string, productId: string) {
    this.products$.next({ loading: true });
    this.orderService.fetchSimilarProductsByBranch(orderId, productId).subscribe({
      next: (data: OrderSimilarProduct[]) => this.products$.next({ data }),
      error: () => this.products$.next({ failure: true }),
    });
  }

  onClickSelectProduct(product: OrderSimilarProduct) {
    if (!this.currentProduct) return;

    this.substituteProduct = product;
    this.form.setValue(this.currentProduct, product);
  }

  onClickRemoveSubstituteProduct() {
    this.substituteProduct = undefined;
  }

  onClickSubmit() {
    if (!this.substituteProduct || !this.currentProduct || this.form.group.invalid) return;

    const product: OrderProductRequest = {
      branchId: this.substituteProduct.branch.id,
      brandId: this.substituteProduct.brand.id,
      partNumber: this.substituteProduct.partNumber,
      productId: this.currentProduct.id,
      orderId: this.orderId,
      quantity: this.form.value.quantity,
      reason: this.form.value.reason,
    };

    this.orderService.updateProductBranch(product).subscribe({
      next: () => {
        this.toast.success('Vendedor alterado com sucesso.');
        this.adjust.emit();
        this.close();
      },
      error: () => this.toast.failure('Não foi possível alterar o produdo do pedido.'),
    });
  }
}
