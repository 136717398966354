import { Pipe, PipeTransform } from '@angular/core';
import { OrderReturn } from '../models/orders-return.model';
import { OrderReturnDetails } from '../models/orders-return-details.model';

@Pipe({ name: 'invoiceWarning' })
export class OrderReturnInvoiceWarningPipe implements PipeTransform {
  transform(order: OrderReturn | OrderReturnDetails) {
    return order.status === 'APPROVED' && order.payment === 'PAID' && !order.invoice && order.order.internalStatus !== 'CANCELED';
  }
}
