<pds-modal #modal title="Alterar Status" [width]="1024" (dismiss)="onDismiss()">
  <pds-form-section [formGroup]="form.group">
    <pds-form-row grid="1-1">
      <pds-form-field label="Status atual">
        <input type="text" [value]="form.value.currentStatus | logTypeStatus" readonly disabled />
      </pds-form-field>

      <pds-form-field label="Novo status">
        <select formControlName="newStatus" *ngIf="statusSoruce.length; else noStatusTemplate">
          <option value="" disabled>Selecione</option>
          <ng-container *ngFor="let option of statusSoruce">
            <option [value]="option.value">{{ option.label }}</option>
          </ng-container>
        </select>
        <ng-template #noStatusTemplate>
          <input type="text" value="Nenhum status subsequente" readonly disabled />
        </ng-template>
      </pds-form-field>
    </pds-form-row>

    <ng-container *ngIf="form.value.newStatus === 'MISPLACED'">
      <pds-form-row grid="1-2">
        <pds-form-field label="Pedido relacionado">
          <pds-autocomplete formControlName="parentOrder" [source]="orders$" (autocomplete)="onCompleteOrders($event)" />
        </pds-form-field>
      </pds-form-row>

      <pds-alert title="Atenção" type="warning" icon="warning"> O número inserido em "Pedido Relacionado" deve ser de um PA já existente e diferente do PA atual ou não será possível finalizar a ação. </pds-alert>
    </ng-container>

    <pds-form-actions>
      <pds-button (click)="onClickSubmit()" [disabled]="form.group.invalid">alterar</pds-button>
    </pds-form-actions>
  </pds-form-section>
</pds-modal>
