import { Component, Input } from '@angular/core';
import { ProductDetails } from '../../../models/product-details.model';

@Component({
  selector: 'peca-product-details-crossreference[product]',
  templateUrl: './product-details-crossreference.component.html',
  styleUrls: ['./product-details-crossreference.component.scss'],
})
export class ProductDetailsCrossreferenceComponent {
  @Input()
  product!: ProductDetails;
}
