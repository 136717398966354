import { Pipe, PipeTransform } from '@angular/core';
import { ProductImage } from '../models/product-details.model';

@Pipe({ name: 'thumbnail' })
export class ProductThumbnailPipe implements PipeTransform {
  transform(images: ProductImage | ProductImage[]) {
    if (Array.isArray(images)) {
      if (!images || !images.length) return '';

      const image = this.getPriorityImage(images);

      return image.thumbnailUrl;
    }

    return images.thumbnailUrl;
  }

  private getPriorityImage(images: ProductImage[]) {
    let image;

    image = images.find(({ category }) => category === 'Foto principal');

    if (image) return image;

    image = images.find(({ category }) => category === 'Foto');

    if (image) return image;

    image = images.find(({ category }) => category === 'Desenho técnico');

    if (image) return image;

    return images[0];
  }
}
