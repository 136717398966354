import { Component, Input } from '@angular/core';
import { PurchaseOrder } from '../../models/purchase-order.model';

@Component({
  selector: 'peca-purchase-order-details-summary[order]',
  templateUrl: './purchase-order-details-summary.component.html',
  styleUrls: ['./purchase-order-details-summary.component.scss'],
})
export class PurchaseOrderDetailsSummaryComponent {
  @Input()
  order!: PurchaseOrder;
}
