<ng-container *ngIf="products.state$ | async; let products">
  @if (products.data && products.data.length) {
  <pds-table>
    <table>
      <thead>
        <tr>
          <th>Vendedor</th>
          <th>Quantidade</th>
          <th>Nome</th>
          <th>PN</th>
          <th>Marca</th>
          <th>Valor</th>
          <th>Pedido de Compra</th>
        </tr>
      </thead>
      <tbody>
        @for (product of products.data; track $index) {
        <tr>
          <td>
            <div class="flex flex-column gap-4">
              <strong>{{ product.branch.tradingName }}</strong>
              <small>{{ product.branch.commercialName }} ({{ product.branch.externalReference }})</small>
            </div>
          </td>
          <td>{{ product.returnedQuantity }}</td>
          <td>{{ product.description }}</td>
          <td>{{ product.partNumber }}</td>
          <td>{{ product.brand }}</td>
          <td>{{ product.refundAmount | currency : 'BRL' }}</td>
          <td>
            <a [routerLink]="['/compras', product.purchaseOrder.id]" pdsChipLink target="_blank">PC-{{ product.purchaseOrder.id | slice : -6 | uppercase }}</a>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </pds-table>
  } @else if (products.data && !products.data.length) {
  <strong>Nenhum produto encontrado.</strong>
  } @else if (products.loading){
  <strong>carregando...</strong>
  } @else if (products.failed) {
  <peca-retry context="produtos" (retry)="onRetryEvent()" />
  }
</ng-container>
