<div role="alert" aria-live="polite" aria-atomic="true" class="peca-retry">
  <div class="peca-retry__content">
    <h2><span class="icon">report</span><span>Falha ao carregar</span></h2>
    <p>Não foi possível carregar {{ context }}. Caso o problema persista, entre em contato com o suporte.</p>
  </div>
  <button type="button" class="peca-retry__button" (click)="onClickRetry()">
    <span class="icon">refresh</span>
    <span>Tentar novamente</span>
  </button>
</div>
