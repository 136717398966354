import { Component, inject, Input, ViewChild } from '@angular/core';
import { Order } from '../../models/order.model';
import { DocumentPreviewComponent } from '@peca/commons';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'peca-order-summary[order]',
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss',
})
export class OrderSummaryComponent {
  @Input()
  order!: Order;
  @ViewChild('documentPreview')
  documentPreview!: DocumentPreviewComponent;
  orderService: OrderService;

  constructor() {
    this.orderService = inject(OrderService);
  }

  onClickPreviewInvoice(order: Order) {
    this.orderService.dowloadInvoice(order.invoice.id).subscribe((res) => {
      this.documentPreview.open({ id: order.id, title: order.commerceReference, type: `application/pdf`, url: res });
    });
  }
}
