import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { ToastService } from '@peca/commons';
import { AnalyticsService } from '@peca/commons';

import { environment } from 'apps/backoffice/src/environments/environment';
import { OrderProduct } from '../../../models/order-product.modal';
import { Order } from '../../../models/order.model';
import { OrderReplaceSellerComponent } from '../../order-replace-seller/order-replace-seller.component';
import { OrderOperationsValidadorService } from '../../../services/order-operations-validador.service';

@Component({
  templateUrl: './order-details-products.component.html',
  styleUrls: ['./order-details-products.component.scss'],
})
export class OrderDetailsProductsComponent implements OnInit, OnDestroy {
  @Output()
  reload: EventEmitter<void>;

  @ViewChild('replaceSellerComponent')
  replaceSellerComponent!: OrderReplaceSellerComponent;

  destroyRef$: Subject<void>;
  http: HttpClient;
  route: ActivatedRoute;
  products$: BehaviorSubject<AsyncState<OrderProduct[]>>;
  order: Order | null;
  toast: ToastService;
  analytics: AnalyticsService;
  orderOperationsValidador: OrderOperationsValidadorService;

  constructor() {
    this.http = inject(HttpClient);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.analytics = inject(AnalyticsService);
    this.orderOperationsValidador = inject(OrderOperationsValidadorService);
    this.order = null;
    this.destroyRef$ = new Subject();
    this.products$ = new BehaviorSubject<AsyncState<OrderProduct[]>>({ loading: true });
    this.reload = new EventEmitter();
  }

  get orderId() {
    return this.route.parent?.snapshot.params['orderId'] as string;
  }

  ngOnInit() {
    const orderId = this.orderId;
    this.fetchProducts(orderId);
  }

  ngOnDestroy(): void {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  onClickReplaceSeller(product: OrderProduct) {
    const validation = this.orderOperationsValidador.validateSellerAdjustments(this.order as Order);

    if (validation) {
      this.toast.warning(validation.message);
      return;
    }

    this.replaceSellerComponent.open(this.orderId, product);
  }

  onAdjust() {
    this.reload.next();
  }

  private onFetchProductsSuccess(products: OrderProduct[], order: Order) {
    this.order = order;
    this.products$.next({ data: products });
  }

  private onFetchProductsFailure(e: unknown) {
    this.products$.next({ failure: true });
  }

  private fetchProducts(orderId: string) {
    const products = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/products`;
    const order = `${environment.gateway.url}/backoffice/v1/orders/${orderId}`;

    this.products$.next({ loading: true });

    return combineLatest({
      products: this.http.get<OrderProduct[]>(products),
      order: this.http.get<Order>(order),
    }).subscribe({
      next: ({ order, products }) => this.onFetchProductsSuccess(products, order),
      error: this.onFetchProductsFailure.bind(this),
    });
  }
}
