import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'status' })
export class OrdersReturnStatusPipe implements PipeTransform {
  transform(value: string) {
    if (!value) return '-';

    switch (value) {
      case 'PROCESSING':
        return 'Processando';
      case 'PENDING_COLLECTION':
        return 'Pendente de coleta';
      case 'IN_TRANSIT':
        return 'Em trânsito';
      case 'UNDER_ANALYSIS':
        return 'Em análise';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Rejeitado';
      case 'PENDING':
        return 'Pendente';
      case 'PAID':
        return 'Pago';
      case 'NOT_PAID':
        return 'Não pago';
      case 'CANCELED':
        return 'Estorno cancelado';
      default:
        return value;
    }
  }
}
