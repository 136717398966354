import { Route } from '@angular/router';
import { DeliveryListComponent } from './components/delivery-list/delivery-list.component';

export const routes: Route[] = [
  {
    path: '',
    component: DeliveryListComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const DeliveriesModuleLoad = async () => {
  const { DeliveriesModule } = await import('./deliveries.module');
  return DeliveriesModule;
};
