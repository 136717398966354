import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusType } from '../models/order-status';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  status: Record<OrderStatusType, { label: string; color: { color: string; background: string } }> = {
    PROCESSING_PAYMENT: { label: 'Processando Pagamento', color: { background: '#A8DADC', color: '#a4b0be' } },
    PAYMENT_ACCEPT: { label: 'Pagamento Aprovado', color: { background: '#A8E6CF', color: '#a4b0be' } },
    PAYMENT_REFUSED: { label: 'Pagamento Recusado', color: { background: '#F8BBD0', color: '#a4b0be' } },
    REQUIRES_MANUAL_ADJUSTMENT: { label: 'Aguardando Ajustes', color: { background: '#FFD180', color: '#a4b0be' } },
    WAITING_FOR_SELLER: { label: 'Aguardando Vendedor', color: { background: '#E6E6FA', color: '#a4b0be' } },
    IN_SEPARATION: { label: 'Em Separação', color: { background: '#B2EBF2', color: '#a4b0be' } },
    AWAITING_COLLECTION: { label: 'Aguardando Coleta', color: { background: '#CCFF90', color: '#a4b0be' } },
    ON_ROUTE: { label: 'Coletado Pela Transportadora', color: { background: '#B3E5FC', color: '#a4b0be' } },
    DELIVERED: { label: 'Entregue', color: { background: '#81ecec', color: '#a4b0be' } },
    CANCELED: { label: 'Cancelado', color: { background: '#F8BBD0', color: '#a4b0be' } },
    PROCESSING_RETURN: { label: 'Processando Devolução', color: { background: '#FFE0B2', color: '#a4b0be' } },
    PARTIAL_RETURN: { label: 'Devolvido Parcialmente', color: { background: '#FFAB91', color: '#a4b0be' } },
    TOTAL_RETURN: { label: 'Devolvido', color: { background: '#FFCDD2', color: '#a4b0be' } },
    MISPLACED: { label: 'Extraviado', color: { background: '#FFF9C4', color: '#a4b0be' } },
  };

  transform(key: OrderStatusType, kind: 'label' | 'color' = 'label') {
    const status = this.status[key];

    if (!status) {
      return kind === 'label' ? key : '#F4F4F4';
    }

    if (kind === 'label') {
      return status.label;
    }

    return status.color;
  }
}
