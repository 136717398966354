<pds-form [formGroup]="form.group">
  <pds-form-row grid="1-1-2">
    <pds-form-field label="Nome">
      <input type="text" formControlName="firstName" placeholder="Nome do responsável" />
    </pds-form-field>

    <pds-form-field label="Sobrenome">
      <input type="text" formControlName="lastName" placeholder="Sobrenome do responsável" />
    </pds-form-field>

    <pds-form-field label="Observações">
      <input type="text" formControlName="observation" placeholder="Observações" />
    </pds-form-field>
  </pds-form-row>

  <ng-container formGroupName="contacts">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="E-mail" icon="alternate_email" formGroupName="email">
        <input type="text" formControlName="contact" pdsMask="email" [alias]="true" />
      </pds-form-field>

      <pds-form-field label="Whatsapp" icon="sms" formGroupName="whatsapp">
        <input type="text" formControlName="contact" pdsMask="(99) 9999-9999[9]" [alias]="true" />
      </pds-form-field>

      <pds-form-field label="Celular" icon="smartphone" formGroupName="cellphone" [errors]="form.controls.contacts.errors">
        <input type="text" formControlName="contact" pdsMask="(99) 9999-9999[9]" />
      </pds-form-field>

      <pds-form-field label="Telefone" icon="call_end" formGroupName="phone">
        <input type="text" formControlName="contact" pdsMask="(99) 9999-9999" />
      </pds-form-field>
    </pds-form-row>
  </ng-container>
</pds-form>
