<ng-container *ngIf="logs$ | async; let logs">
  <ng-container *ngIf="logs.loading">
    <strong>carregando...</strong>
  </ng-container>
  <ng-container *ngIf="logs.failure">
    <strong>Não foi possível carregar os logs do pedido.</strong>
  </ng-container>
  <ng-container *ngIf="logs.data && !logs.data.length">
    <strong>Nenhum log encontrado para o pedido.</strong>
  </ng-container>

  <pds-table *ngIf="logs.data && logs.data.length">
    <table>
      <thead>
        <tr>
          <th rowspan="2">Data / Hora</th>
          <th rowspan="2">Usuário</th>
          <th rowspan="2">Tipo de evento</th>
          <th colspan="2">Evento</th>
        </tr>
        <tr>
          <th>De</th>
          <th>Para</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let log of logs.data">
          <td>{{ log.createdAt | date : 'dd/MM/yy HH:mm:ss' }}</td>
          <td>{{ log.author || 'SISTEMA' }}</td>
          <td>{{ log.changeType | logType }}</td>
          <td>{{ log | log : 'from' | logTypeStatus }}</td>
          <td>{{ log | log : 'to' | logTypeStatus }}</td>
        </tr>
      </tbody>
    </table>
  </pds-table>
</ng-container>
