<div class="seller-list-search">
  <pds-form [formGroup]="form">
    <pds-form-row grid="1-1-1">
      <pds-form-field label="Busca">
        <input type="text" placeholder="Pesquise por CNPJ, nome comercial ou e-mail" formControlName="search" />
      </pds-form-field>

      <pds-form-field label="Modelo de venda">
        <select formControlName="salesModel">
          <option value="">Todos</option>
          <option value="1P">Modelo 1P</option>
          <option value="3P">Modelo 3P</option>
        </select>
      </pds-form-field>

      <pds-form-field label="Status">
        <select formControlName="active">
          <option value="">Todos</option>
          <option value="true">Ativos</option>
          <option value="false">Inativos</option>
        </select>
      </pds-form-field>
    </pds-form-row>

    <pds-form-actions>
      <button pdsButton="primary" modifier="outlined" (click)="clearFilters()" type="button" [disabled]="form.pristine">Limpar filtros</button>
      <button pdsButton type="submit" (click)="onSubmitSearch()">Buscar</button>
    </pds-form-actions>
  </pds-form>
</div>
