import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, Output, ViewChild, inject } from '@angular/core';
import { forwardRefProvider } from '@peca/backoffice/providers/forward-ref.provider';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, filter, fromEvent, map, switchMap, takeUntil, tap } from 'rxjs';
import { ControlValueAccessor } from '@angular/forms';
import { ToastService } from '@peca/commons';
import { BuyerLegalPersonService } from '../../services/buyer-legal-person.service';

type Farmer = { name: string; email: string; id: string };
type State = AsyncState<Farmer[]>;

@Component({
  selector: 'peca-buyer-representative-form',
  templateUrl: './buyer-representative-form.component.html',
  styleUrls: ['./buyer-representative-form.component.scss'],
  providers: [forwardRefProvider(BuyerRepresentativeFormComponent)],
})
export class BuyerRepresentativeFormComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @ViewChild('search')
  searchElementRef!: ElementRef;
  @Output()
  createNewUser: EventEmitter<void>;
  buyerService: BuyerLegalPersonService;
  destroyRef$: Subject<void>;
  farmers$: BehaviorSubject<State>;
  open$: BehaviorSubject<boolean>;
  value: any[];
  disabled: boolean;
  toast: ToastService;

  changed!: (value: number[]) => void;
  touched!: () => void;

  constructor() {
    this.createNewUser = new EventEmitter<void>();
    this.buyerService = inject(BuyerLegalPersonService);
    this.destroyRef$ = new Subject<void>();
    this.farmers$ = new BehaviorSubject<State>({});
    this.open$ = new BehaviorSubject(false);
    this.value = [];
    this.disabled = false;
    this.toast = inject(ToastService);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.complete();
  }

  writeValue(value: any[]) {
    this.value = value || [];
  }

  registerOnChange(fn: (value: number[]) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngAfterViewInit() {
    fromEvent<KeyboardEvent>(this.searchElementRef.nativeElement, 'keyup')
      .pipe(
        map((event) => (event.target as HTMLFormElement)['value'].trim()),
        distinctUntilChanged(),
        tap(() => this.farmers$.next({})),
        filter((value) => value.length > 2),
        debounceTime(1000),
        takeUntil(this.destroyRef$),
        tap(() => this.farmers$.next({ loading: true })),
        switchMap(this.buyerService.suggestFarmers.bind(this.buyerService))
      )
      .subscribe({
        next: (data) => {
          if (!data) return this.farmers$.next({ failure: true });
          this.farmers$.next({ data });
        },
        error: () => this.farmers$.next({ failure: true }),
      });
  }

  @HostListener('window:keydown.escape', ['$event'])
  clearAndClose() {
    this.open$.next(false);
    this.farmers$.next({});
    this.searchElementRef.nativeElement.blur();
    this.searchElementRef.nativeElement.value = '';
  }

  onFocus() {
    this.open$.next(true);
  }

  onClickOverlay() {
    this.clearAndClose();
  }

  onKeydownTab(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  onCreateNewUser() {
    this.clearAndClose();
    this.createNewUser.next();
  }

  onClickItem(farmer: Farmer) {
    if (this.value.some((item) => item.id === farmer.id)) {
      this.toast.success(`O representante ${farmer.name} já está cadastrado.`);
      return;
    }

    this.value.push(farmer);
    this.changed(this.value);
    this.clearAndClose();
  }
}
