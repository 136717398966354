import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { KeycloakCredentialsService, keycloakHttpInterceptorProvider, keyclockProviderFactory } from '@peca/keycloak';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AppComponent } from './app.component';
import { ANALYTICS_CONFIG, CommonsModule, loadingOverlayInterceptorProvider, monitoringInterceptorProvider } from '@peca/commons';
import { ConfirmableActionComponent } from './core/components/confirmable-action/confirmable-action.component';
import { DesignSystemModule, InsightsService } from '@peca/design-system';
import { ConfirmableActionService } from './core/services/confirmable-action.service';
import { menu } from './core/menu.config';
import { MAIN_NAVIGATION_CONFIG } from 'libs/design-system/src/lib/components/main-navigation/main-navigation.config';

registerLocaleData(pt);

@NgModule({
  declarations: [AppComponent, ConfirmableActionComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    BrowserAnimationsModule,
    CommonsModule,
    DesignSystemModule,
  ],
  providers: [
    { provide: MAIN_NAVIGATION_CONFIG, useValue: menu },
    { provide: ANALYTICS_CONFIG, useValue: environment.mixpanel },
    { provide: LOCALE_ID, useValue: 'pt' },
    keyclockProviderFactory(environment.keycloak as KeycloakOptions),
    monitoringInterceptorProvider,
    loadingOverlayInterceptorProvider,
    KeycloakService,
    KeycloakCredentialsService,
    keycloakHttpInterceptorProvider,
    ConfirmableActionService,
    InsightsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
