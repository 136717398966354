import { ProductSearchQuery } from '../product-search-query.model';

export const storefrontSearchSchema = (query: ProductSearchQuery) => {
  const filters = prepareFilters(query);

  return JSON.stringify({
    query: `query CatalogSearchWithZipcode {
    catalogSearchWithZipcode(
        input: {
          plate: "${query.plate}",
          zipcode: ${query.zipcode},
          query: "${query.search}",
          filters: ${filters}
        }
        page: ${query.page || 0}
        limit: ${query.size || 10}
    ) {
        products {
            edges {
                productId
                name
                partNumber
                price
                unitPrice
                qmvDescription
                img {
                  thumbnailUrl
                }
                specs {
                    id
                    type
                    name
                    label
                    value
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                pageSize
                totalPages
            }
            totalCount
        }
    }
  }`,
  });
};

function prepareFilters(query: ProductSearchQuery) {
  let filters = `[`;

  if (query.brand) filters += `{ type: BRAND, id: "${query.brand}" }`;

  if (query.group) filters += `{ type: GROUP_PRODUCT, id: "${query.group}" }`;

  filters += `]`;

  return filters;
}
