<pds-module-header title="Produtos" ctaPath="/produtos" ctaLabel="Voltar" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <div class="product-details">
    <ng-container *ngIf="product$ | async; let product">
      <ng-container *ngIf="product.loading">
        <h2>carregando...</h2>
      </ng-container>

      <ng-container *ngIf="product.failure">
        <h2>Não foi possível carregar as informações do produto. Por favor, tente novamente mais tarde.</h2>
      </ng-container>

      <ng-container *ngIf="product.data">
        <hr />

        <div class="product-details__content">
          <div>
            <div class="product-details__content__image">
              <ng-container *ngIf="product.data.images?.length">
                <img [src]="product.data.images | thumbnail" [alt]="product.data.partNumber" />
              </ng-container>
            </div>

            <ul class="product-details__content__menu">
              <ng-container *ngFor="let item of menu">
                <li role="button" [class.--active]="item.active" (click)="handleItemMenuClickEvent(item)">{{ item.label }}</li>
              </ng-container>
            </ul>
          </div>

          <div>
            <div class="product-details__row">
              <div class="product-details__field">
                <label class="product-details__field__label">Código</label>
                <span class="product-details__field__value">{{ product.data.partNumber }}</span>
              </div>

              <div class="product-details__field">
                <label class="product-details__field__label">Marca</label>
                <span class="product-details__field__value">{{ product.data.brand.name }}</span>
              </div>

              <div class="product-details__field">
                <label class="product-details__field__label">Grupo</label>
                <span class="product-details__field__value">{{ product.data.applications | group }}</span>
              </div>

              <div class="product-details__field">
                <label class="product-details__field__label">Descrição</label>
                <span class="product-details__field__value">{{ product.data.applications | description }}</span>
              </div>
            </div>

            <hr />

            <ng-container *ngIf="activatedMenu.key === 'details'">
              <peca-product-details-spec [product]="product.data"></peca-product-details-spec>
            </ng-container>

            <ng-container *ngIf="activatedMenu.key === 'distributors'">
              <peca-product-details-distributors [product]="product.data"></peca-product-details-distributors>
            </ng-container>

            <ng-container *ngIf="activatedMenu.key === 'images'">
              <peca-product-details-images [product]="product.data"></peca-product-details-images>
            </ng-container>

            <ng-container *ngIf="activatedMenu.key === 'cross'">
              <peca-product-details-crossreference [product]="product.data"></peca-product-details-crossreference>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</pds-module-header>
