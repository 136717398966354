<pds-module-header title="Produtos">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <pds-panel>
    <peca-product-search
      [loading]="loading$"
      (alternate)="handleSearchModeAlternate($event)"
      (search)="handleSearchEvent($event)"
    ></peca-product-search>
  </pds-panel>

  <pds-panel class="mt-24">
    <ng-container *ngIf="mode === 'STOREFRONT'; else productTableTemplate">
      <peca-product-card [query]="query$" (changed)="handleSearchChange($event)"></peca-product-card>
    </ng-container>

    <ng-template #productTableTemplate>
      <peca-product-table [query]="query$" (changed)="handleSearchChange($event)"></peca-product-table>
    </ng-template>

    <pds-pagination [pagination]="pagination$" (paginate)="handlePaginateEvent($event)"></pds-pagination>
  </pds-panel>
</pds-module-header>
