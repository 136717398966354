import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BreadcrumbItem, InsightsService, Pagination, PaginationInitialState } from '@peca/design-system';
import { Subject } from 'rxjs';
import { Async } from '@peca/backoffice/utils/async.util';
import { Router } from '@angular/router';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { ToastService } from '@peca/commons';

import { BuyerLegalPersonService } from '../../../services/buyer-legal-person.service';
import { BuyerLegalPersonQuery } from '../../../models/buyer-legal-person/buyer-legal-person-query.model';
import { BuyerLegalPersonResponse } from '../../../models/buyer-legal-person/buyer-legal-person-response.model';

@Component({
  templateUrl: './buyer-legal-person-list.component.html',
  styleUrls: ['./buyer-legal-person-list.component.scss'],
})
export class BuyerLegalPersonListComponent implements OnInit, OnDestroy {
  breadcrumb: BreadcrumbItem[];
  form: FormGroup;
  pagination$: Subject<Pagination>;
  lastQuery: BuyerLegalPersonQuery;
  buyerService: BuyerLegalPersonService;
  buyers: Async<BuyerLegalPersonResponse[]>;
  router: Router;
  confirmable: ConfirmableActionService;
  toast: ToastService;
  insightsService: InsightsService;
  private readonly searchFormInitialValue = {
    search: '',
    active: '',
    status: '',
    sort: 'audit.createdAt,desc',
  };

  constructor() {
    this.breadcrumb = [{ label: 'Compradores', path: '/compradores' }, { label: 'Pessoa Jurídica' }];
    this.form = new FormGroup({
      search: new FormControl(''),
      active: new FormControl(''),
      status: new FormControl(''),
      sort: new FormControl('audit.createdAt,desc'),
    });
    this.pagination$ = new Subject<Pagination>();
    this.lastQuery = { ...PaginationInitialState, sort: 'audit.createdAt,desc' };
    this.buyerService = inject(BuyerLegalPersonService);
    this.buyers = new Async<BuyerLegalPersonResponse[]>();
    this.router = inject(Router);
    this.confirmable = inject(ConfirmableActionService);
    this.toast = inject(ToastService);
    this.insightsService = inject(InsightsService);

    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
  }

  get controls() {
    return {
      search: this.form.get('search') as FormControl,
      active: this.form.get('active') as FormControl,
      status: this.form.get('status') as FormControl,
      sort: this.form.get('sort') as FormControl,
    };
  }

  get value() {
    return {
      search: this.controls.search.getRawValue(),
      active: this.controls.active.getRawValue(),
      status: this.controls.status.getRawValue(),
      sort: this.controls.sort.getRawValue(),
    };
  }

  ngOnInit() {
    this.fetchBuyers(this.lastQuery);
    this.fetchInsights();
  }

  ngOnDestroy() {
    this.insightsService.clear();
  }

  fetchInsights() {
    this.buyerService.fetchInsights().subscribe((response) => {
      this.insightsService.push([{ type: 'text', label: 'Cadastros pendentes', value: response.pendingAnalysis }]);
    });
  }

  fetchBuyers(query: BuyerLegalPersonQuery) {
    this.lastQuery = query;
    this.buyers.loading();
    this.buyerService.fetchBuyers(query).subscribe({
      next: (response) => {
        this.buyers.loaded(response.items);
        this.paginate(response);
      },
      error: () => {
        this.buyers.failed();
      },
    });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  updateStatus(buyer: BuyerLegalPersonResponse) {
    this.buyerService.updateStatus(buyer.id, buyer.active).subscribe({
      next: () => {
        this.toast.success(`Comprador ${buyer.active ? 'ativado' : 'desativado'} com sucesso.`);
      },
      error: () => {
        this.toast.failure(`Falha ao ${buyer.active ? 'ativar' : 'desativar'} comprador.`);
        buyer.active = !buyer.active;
      },
    });
  }

  onClickSearch() {
    if (this.form.invalid) return;

    const query = { ...PaginationInitialState, ...this.form.value };
    this.fetchBuyers(query);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);

    const query = { ...PaginationInitialState, ...this.form.value };
    this.fetchBuyers(query);
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) this.form.markAsPristine();
  }

  onEventRetry() {
    this.fetchBuyers(this.lastQuery);
  }

  onChangeActive(buyer: BuyerLegalPersonResponse) {
    const message = `Você está ${!buyer.active ? 'ativando' : 'desativando'} o comprador ${buyer.companyName}.`;
    this.confirmable.confirm(message, buyer).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        buyer.active = !buyer.active;
        return;
      }

      this.updateStatus(buyer);
    });
  }

  onClickDetails(buyer: BuyerLegalPersonResponse) {
    this.router.navigate(['/compradores', 'pessoa-juridica', buyer.id]);
  }

  onEventPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchBuyers(query);
  }
}
