import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem, Tab } from '@peca/design-system';

@Component({
  templateUrl: './buyer-container-form-tabs.component.html',
  styleUrls: ['./buyer-container-form-tabs.component.scss'],
})
export class BuyerContainerFormTabsComponent implements OnInit {
  route: ActivatedRoute;
  breadcrumb: BreadcrumbItem[];
  router: Router;
  readonly tabs: Tab[];

  constructor() {
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.breadcrumb = [
      { label: 'Compradores', path: '/compradores' },
      { label: 'Pessoa Jurídica', path: '/compradores/pessoa-juridica' },
      { label: 'Detalhes', path: '/compradores/pessoa-juridica/Detalhes' },
    ];
    this.tabs = [
      {
        label: 'Detalhes',
        target: 'details',
        active: true,
        roles: [],
      },
      {
        label: 'Faturas',
        target: 'bill',
        active: false,
        roles: [['compradores-faturas-listagem']],
      },
    ];
  }

  ngOnInit() {
    const currentRoute = this.route.snapshot.firstChild?.routeConfig?.path;
    this.setActiveTab(currentRoute);
  }

  setActiveTab(currentRoute: string | undefined) {
    if (!currentRoute) {
      this.router.navigate(['detalhes'], { relativeTo: this.route });
      currentRoute = 'detalhes';
    }

    this.tabs.forEach((tab) => (tab.active = false));
    const matchingTab = this.tabs.find((tab) => tab.label.toLowerCase() === currentRoute);
    if (matchingTab) {
      this.breadcrumb[2].label = matchingTab?.label;
      matchingTab.active = true;
    }
  }

  handleTabSelectEvent(tab: Tab) {
    const currentTab = tab.label.toLowerCase();
    this.setActiveTab(currentTab);
    this.router.navigate([currentTab], { relativeTo: this.route });
  }

  get buyerId() {
    return this.route.snapshot.params['id'] as string;
  }
}
