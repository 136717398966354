import { Pipe, PipeTransform } from '@angular/core';

type Op = 'eq' | 'gt' | 'gte' | 'lt' | 'lte';

@Pipe({ name: 'size', standalone: true })
export class SizePipe implements PipeTransform {
  transform(value: string | unknown[] | null, op: Op, size: number) {
    if (!value) return false;

    switch (op) {
      case 'eq':
        return value.length === size;
      case 'gt':
        return value.length > size;
      case 'gte':
        return value.length >= size;
      case 'lt':
        return value.length < size;
      case 'lte':
        return value.length <= size;
    }
  }
}
