<div class="product-details-specs">
  <h3 class="mb-24">Especificações</h3>

  <ul class="product-details-specs__specification-list">
    <ng-container *ngFor="let specification of product.specifications">
      <li>
        <strong>{{ specification.description }}:</strong> {{ specification.value }}
      </li>
    </ng-container>
  </ul>

  <hr />

  <h3 class="mb-24">Aplicações</h3>
  <pds-table>
    <table>
      <thead>
        <tr>
          <th>Marca</th>
          <th>Nome</th>
          <th>Modelo</th>
          <th>Motor</th>
          <th>Combustível</th>
          <th>Potência</th>
          <th>Restrições</th>
          <th>Ano</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let application of applications">
          <tr>
            <th colspan="8">{{ application.group.name }}</th>
          </tr>
          <ng-container *ngFor="let vehicle of application.vehicles">
            <tr>
              <td>{{ vehicle.brand }}</td>
              <td>{{ vehicle.name }}</td>
              <td>{{ vehicle.model }}</td>
              <td>{{ vehicle.engineName }} - {{ vehicle.engineConfiguration }}</td>
              <td>{{ vehicle.fuel }}</td>
              <td>{{ vehicle.horsePower }}</td>
              <td>{{ vehicle.restriction }}</td>
              <td>{{ vehicle.startYear }} / {{ vehicle.endYear }}</td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </pds-table>
</div>
