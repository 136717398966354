import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paymentStatus', standalone: true })
export class PaymentStatusPipe implements PipeTransform {
  transform(value: 'AUTHORIZED' | 'REFUSED' | 'PENDING') {
    switch (value) {
      case 'AUTHORIZED':
        return 'Autorizado';
      case 'REFUSED':
        return 'Recusado';
      case 'PENDING':
        return 'Pendente';
      default:
        return value;
    }
  }
}
