<pds-module-header title="Vendedores" ctaLabel="Voltar" ctaPath="/vendedores" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <div class="seller-details__content">
    <ng-container *ngIf="seller$ | async; let seller">
      <pds-panel>
        <ng-container *ngIf="seller.data">
          <pds-form [formGroup]="form">
            <pds-form-row grid="1-1-1">
              <pds-form-field label="Referência Fraga">
                <input type="text" formControlName="fragaReference" />
              </pds-form-field>

              <pds-form-field label="Nome">
                <input type="text" formControlName="name" [pdsFormImmutable]="true" />
              </pds-form-field>

              <pds-form-field label="E-mail">
                <input type="text" formControlName="email" />
              </pds-form-field>
            </pds-form-row>

            <div class="seller-datails__form__actions">
              <ng-container *ngIf="form.enabled; else editingTemplate">
                <pds-button modifier="outlined" (click)="handleCancelClickEvent()">Cancelar</pds-button>
                <pds-button (click)="handleSaveClickEvent()">Salvar</pds-button>
              </ng-container>

              <ng-template #editingTemplate>
                <pds-button (click)="handleEditClickEvent()">Editar</pds-button>
              </ng-template>
            </div>
          </pds-form>
        </ng-container>

        <ng-container *ngIf="seller.loading">
          <span>carregando...</span>
        </ng-container>

        <ng-container *ngIf="seller.failure">
          <strong>Não foi possível listar os vendedores. Por favor, tente novamente mais tarde.</strong>
        </ng-container>

        <hr />

        <pds-tabs [tabs]="tabs">
          <router-outlet />
        </pds-tabs>
      </pds-panel>
    </ng-container>
  </div>
</pds-module-header>
