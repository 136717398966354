<pds-module-header title="Transportadoras" ctaLabel="Nova Transportadora" ctaPath="/transportadoras/nova">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>
  <pds-panel>
    <peca-carrier-search (search)="handleSearchEvent($event)"></peca-carrier-search>

    <hr />

    <ng-container *ngIf="carriers$ | async; let carriers">
      <ng-container *ngIf="carriers.data?.length">
        <pds-table>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>Cadastro</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let carrier of carriers.data">
                <tr>
                  <td>{{ carrier.commercialName }}</td>
                  <td>{{ carrier.cnpj }}</td>
                  <td>{{ carrier.audit.createdAt | date : 'dd/MM/yyyy HH:mm' }}</td>
                  <td pdsColActions>
                    <pds-switch (ngModelChange)="handleStatusChanges(carrier, $event)" [(ngModel)]="carrier.active"></pds-switch>
                  </td>
                  <td pdsColActions>
                    <button pdsButton="icon" title="Ver Detalhes" (click)="handleDetailsClickEvent(carrier)"><span class="icon">visibility</span></button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </pds-table>

        <pds-pagination [pagination]="pagination$"></pds-pagination>
      </ng-container>

      <ng-container *ngIf="carriers.loading">
        <strong>carregando...</strong>
      </ng-container>

      <ng-container *ngIf="carriers.failure">
        <peca-retry context="transportadoras" (retry)="onRetry()"></peca-retry>
      </ng-container>

      <ng-container *ngIf="!!carriers.data && !carriers.data?.length">
        <strong>Nenhuma transportadora encontrada.</strong>
      </ng-container>
    </ng-container>
  </pds-panel>
</pds-module-header>
