<div class="order-details-payments" *ngIf="payments$ | async; let payments">
  <ng-container *ngIf="payments.loading">
    <strong>carregando...</strong>
  </ng-container>
  <ng-container *ngIf="payments.failure">
    <strong>Não foi possível listar os pagamentos do pedido.</strong>
  </ng-container>
  <ng-container *ngIf="payments.data && !payments.data.length">
    <strong>Nenhum pagamento encontrado para o pedido.</strong>
  </ng-container>

  <ng-container *ngIf="payments.data && payments.data.length">
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Método</th>
            <th>Status</th>
            <th>Parcelas</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of payments.data">
            <td>{{ payment.paymentMethod.name }}</td>
            <td>
              <span pdsChip>{{ payment.status | paymentStatus }}</span>
            </td>
            <td>{{ payment.installments || '1' }}</td>
            <td>{{ payment.amount | currency : 'BRL' }}</td>
          </tr>
        </tbody>
      </table>
    </pds-table>
  </ng-container>
</div>
