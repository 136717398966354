import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@peca/commons';
import { delay } from 'rxjs';
import { OrderStatusType } from '../models/order-status';

export class OrderChangeStatusForm {
  private _group: FormGroup;

  constructor() {
    this._group = new FormGroup({
      currentStatus: new FormControl(''),
      newStatus: new FormControl('', [Validators.required]),
      parentOrder: new FormControl(null),
    });

    this.controls.newStatus.valueChanges.pipe(delay(1)).subscribe((value) => {
      if (value !== 'MISPLACED') {
        this.controls.parentOrder.clearValidators();
      } else {
        this.controls.parentOrder.setValidators(this.requiredIfStatusEqualsValidator('MISPLACED'));
      }

      this.controls.parentOrder.updateValueAndValidity();
      this.group.updateValueAndValidity();
    });
  }

  get group() {
    return this._group;
  }

  get controls() {
    return {
      currentStatus: this.group.get('currentStatus') as FormControl,
      newStatus: this.group.get('newStatus') as FormControl,
      parentOrder: this.group.get('parentOrder') as FormControl,
    };
  }

  get value() {
    const value = {
      currentStatus: this.controls.currentStatus.getRawValue() as OrderStatusType,
      newStatus: this.controls.newStatus.getRawValue() as OrderStatusType,
      parentOrder: this.controls.parentOrder.value?.id || null,
    };

    return value;
  }

  requiredIfStatusEqualsValidator(status: OrderStatusType) {
    return (control: AbstractControl) => {
      if (this.value.newStatus === status && !control.value) {
        return { required: { message: 'Campo obrigatório' } };
      }

      return null;
    };
  }

  clear() {
    this.group.patchValue({
      currentStatus: '',
      newStatus: '',
      parentOrder: null,
    });
    this.group.updateValueAndValidity();
  }
}
