<pds-form [formGroup]="form">
  <pds-form-row grid="1-1-1-1">
    <pds-form-field label="Busca">
      <input type="text" formControlName="search" placeholder="Nome comercial do comprador ou PA" />
    </pds-form-field>
    <pds-form-field label="Data do pedido">
      <pds-range-form formControlName="date" />
    </pds-form-field>
    <pds-form-field label="Status da devolução">
      <pds-select [disableSearch]="true" [options]="statusOptions" formControlName="status" />
    </pds-form-field>
    <pds-form-field label="Status do pagamento">
      <pds-select [disableSearch]="true" [options]="paymentOptions" formControlName="payment" />
    </pds-form-field>
  </pds-form-row>
  <pds-form-actions>
    <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="form.pristine">Limpar filtros</button>
    <button pdsButton type="submit" (click)="onClickSearch()">Buscar</button>
  </pds-form-actions>
</pds-form>
