<ng-container *ngIf="deliveryTypes$ | async; let types">
  <ng-container *ngIf="types.loading">
    <span>carregando...</span>
  </ng-container>

  <ng-container *ngIf="types.failure">
    <span>Falha ao obter os tipos de entrega.</span>
  </ng-container>

  <ng-container *ngIf="types.data">
    <pds-form-section title="Tipos de entrega"></pds-form-section>

    <ng-container *ngIf="!types.data.length">
      <h4>Nenhum tipo de entrega cadastrado.</h4>
    </ng-container>

    <ng-container *ngFor="let type of types.data">
      <peca-carrier-delivery-type-details [deliveryType]="type"></peca-carrier-delivery-type-details>
    </ng-container>

    <hr />

    <pds-form-actions>
      <a pdsButton="default" [routerLink]="['/transportadoras', carrierId, 'tipo-de-entrega', 'novo']">Adicionar</a>
    </pds-form-actions>
  </ng-container>
</ng-container>
