import { BaseHttpClient } from '@peca/commons';
import { environment } from 'apps/backoffice/src/environments/environment';
import { BuyerNaturalPerson } from '../models/buyer-natural-person-request.model';
import { BuyerNaturalPersonResponse } from '../models/buyer-natural-person-response.model';
import { BuyerNaturalPersonQuery } from '../models/buyer-natural-person-query.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';

export class BuyerNaturalPersonService extends BaseHttpClient {
  save(payload: BuyerNaturalPerson) {
    return payload.id ? this.update(payload.id, payload) : this.create(payload);
  }

  read(id: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/b2c/${id}`;
    return this.http.get<BuyerNaturalPersonResponse>(resource);
  }

  readAll(query: BuyerNaturalPersonQuery) {
    const params = this.prepareQuery(query);
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/b2c`;
    return this.http.get<Pageable<BuyerNaturalPersonResponse>>(resource, { params });
  }

  private update(id: string, payload: unknown) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/b2c/${id}`;
    return this.http.patch(resource, payload);
  }

  private create(payload: unknown) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/b2c`;
    return this.http.post(resource, payload);
  }
}
