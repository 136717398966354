import { Route } from '@angular/router';
import { PurchaseOrderListComponent } from './components/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderDetailsComponent } from './components/purchase-order-details/purchase-order-details.component';
import { PurchaseorderDetailsProductsComponentComponent } from './components/purchase-order-details-products/purchase-order-details-products.component';
import { PurchaseorderDetailsPaymentsComponentComponent } from './components/purchase-order-details-payments/purchase-order-details-payments.component';
import { PurchaseorderDetailsLogsComponentComponent } from './components/purchase-order-details-logs/purchase-order-details-logs.component';

export const routes: Route[] = [
  {
    path: ':id',
    component: PurchaseOrderDetailsComponent,
    children: [
      {
        path: 'logs',
        component: PurchaseorderDetailsLogsComponentComponent,
      },
      {
        path: 'pagamentos',
        component: PurchaseorderDetailsPaymentsComponentComponent,
      },
      {
        path: 'produtos',
        component: PurchaseorderDetailsProductsComponentComponent,
      },
      {
        path: '**',
        redirectTo: 'produtos',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    component: PurchaseOrderListComponent,
  },
];

export const PurchaseOrdersModuleLoad = async () => {
  const { PurchaseOrdersModule } = await import('./purchase-orders.module');
  return PurchaseOrdersModule;
};
