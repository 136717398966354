<div class="buyer-representative-form__overlay" [class.--is-active]="open$ | async" (click)="onClickOverlay()"></div>
<div class="buyer-representative-form" *ngIf="farmers$ | async as farmers">
  <input #search type="text" placeholder="Busque por representantes Peça Aí" (focus)="onFocus()" (keydown.Tab)="onKeydownTab($event)" [disabled]="disabled" />

  <div role="listbox" class="buyer-representative-form__dropdown" [class.--is-active]="open$ | async">
    <ul class="buyer-representative-form__dropdown__list">
      <ng-container *ngIf="!farmers.loading && !farmers.failure && !farmers.data">
        <span class="buyer-representative-form__dropdown__message">Informe ao menos 3 caracteres para realizar a busca</span>
      </ng-container>

      <ng-container *ngIf="farmers.loading">
        <span class="buyer-representative-form__dropdown__message">carregando...</span>
      </ng-container>

      <ng-container *ngIf="farmers.failure">
        <span class="buyer-representative-form__dropdown__message">Falha ao obter representates.</span>
      </ng-container>

      <ng-container *ngIf="farmers.data">
        <ng-container *ngIf="!farmers.data.length">
          <span class="buyer-representative-form__dropdown__message">Nenhum representate encontrado.</span>
        </ng-container>

        <ng-container *ngFor="let farmer of farmers.data">
          <li role="listitem" class="buyer-representative-form__dropdown__item" (click)="onClickItem(farmer)">
            <span class="buyer-representative-form__dropdown__item__name">{{ farmer.name }}</span>
            <small class="buyer-representative-form__dropdown__item__email">{{ farmer.email }}</small>
          </li>
        </ng-container>
      </ng-container>
    </ul>

    <button type="button" role="listitem" class="buyer-representative-form__dropdown__button" (click)="onCreateNewUser()">Criar novo representante <span class="icon">add</span></button>
  </div>
</div>
