export const Messages = {
  ORDER: {
    PRODUCTS_ADJUSTMENTS_AT_PAC_VALIDATION: 'Não é possível realizar troca de produtos em pedidos complementares (PAC).',
    PRODUCTS_ADJUSTMENTS_VALIDATION: 'Não é possível realizar troca de produtos em pedidos com status "{}".',
    CANCELATION_VALIDATION: 'Não é possível cancelar um pedido com status "{}"',
    SELLER_ADJUSTMENTS_VALIDATION: 'Não é possível alterar vendedores de pedidos com status "{}".',
    INVOICE_ISSUANCE_VALIDATION: 'Somente pedidos com falha na emissão de nota fiscal podem ser faturados manualmente.',
    PAC_INVOICE_ISSUANCE_VALIDATION: 'Não é permitido realizar emissão de nota fiscal em pedidos complementares (PAC).',
    PARTIAL_DELIVERED_VALIDATION: 'Somente pedidos com status "Entregue" podem ser tratados como entregue parcialmente.',
    PAC_PARTIAL_DELIVERED_VALIDATION: 'Não é permitido tratar pedidos complementares (PAC) com entrega parcial.',
    RETURN_VALIDATION: 'Não é permitido realizar devoluções de pedidos não entregues.',
    INVOICE_ISSUANCE_PROCESSED_ORDER: 'Não é possível emitir notas fiscais para pedidos já processados.',
    CHANGE_PRICE_PROCESSED_ORDER: 'Somente pedidos não processados podem sofrer alteração de preços',
    CHANGE_PRICE_BANK_SLIP: 'Somente pedidos pagos em conta corrente podem sofrer alteração de preços',
  },
};
