<pds-form [formGroup]="form.instance">
  <pds-form-section title="Empresa">
    <pds-form-row grid="1-1-1">
      <pds-form-field label="Código Chave">
        <input type="text" formControlName="externalReference" placeholder="Código chave" />
      </pds-form-field>

      <pds-form-field label="Nome">
        <input type="text" formControlName="commercialName" placeholder="Nome comercial" />
      </pds-form-field>
    </pds-form-row>

    <pds-form-row grid="1-1-1">
      <pds-form-field label="CNPJ">
        <input type="text" formControlName="cnpj" placeholder="CNPJ" pdsMask="99.999.999/9999-99" />
      </pds-form-field>

      <pds-form-field label="Razão Social">
        <input type="text" formControlName="companyName" placeholder="Razão Social" />
      </pds-form-field>

      <pds-form-field label="Nome Fantasia">
        <input type="text" formControlName="tradingName" placeholder="Nome fantasia" />
      </pds-form-field>
    </pds-form-row>

    <pds-form-row grid="2-1">
      <pds-form-field label="Descrição">
        <input type="text" formControlName="description" placeholder="Descrição" />
      </pds-form-field>
    </pds-form-row>
  </pds-form-section>

  <pds-form-section title="Responsável">
    <peca-responsible-form formControlName="responsible" [requireds]="['EMAIL']" />
  </pds-form-section>

  <pds-form-section title="Endereço">
    <peca-address formControlName="address" />
  </pds-form-section>

  <pds-form-section title="Turno de Atendimento">
    <peca-business-hours [value]="form.value.businessHours" [disabled]="form.instance.disabled" (changes)="handleBusinessHoursChanges($event)"></peca-business-hours>
  </pds-form-section>

  <pds-form-section title="Configurações">
    <pds-form-row grid="1-1-1">
      <pds-form-field label="Vendedores">
        <pds-multiple-select placeholder="Todos" [source]="whiteList$" formControlName="sellersWhitelistIds"></pds-multiple-select>
      </pds-form-field>
      <pds-form-field label="Vendedores excluídos" [errors]="form.instance.errors">
        <pds-multiple-select placeholder="Nenhum" [source]="blackList$" formControlName="sellersBlacklistIds"></pds-multiple-select>
      </pds-form-field>
    </pds-form-row>
  </pds-form-section>
</pds-form>
