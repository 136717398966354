import { FormControl, FormGroup } from '@angular/forms';
import { FreightRequest } from '../models/freight-request.model';
import { FreightPointRequest } from '../models/freight-point-request.model';
import { Delivery } from '../models/delivery.model';

export class FreightRequestForm {
  group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      observation: new FormControl(''),
      deliveryTypeId: new FormControl(''),
      manualShipping: new FormControl(false),
      deliveryAtCD: new FormControl(false),
      origins: new FormControl([]),
      destinations: new FormControl([]),
      freightIds: new FormControl([]),
    });
  }

  get controls() {
    return {
      observation: this.group.get('observation') as FormControl,
      deliveryTypeId: this.group.get('deliveryTypeId') as FormControl,
      manualShipping: this.group.get('manualShipping') as FormControl,
      deliveryAtCD: this.group.get('deliveryAtCD') as FormControl,
      origins: this.group.get('origins') as FormControl,
      destinations: this.group.get('destinations') as FormControl,
      freightIds: this.group.get('freightIds') as FormControl,
    };
  }

  get value() {
    return {
      observation: this.controls.observation.getRawValue() as string,
      deliveryTypeId: this.controls.deliveryTypeId.getRawValue() as string,
      manualShipping: this.controls.manualShipping.getRawValue() as boolean,
      deliveryAtCD: this.controls.deliveryAtCD.getRawValue() as boolean,
      origins: this.controls.origins.getRawValue() as Array<FreightPointRequest>,
      destinations: this.controls.destinations.getRawValue() as Array<FreightPointRequest>,
      freightIds: this.controls.freightIds.getRawValue() as Array<number>,
    } as FreightRequest;
  }

  pushOrAggregateOrigin(delivery: Delivery) {
    const points = this.value.origins;
    const point = points.find((e) => e.addressId === delivery.origin.address.id);

    if (point) {
      point.commerceReferences.push(delivery.order.commerceReference);
      return;
    }

    points.push({
      addressId: delivery.origin.address.id,
      commerceReferences: [delivery.order.commerceReference],
      name: `${delivery.origin.branchTradingName} - ${delivery.origin.branchCommercialName}`,
      address: delivery.origin.address,
      carrier: delivery.carrier,
    });

    this.controls.origins.setValue(points);
  }

  pushOrAggregateDestination(delivery: Delivery) {
    const points = this.value.destinations;
    const point = points.find((e) => e.buyerId === delivery.buyer.id);

    if (point) {
      point.commerceReferences.push(delivery.order.commerceReference);
      return;
    }

    points.push({
      buyerId: delivery.buyer.id,
      addressId: delivery.shippingAddress.id,
      commerceReferences: [delivery.order.commerceReference],
      name: delivery.buyer.companyName,
      address: delivery.shippingAddress,
      time: delivery.deadlineHours,
      distance: delivery.distanceKm,
      carrier: delivery.carrier,
    });

    this.controls.destinations.setValue(points);
  }

  moveUpOrigin(index: number) {
    if (index === 0 || !this.value.origins) return;

    const reorganizedPoints = this.moveUpFreightPoint(index, this.value.origins);

    this.controls.origins.setValue(reorganizedPoints);
  }

  moveDownOrigin(index: number) {
    if (!this.value.origins || index === this.value.origins.length - 1) return;

    const reorganizedPoints = this.moveUpDownPoint(index, this.value.origins);

    this.controls.origins.setValue(reorganizedPoints);
  }

  moveUpDestination(index: number) {
    if (index === 0 || !this.value.destinations) return;

    const reorganizedPoints = this.moveUpFreightPoint(index, this.value.destinations);

    this.controls.destinations.setValue(reorganizedPoints);
  }

  moveDownDestination(index: number) {
    if (!this.value.destinations || index === this.value.destinations.length - 1) return;

    const reorganizedPoints = this.moveUpDownPoint(index, this.value.destinations);

    this.controls.destinations.setValue(reorganizedPoints);
  }

  clear() {
    this.group.setValue({
      observation: '',
      deliveryTypeId: '',
      manualShipping: false,
      deliveryAtCD: false,
      origins: [],
      destinations: [],
      freightIds: [],
    });
  }

  pushFreightId(id: number) {
    const value = this.value.freightIds;
    value.push(id);
    this.controls.freightIds.setValue(value);
  }

  private moveUpFreightPoint(index: number, points: Array<FreightPointRequest>) {
    const point = points[index];

    points[index] = points[index - 1];
    points[index - 1] = point;

    return points;
  }

  private moveUpDownPoint(index: number, points: Array<FreightPointRequest>) {
    const point = points[index];

    points[index] = points[index + 1];
    points[index + 1] = point;

    return points;
  }
}
