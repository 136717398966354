import { Routes } from '@angular/router';

import { CarriersListComponent } from './components/carriers-list/carriers-list.component';
import { CarrierCreateComponent } from './components/carrier-create/carrier-create.component';
import { CarrierUpdateComponent } from './components/carrier-update/carrier-update.component';
import { CarrierDeliveryTypeCreateComponent } from './components/carrier-delivery-type-create/carrier-delivery-type-create.component';
import { CarrierDeliveryTypeUpdateComponent } from './components/carrier-delivery-type-update/carrier-delivery-type-update.component';
import { DeliveryTypeRegionsComponent } from './components/delivery-type-regions/delivery-type-regions.component';

export const routes: Routes = [
  {
    path: 'nova',
    component: CarrierCreateComponent,
  },
  {
    path: ':carrierId/tipo-de-entrega/:id/regioes-de-atuacao',
    component: DeliveryTypeRegionsComponent,
  },
  {
    path: ':carrierId/tipo-de-entrega/novo',
    component: CarrierDeliveryTypeCreateComponent,
  },
  {
    path: ':carrierId/tipo-de-entrega/:id',
    component: CarrierDeliveryTypeUpdateComponent,
  },
  {
    path: ':carrierId',
    component: CarrierUpdateComponent,
  },
  {
    path: '',
    component: CarriersListComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const CarriersModuleLoad = () =>
  import('./carriers.module').then(({ CarriersModule }) => CarriersModule);
