import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BusinessHours, Validators } from '@peca/commons';
import { Responsible } from '@peca/backoffice/components/responsible-form/responsible.model';

import { Branch } from '../../models/branch.model';
import { Address } from '../../models/address.model';
import { Seller } from '../../models/seller.model';

export class SellerForm {
  private form: FormGroup;

  private constructor(form: FormGroup) {
    this.form = form;
  }

  static getInstance() {
    const form = new FormGroup({
      seller: new FormGroup({
        id: new FormControl(''),
        name: new FormControl('', [Validators.required]),
        fragaReference: new FormControl('', [Validators.required, Validators.length.exactly(36)]),
        email: new FormControl('', [Validators.required, Validators.email]),
      }),
      id: new FormControl(''),
      externalReference: new FormControl('', [Validators.required]),
      commercialName: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      cnpj: new FormControl('', [Validators.required, Validators.cnpj]),
      companyName: new FormControl('', [Validators.required]),
      tradingName: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      active: new FormControl(true),
      billingType: new FormControl('', [Validators.required, Validators.oneOf(['DAILY', 'WEEKLY', 'BIWEEKLY'])]),
      billingMethodId: new FormControl('', [Validators.required, Validators.oneOf(['1', '3'])]),
      paymentDeadlineInDays: new FormControl('', [Validators.required, Validators.number.min(1), Validators.number.max(365)]),
      pickingTime: new FormControl('0', [Validators.required, Validators.number.min(0), Validators.number.max(99)]),
      businessUnit: new FormControl('', [Validators.required]),
      minimumOrderValue: new FormControl('0', [Validators.required, Validators.number.min(0, true), Validators.number.max(999, true)]),
      convenienceFee: new FormControl('0', [Validators.required, Validators.number.min(0, true), Validators.number.max(99, true)]),
      businessHours: new FormArray([]),
      headOffice: new FormControl(''),
      salesModel: new FormControl('1P'),
      responsible: new FormControl(null, [Validators.required]),
      activityAreaId: new FormControl('', [Validators.required]),
    });

    return new SellerForm(form);
  }

  get formGroup() {
    return this.form;
  }

  get invalid() {
    return this.form.invalid;
  }

  get value() {
    return {
      id: this.form.get('id')?.getRawValue(),
      sellerId: this.form.get('seller.id')?.getRawValue(),
      externalReference: this.form.get('externalReference')?.getRawValue(),
      commercialName: this.form.get('commercialName')?.getRawValue(),
      description: this.form.get('description')?.getRawValue(),
      email: this.form.get('email')?.getRawValue(),
      cnpj: this.form.get('cnpj')?.getRawValue(),
      companyName: this.form.get('companyName')?.getRawValue(),
      tradingName: this.form.get('tradingName')?.getRawValue(),
      billingType: this.form.get('billingType')?.getRawValue(),
      billingMethod: { id: this.form.get('billingMethodId')?.getRawValue() },
      paymentDeadlineInDays: this.form.get('paymentDeadlineInDays')?.getRawValue(),
      address: this.form.get('address')?.getRawValue(),
      active: this.form.get('active')?.getRawValue(),
      pickingTime: this.form.get('pickingTime')?.getRawValue(),
      businessUnit: this.form.get('businessUnit')?.getRawValue(),
      minimumOrderValue: this.form.get('minimumOrderValue')?.getRawValue(),
      convenienceFee: this.form.get('convenienceFee')?.getRawValue(),
      businessHours: this.form.get('businessHours')?.getRawValue(),
      headOffice: this.form.get('headOffice')?.getRawValue(),
      salesModel: this.form.get('salesModel')?.getRawValue(),
      responsible: this.form.get('responsible')?.getRawValue(),
      seller: this.form.get('seller')?.getRawValue(),
      activityAreaId: this.form.get('activityAreaId')?.getRawValue(),
    };
  }

  set value(branch: Branch) {
    this.id = branch.id;
    this.externalReference = branch.externalReference;
    this.commercialName = branch.commercialName;
    this.description = branch.description;
    this.email = branch.email;
    this.cnpj = branch.cnpj;
    this.companyName = branch.companyName;
    this.tradingName = branch.tradingName;
    this.active = branch.active;
    this.pickingTime = branch.pickingTime;
    this.businessUnit = branch.businessUnit;
    this.minimumOrderValue = branch.minimumOrderValue;
    this.convenienceFee = branch.convenienceFee;
    this.headOffice = branch.headOffice;
    this.salesModel = branch.salesModel;
    this.address = branch.address;
    this.seller = branch.seller;
    this.responsible = branch.responsible;
    this.businessHours = branch.businessHours;
    this.billingType = branch.billingType || '';
    this.billingMethodId = branch.billingMethod?.id.toString() || '';
    this.paymentDeadlineInDays = branch.paymentDeadlineInDays || '';
    this.activityAreaId = branch.activityAreaId || '';
  }

  update() {
    this.form.updateValueAndValidity();
  }

  private set id(id: number) {
    this.form.get('id')?.setValue(id);
  }
  private set externalReference(externalReference: string) {
    this.form.get('externalReference')?.setValue(externalReference);
  }
  private set commercialName(commercialName: string) {
    this.form.get('commercialName')?.setValue(commercialName);
  }
  private set description(description: string) {
    this.form.get('description')?.setValue(description);
  }
  private set email(email: string) {
    this.form.get('email')?.setValue(email);
  }
  private set cnpj(cnpj: string) {
    this.form.get('cnpj')?.setValue(cnpj);
  }
  private set companyName(companyName: string) {
    this.form.get('companyName')?.setValue(companyName);
  }
  private set tradingName(tradingName: string) {
    this.form.get('tradingName')?.setValue(tradingName);
  }
  private set active(active: boolean) {
    this.form.get('active')?.setValue(active);
  }
  private set pickingTime(pickingTime: number) {
    this.form.get('pickingTime')?.setValue(pickingTime);
  }
  private set businessUnit(businessUnit: string) {
    this.form.get('businessUnit')?.setValue(businessUnit);
  }
  private set minimumOrderValue(minimumOrderValue: number) {
    this.form.get('minimumOrderValue')?.setValue(minimumOrderValue);
  }
  private set convenienceFee(convenienceFee: number) {
    this.form.get('convenienceFee')?.setValue(convenienceFee);
  }
  private set headOffice(headOffice: boolean) {
    this.form.get('headOffice')?.setValue(headOffice);
  }
  private set salesModel(salesModel: string) {
    this.form.get('salesModel')?.setValue(salesModel);
  }
  private set address(address: Address) {
    this.form.get('address')?.setValue(address);
  }
  set seller(seller: Seller) {
    this.form.get('seller.name')?.setValue(seller.name);
    this.form.get('seller.fragaReference')?.setValue(seller.fragaReference);
    this.form.get('seller.email')?.setValue(seller.email);
    this.form.get('seller')?.disable();
  }

  private set responsible(responsible: Responsible) {
    this.form.get('responsible')?.setValue(responsible);
  }

  set billingType(billingType: string) {
    this.form.get('billingType')?.setValue(billingType);
  }
  set billingMethodId(billingMethodId: string) {
    this.form.get('billingMethodId')?.setValue(billingMethodId);
  }
  set paymentDeadlineInDays(paymentDeadlineInDays: string) {
    this.form.get('paymentDeadlineInDays')?.setValue(paymentDeadlineInDays);
  }
  set activityAreaId(activityAreaId: string) {
    this.form.get('activityAreaId')?.setValue(activityAreaId);
  }

  set businessHours(hours: BusinessHours[]) {
    const businessHours = this.form.get('businessHours') as FormArray;
    businessHours.controls = [];
    hours.forEach((control) =>
      businessHours.push(
        new FormGroup({
          dayOfWeek: new FormControl(control.dayOfWeek),
          openHour: new FormControl(control.openHour),
          closeHour: new FormControl(control.closeHour),
        })
      )
    );
    this.form.updateValueAndValidity();
  }

  enable() {
    this.form.enable();
    this.form.get('seller')?.disable();
  }
}
