<pds-modal #modal title="Anexar arquivos" [width]="420" (dismiss)="onEventDismis()">
  <div class="file-upload">
    @if(!file) {
    <div class="file-upload-thumbnail" (drop)="onEventDrop($event)" (dragover)="onEventDragOver($event)" (click)="fileInput.click()">
      <input #fileInput type="file" (change)="onEventFileChange($event)" />
      <span class="icon">cloud_upload</span>
      <span class="file-upload-thumbnail__label">Clique para selecionar o arquivo</span>
    </div>
    } @else {
    <div class="file-upload-preview">
      <div class="file-upload-preview__thumbnail">
        @if(previewImage) {
        <img *ngIf="previewImage" [src]="previewImage" [alt]="fileName" />
        } @else {
        <div class="file-upload-preview__thumbnail__default">
          <span class="icon">description</span>
        </div>
        }
      </div>
      <div class="file-upload-preview__details">
        <div class="file-upload-preview__details__name">{{ fileName }}</div>
        <div class="file-upload-preview__details__size">{{ fileSize }}</div>
      </div>
      <div class="file-upload-preview__action">
        <button pdsButton="icon" title="Remover" (click)="onClickRemove()">
          <span class="icon">delete</span>
        </button>
      </div>
    </div>
    } @if (file) {
    <hr />
    <div class="file-upload-action">
      <button pdsButton (click)="onClickAttach()">Anexar</button>
    </div>
    }
  </div>
</pds-modal>
