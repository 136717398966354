import { Pipe, PipeTransform } from '@angular/core';
import { BillStatus } from '../models/bill_status.model';

@Pipe({ name: 'billStatusColor', standalone: true })
export class BillStatusColor implements PipeTransform {
  transform(value: BillStatus) {
    switch (value) {
      case 'OPENED':
        return 'primary';
      case 'CLOSED':
        return '';
      default:
        return value;
    }
  }
}
