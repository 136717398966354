import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'businessUnit',
})
export class BusinessUnitPipe implements PipeTransform {
  transform(value: string) {
    switch (value) {
      case '204767519':
        return 'Minas Gerais';
      case '204180583':
        return 'São Paulo';
      default:
        return value;
    }
  }
}
