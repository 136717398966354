import { BehaviorSubject } from 'rxjs';
import { AfterContentInit, Component, inject } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { forwardRefProvider } from '@peca/backoffice/providers/forward-ref.provider';
import { AsyncState } from '@peca/backoffice/models/async-state.model';

import { Role } from '../../models/role.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'peca-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss'],
  providers: [forwardRefProvider(UserRoleFormComponent)],
})
export class UserRoleFormComponent implements AfterContentInit, ControlValueAccessor {
  value: string[];
  disabled: boolean;
  changed!: (value: string[]) => void;
  touched!: () => void;
  service: UserService;
  roles$: BehaviorSubject<AsyncState<any[]>>;

  constructor() {
    this.service = inject(UserService);
    this.disabled = false;
    this.value = [];
    this.roles$ = new BehaviorSubject<AsyncState<any[]>>({ loading: true });
    this.disabled = false;
  }

  writeValue(value: string[]) {
    this.value = value;
  }

  registerOnChange(fn: (value: string[]) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngAfterContentInit() {
    this.fetchRoles();
  }

  fetchRoles() {
    this.roles$.next({ loading: true });
    this.service.fetchRoles(this.value).subscribe({
      next: (data: any) => this.roles$.next({ data }),
      error: () => this.roles$.next({ failure: true }),
    });
  }

  onRetry() {
    this.fetchRoles();
  }

  onChangeRole(role: Role) {
    if (this.value.includes(role.id)) {
      this.value = this.value.filter((id) => id !== role.id);
    } else {
      this.value.push(role.id);
    }

    this.changed(this.value);
  }

  onClickExpandGroup(group: any) {
    group.expanded = !group.expanded;
  }

  onClickExpandModule(module: any) {
    module.expanded = !module.expanded;
  }

  onClickNoRoles(module: any) {
    module.roles.forEach((role: any) => {
      role.selected = false;
      this.value = this.value.filter((id) => id !== role.id);
    });

    this.changed(this.value);
  }

  onClickAllRoles(module: any) {
    module.roles.forEach((role: any) => {
      if (this.value.includes(role.id)) return;
      role.selected = true;
      this.value.push(role.id);
    });

    this.changed(this.value);
  }
}
