<div class="product-card">
  <ng-container *ngIf="!loading && !failure && !products">
    <h2 class="product-card__title">Faça uma busca para visualizar produtos.</h2>
    <p class="product-card__disclaimer">É necessário informar ao menos CEP e placa do veículo.</p>
  </ng-container>

  <ng-container *ngIf="loading">
    <h2>carregando...</h2>
  </ng-container>

  <ng-container *ngIf="failure">
    <h2>Não foi possível listar os produtos. Por favor, tente novamente mais tarde.</h2>
  </ng-container>

  <ng-container *ngIf="products && products.totalItems === 0">
    <h2 class="product-card__title">Nenhum produto encontrado.</h2>
    <p class="product-card__disclaimer">Verifique os filtros utilizados e tente novamente.</p>
  </ng-container>

  <ng-container *ngIf="products && products.items.length">
    <h2 class="product-card__title">Resultado</h2>
    <p class="product-card__disclaimer">{{ products.totalItems | number }} produto(s) encontrado(s)</p>

    <div class="product-card__grid">
      <ng-container *ngFor="let product of products.items">
        <div class="product-card__item" role="link" (click)="handleItemClick(product.productId)">
          <div class="product-card__item__thumb">
            <ng-container *ngIf="product.img?.thumbnailUrl">
              <img [src]="product.img.thumbnailUrl" [alt]="product.name" />
            </ng-container>
          </div>
          <h2 class="product-card__item__name">{{ product.name }}</h2>
          <ul class="product-card__item__specs">
            <ng-container *ngFor="let spec of product.specs">
              <li>{{ spec.name }}: {{ spec.value }}</li>
            </ng-container>
          </ul>
          <div class="product-card__item__price">
            <span class="label">a partir de</span>
            <span class="value">{{ product.unitPrice | currency : 'BRL' }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
