import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderQuery } from '../../models/order-query.model';
import { AutocompleteItemSource } from '@peca/design-system';
import { BehaviorSubject } from 'rxjs';
import { OrderService } from '../../services/order.service';
import { LogTypeStatusPipe } from '@peca/backoffice/pipes/logs/order-log-type-status.pipe';
import { StatusValues } from '../../values/status.value';
import { ActivatedRoute } from '@angular/router';
import { PersistQueryService } from '@peca/backoffice/services/persist-query.service';

@Component({
  selector: 'peca-order-search',
  templateUrl: './order-search.component.html',
  styleUrl: './order-search.component.scss',
})
export class OrderSearchComponent implements OnInit {
  @Output()
  search: EventEmitter<OrderQuery>;
  form: FormGroup;
  buyers$: BehaviorSubject<AutocompleteItemSource>;
  service: OrderService;
  statusSource: any[];
  route: ActivatedRoute;
  persistQueryService: PersistQueryService;
  salesChannels$: BehaviorSubject<AutocompleteItemSource>;
  private readonly searchFormInitialValue = {
    status: [],
    date: { startDate: '', endDate: '' },
    search: '',
    buyer: '',
    salesChannelId: '',
  };

  constructor() {
    this.service = inject(OrderService);
    this.route = inject(ActivatedRoute);
    this.persistQueryService = inject(PersistQueryService);
    this.buyers$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.search = new EventEmitter<OrderQuery>();
    this.form = new FormGroup({
      status: new FormControl([]),
      date: new FormControl({ startDate: '', endDate: '' }),
      search: new FormControl(''),
      buyer: new FormControl(''),
      salesChannelId: new FormControl(''),
    });
    this.statusSource = this.getStatusSource();
    this.salesChannels$ = new BehaviorSubject<AutocompleteItemSource>({});
  }

  get value() {
    return {
      search: (this.form.get('search') as FormControl).value,
      status: (this.form.get('status') as FormControl).value,
      startDate: (this.form.get('date') as FormControl).value?.startDate,
      endDate: (this.form.get('date') as FormControl).value?.endDate,
      buyerId: (this.form.get('buyer') as FormControl).value?.id,
      salesChannelId: (this.form.get('salesChannelId') as FormControl).value?.id,
      buyer: (this.form.get('buyer') as FormControl).value,
      salesChannel: (this.form.get('salesChannelId') as FormControl).value,
    } as OrderQuery;
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, status, startDate, endDate, buyer, salesChannel } = JSON.parse(atob(params['q']));
      this.form.patchValue({ buyer: buyer, salesChannelId: salesChannel, search, status, date: { startDate, endDate } });
    });
  }

  onSubmit() {
    if (this.form.invalid) return;
    this.search.next(this.value);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.onSubmit();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) this.form.markAsPristine();
  }

  getStatusSource() {
    const status = [...StatusValues];
    const pipe = new LogTypeStatusPipe();
    return status.map((i) => ({ id: i, label: pipe.transform(i) as string }));
  }

  onAutocompleteBuyers(search: string) {
    if (search.length < 3) return;

    this.buyers$.next({ loading: true });
    this.service.suggestBuyers(search).subscribe({
      next: (data) => this.buyers$.next({ data }),
      error: () => this.buyers$.next({ failure: true }),
    });
  }

  onAutocompleteSalesChannel(search: string) {
    if (search.length < 3) return;

    this.salesChannels$.next({ loading: true });
    this.service.suggestSalesChannels(search).subscribe({
      next: (data) => this.salesChannels$.next({ data }),
      error: () => this.salesChannels$.next({ failure: true }),
    });
  }
}
