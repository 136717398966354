import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { CarrierQuery } from '../../models/carrier-query.model';

@Component({
  selector: 'peca-carrier-search',
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss'],
})
export class CarrierSearchComponent {
  @Output()
  search: EventEmitter<CarrierQuery>;

  form: FormGroup;
  private readonly searchFormInitialValue = { search: '' };

  constructor() {
    this.search = new EventEmitter<CarrierQuery>();
    this.form = new FormGroup({ search: new FormControl('') });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.checkIfSearchIsPrestine());
  }

  handleSearchClickEvent() {
    this.search.next(this.form.value);
  }

  clearFilters() {
    this.form.reset(this.searchFormInitialValue);
    this.handleSearchClickEvent();
  }

  checkIfSearchIsPrestine() {
    const currentValue = this.form.value;
    const isFormEmpty = JSON.stringify(currentValue) === JSON.stringify(this.searchFormInitialValue);
    if (isFormEmpty) this.form.markAsPristine();
  }
}
