import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DesignSystemModule } from '@peca/design-system';
import { AfterViewInit, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ControlValueAccessor, FormsModule } from '@angular/forms';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { forwardRefProvider } from '@peca/backoffice/providers/forward-ref.provider';
import { PaymentMethod } from '@peca/backoffice/components/payment-method-form/payment-method.model';

import { environment } from 'apps/backoffice/src/environments/environment';

@Component({
  selector: 'peca-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, DesignSystemModule],
  providers: [forwardRefProvider(PaymentMethodFormComponent)],
})
export class PaymentMethodFormComponent implements AfterViewInit, ControlValueAccessor {
  @Output()
  financingSet: EventEmitter<boolean>;
  @Input()
  multipleFinancing: boolean;
  methods$: BehaviorSubject<AsyncState<PaymentMethod[]>>;
  http: HttpClient;
  value: number[];
  methods: AsyncState<PaymentMethod[]>;
  disabled: boolean;
  financingCodes: number[];
  changed!: (value: number[]) => void;
  touched!: () => void;

  constructor() {
    this.financingSet = new EventEmitter<boolean>();
    this.multipleFinancing = false;
    this.financingCodes = [];
    this.methods$ = new BehaviorSubject<AsyncState<PaymentMethod[]>>({ loading: true });
    this.http = inject(HttpClient);
    this.value = [];
    this.methods = { loading: true };
    this.disabled = false;
  }

  ngAfterViewInit() {
    this.fetchPaymentMethods().subscribe({
      next: this.setValue.bind(this),
      error: () => this.methods$.next({ failure: true }),
    });
  }

  onChangeParent(method: PaymentMethod) {
    if (method.children?.length) {
      method.children.forEach((child) => {
        child.disabled = false;
        if (child.selected) {
          child.selected = false;
          this.onChangeValue(child);
        }
      });
      return;
    }

    this.onChangeValue(method);
  }

  onChangeChild(parent: PaymentMethod, child: PaymentMethod) {
    parent.children?.forEach((item) => (item.disabled = child.selected && item.id !== child.id && !this.multipleFinancing));
    this.onChangeValue(child);
  }

  writeValue(value: number[]) {
    this.value = value || [];

    if (this.methods$.value.data) {
      this.setValue(this.methods$.value.data);
      this.financingSet.next(this.value.some((id) => this.financingCodes.includes(id)));
    }
  }

  registerOnChange(fn: (value: number[]) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private onChangeValue(method: PaymentMethod) {
    const value = new Set<number>(this.value);

    if (method.children) return;

    if (method.selected) value.add(method.id);
    else value.delete(method.id);

    this.value = [...value];
    this.changed(this.value);
    this.financingSet.next(this.value.some((id) => this.financingCodes.includes(id)));
  }

  private fetchPaymentMethods() {
    const resource = `${environment.gateway.url}/backoffice/v1/payment-methods`;
    return this.http.get<PaymentMethod[]>(resource);
  }

  private setValue(methods: PaymentMethod[]) {
    const data = methods
      .sort((x, y) => (x.name > y.name ? 1 : -1))
      .map((item) => ({
        ...item,
        selected: this.value.includes(item.id) || item.children?.some((child) => this.value.includes(child.id)) || false,
        children: item.children
          ?.sort((x, y) => (x.name > y.name ? 1 : -1))
          .map((child) => ({
            ...child,
            selected: this.value.includes(child.id),
          }))
          .map((item, i, items) => ({
            ...item,
            disabled: items.some((i) => i.selected) && !item.selected && !this.multipleFinancing,
          })),
      }));

    data.forEach((method) => {
      if (method.referenceName.startsWith('bank-slip') && method.children?.length) {
        method.children.forEach((child) => this.financingCodes.push(child.id));
      }
    });

    this.methods$.next({ data });
    this.financingSet.next(this.value.some((id) => this.financingCodes.includes(id)));
  }
}
