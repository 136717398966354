import { Component, inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DeliveryTypeService } from '../../services/delivery-type.service';

@Component({
  selector: 'peca-carrier-delivery-type-update',
  templateUrl: './carrier-delivery-type-update.component.html',
  styleUrls: ['./carrier-delivery-type-update.component.scss'],
})
export class CarrierDeliveryTypeUpdateComponent {
  private readonly deliveryService: DeliveryTypeService;
  private readonly route: ActivatedRoute;

  constructor() {
    this.deliveryService = inject(DeliveryTypeService);
    this.route = inject(ActivatedRoute);
  }

  get carrierId() {
    return this.route.snapshot.params['carrierId'] as string;
  }

  get deliveryType$() {
    const { carrierId, id } = this.route.snapshot.params;
    return this.deliveryService.details(carrierId, id).pipe(
      catchError((e) => {
        return throwError(() => new Error(e));
      })
    );
  }
}
