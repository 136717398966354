<pds-modal #modal title="Substituir Vendedor" [width]="1024" (dismiss)="close()">
  <pds-form>
    <pds-form-section *ngIf="currentProduct" title="Produto Atual">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th rowspan="2" style="width: 24%">Partnumber</th>
              <th rowspan="2">Marca</th>
              <th colspan="3">Vendedor</th>
              <th rowspan="2">Qtd.</th>
              <th rowspan="2">Preço Unt.</th>
              <th rowspan="2">Preço Total</th>
            </tr>
            <tr>
              <th>Nome</th>
              <th>Filial</th>
              <th>Und. de negócio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="product-substitute-description">
                  <strong>{{ currentProduct.partNumber }}</strong>
                  <small>{{ currentProduct.description }}</small>
                </div>
              </td>
              <td>{{ currentProduct.brand }}</td>
              <td>{{ currentProduct.branch.commercialName }}</td>
              <td>{{ currentProduct.branch.externalReference }}</td>
              <td>{{ currentProduct.branch.businessUnit }}</td>
              <td>{{ currentProduct.quantity }}</td>
              <td>{{ currentProduct.unitPrice | currency : 'BRL' }}</td>
              <td>{{ currentProduct.totalPrice | currency : 'BRL' }}</td>
            </tr>
          </tbody>
        </table>
      </pds-table>
    </pds-form-section>

    <pds-form-section *ngIf="products$ | async; let products" title="Substituições possíveis" [hidden]="substituteProduct">
      <ng-container *ngIf="products.loading"><strong>carregando...</strong></ng-container>
      <ng-container *ngIf="products.failure"><strong>Não foi possível carregar os produtos similares.</strong></ng-container>
      <ng-container *ngIf="products.data && !products.data.length"><strong>Nenhum produto similar encontrado.</strong></ng-container>
      <ng-container *ngIf="products.data && products.data.length">
        <p class="product-substitute-label">A linhas na cor <span></span> destacam vendedores que já estão no pedido. Priorize-os para otimizar o frete.</p>

        <pds-form-row>
          <pds-checkbox label="Exibir somentes produtos do mesmo vendedor." [(ngModel)]="onlySameSeller" />
        </pds-form-row>
        <pds-table [height]="256">
          <table>
            <thead>
              <tr>
                <th rowspan="2" style="width: 24%">Partnumber</th>
                <th rowspan="2">Marca</th>
                <th colspan="3">Vendedor</th>
                <th rowspan="2">Estoque</th>
                <th rowspan="2">Preço</th>
                <th rowspan="2"></th>
              </tr>
              <tr>
                <th>Nome</th>
                <th>Filial</th>
                <th>Und. de negócio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of products.data" [class.--already-on-order]="product.alreadyOnOrder" [hidden]="onlySameSeller && product.branch.id !== currentProduct?.branch?.id">
                <td>
                  <div class="product-substitute-description">
                    <strong>{{ product.partNumber }}</strong>
                    <small>{{ product.description }}</small>
                  </div>
                </td>
                <td>{{ product.brand.name }}</td>
                <td>{{ product.branch.commercialName }}</td>
                <td>{{ product.branch.externalReference }}</td>
                <td>{{ product.branch.businessUnit }}</td>
                <td>{{ product.stock }}</td>
                <td>{{ product.salePrice | currency : 'BRL' }}</td>
                <td pdsColActions>
                  <button pdsButton="icon" title="Selecionar substituto" (click)="onClickSelectProduct(product)">
                    <span class="icon">sync</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </pds-table>
      </ng-container>
    </pds-form-section>

    <pds-form-section *ngIf="substituteProduct" title="Produto Selecionado" [formGroup]="form.group">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th rowspan="2" style="width: 24%">Partnumber</th>
              <th rowspan="2">Marca</th>
              <th colspan="3">Vendedor</th>
              <th rowspan="2">Estoque</th>
              <th rowspan="2">Preço Unt.</th>
              <th rowspan="2"></th>
            </tr>
            <tr>
              <th>Nome</th>
              <th>Filial</th>
              <th>Und. de negócio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="product-substitute-description">
                  <strong>{{ substituteProduct.partNumber }}</strong>
                  <small>{{ substituteProduct.description }}</small>
                </div>
              </td>
              <td>{{ substituteProduct.brand.name }}</td>
              <td>{{ substituteProduct.branch.commercialName }}</td>
              <td>{{ substituteProduct.branch.externalReference }}</td>
              <td>{{ substituteProduct.branch.businessUnit }}</td>
              <td>{{ substituteProduct.stock }}</td>
              <td>{{ substituteProduct.salePrice | currency : 'BRL' }}</td>
              <td pdsColActions>
                <button pdsButton="icon" title="Selecionar substituto" (click)="onClickRemoveSubstituteProduct()">
                  <span class="icon">remove</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </pds-table>

      <pds-form-row grid="1-1-1-1">
        <pds-form-field label="Quantidade">
          <input type="number" formControlName="quantity" />
        </pds-form-field>
        <pds-form-field label="Preço unitário">
          <input type="text" [value]="form.value.unitPrice.formmated" disabled />
        </pds-form-field>
        <pds-form-field label="Preço total">
          <input type="text" [value]="form.value.totalPrice.formmated" disabled />
        </pds-form-field>
        <pds-form-field label="Diferença de preço">
          <input type="text" [value]="form.value.diffPrice.formmated" disabled />
        </pds-form-field>
      </pds-form-row>

      <pds-form-row>
        <pds-form-field label="Justificativa">
          <input type="text" formControlName="reason" />
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-actions *ngIf="substituteProduct">
      <pds-button data-id="btnReplaceProductSubmit" [disabled]="form.group.invalid" (click)="onClickSubmit()">salvar</pds-button>
    </pds-form-actions>
  </pds-form>
</pds-modal>
