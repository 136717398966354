import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'peca-carrier-delivery-type-create',
  templateUrl: './carrier-delivery-type-create.component.html',
  styleUrls: ['./carrier-delivery-type-create.component.scss'],
})
export class CarrierDeliveryTypeCreateComponent {
  route = inject(ActivatedRoute);

  get carrierId() {
    return this.route.snapshot.params['carrierId'] as string;
  }
}
