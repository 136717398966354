import { Injectable } from '@angular/core';
import { LogTypeStatusPipe } from '@peca/backoffice/pipes/logs/order-log-type-status.pipe';
import { Messages } from '@peca/backoffice/utils/messages.utils';
import { StringUtils } from '@peca/backoffice/utils/string-format.utils';
import { OrderStatusType } from '../models/order-status';
import { Order } from '../models/order.model';

@Injectable({ providedIn: 'root' })
export class OrderOperationsValidadorService {
  private statusPipe: LogTypeStatusPipe;

  constructor() {
    this.statusPipe = new LogTypeStatusPipe();
  }

  validateChangePrices(order: Order) {
    if (!order.payments?.some(({ paymentMethod }) => paymentMethod.referenceName.startsWith('bank'))) {
      return { message: Messages.ORDER.CHANGE_PRICE_BANK_SLIP };
    }

    if (order.processedDate) {
      return { message: Messages.ORDER.CHANGE_PRICE_PROCESSED_ORDER };
    }

    return null;
  }

  validateOrderReturn(order: Order) {
    const allowedStatus = ['DELIVERED', 'PARTIAL_RETURN', 'MISPLACED', 'PROCESSING_RETURN'];

    if (!allowedStatus.includes(order.internalStatus)) {
      return { message: Messages.ORDER.RETURN_VALIDATION };
    }

    return null;
  }

  validateInvoiceIssuance(order: Order) {
    if (order.processedDate) {
      return { message: Messages.ORDER.INVOICE_ISSUANCE_PROCESSED_ORDER };
    }

    if (order.invoice && order.invoice.status === 'FAILED') return null;

    return { message: Messages.ORDER.INVOICE_ISSUANCE_VALIDATION };
  }

  validateSellerAdjustments(order: Order) {
    const allowedStatus: OrderStatusType[] = [
      'REQUIRES_MANUAL_ADJUSTMENT',
      'WAITING_FOR_SELLER',
      'IN_SEPARATION',
      'AWAITING_COLLECTION',
      'ON_ROUTE',
      'DELIVERED',
    ];

    if (allowedStatus.includes(order.internalStatus)) {
      return null;
    }

    return { message: StringUtils.format(Messages.ORDER.SELLER_ADJUSTMENTS_VALIDATION, this.statusPipe.transform(order.internalStatus) as string) };
  }

  validateOrderCancelation(order: Order) {
    const allowed: OrderStatusType[] = ['PAYMENT_ACCEPT', 'WAITING_FOR_SELLER', 'REQUIRES_MANUAL_ADJUSTMENT', 'IN_SEPARATION', 'AWAITING_COLLECTION'];

    if (allowed.includes(order.internalStatus)) return null;

    return { message: StringUtils.format(Messages.ORDER.CANCELATION_VALIDATION, this.statusPipe.transform(order.internalStatus) as string) };
  }

  getPossibleStatus(status: OrderStatusType) {
    switch (status) {
      case 'PROCESSING_PAYMENT':
        return [];
      case 'PAYMENT_ACCEPT':
        return [];
      case 'PAYMENT_REFUSED':
        return [];
      case 'REQUIRES_MANUAL_ADJUSTMENT':
        return [];
      case 'WAITING_FOR_SELLER':
        return ['IN_SEPARATION'];
      case 'IN_SEPARATION':
        return ['AWAITING_COLLECTION'];
      case 'AWAITING_COLLECTION':
        return ['ON_ROUTE'];
      case 'ON_ROUTE':
        return ['DELIVERED'];
      case 'DELIVERED':
        return ['MISPLACED'];
      case 'CANCELED':
        return [];
      case 'PROCESSING_RETURN':
        return [];
      case 'PARTIAL_RETURN':
        return [];
      case 'TOTAL_RETURN':
        return [];
      case 'MISPLACED':
        return [];
      default:
        return [];
    }
  }
}
