import { Component, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { KeycloakCredentialsService } from '@peca/keycloak';
import { Subject } from 'rxjs';

@Component({
  standalone: true,
  imports: [DesignSystemModule],
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnDestroy {
  router: Router;
  opened: boolean;
  destroyRef$: Subject<void>;

  constructor(private keycloakService: KeycloakCredentialsService) {
    this.router = inject(Router);
    this.opened = false;
    this.destroyRef$ = new Subject<void>();
  }

  get username() {
    return this.keycloakService.credentials?.username;
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  onClickToogleMenu() {
    this.opened = !this.opened;
  }
}
