import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryAddress } from '../models/delivery-address.model';

@Pipe({ name: 'deliveryAddress' })
export class DeliveryAddressPipe implements PipeTransform {
  transform(address: DeliveryAddress) {
    const { street, number, neighborhood, city, state } = address;
    return `${street}, ${number}, ${neighborhood}, ${city} - ${state}`;
  }
}
