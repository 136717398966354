<pds-module-header title="Compradores" ctaLabel="Voltar" ctaPath="/compradores/pessoa-juridica" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-tabs *ngIf="buyerId" [tabs]="tabs" (selected)="handleTabSelectEvent($event)">
    <ng-container *ngFor="let tab of tabs">
      <ng-container *ngIf="tab.target === 'details' && tab.active">
        <peca-buyer-legal />
      </ng-container>
      <ng-container *ngIf="tab.target === 'bill' && tab.active">
        <peca-buyer-bill-list />
      </ng-container>
    </ng-container>
  </pds-tabs>
</pds-module-header>
