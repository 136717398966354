import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: { street: string; number: string; neighborhood: string; city: string; state: string; zipCode: string }) {
    return `${address.street}, ${address.number} - ${address.neighborhood} - ${address.city} - ${address.state} - ${address.zipCode}`;
  }
}
