import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { ToastService } from '@peca/commons';
import { AutocompleteItemSource, MultipleItem, BreadcrumbItem } from '@peca/design-system';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, distinctUntilChanged, filter, mergeMap } from 'rxjs';

import { ConfirmableActionService } from '../../../../core/services/confirmable-action.service';
import { B2BService } from '../../services/b2b.service';
import { B2B } from '../../models/b2b.model';
import { B2BForm } from './b2b-form';
import { B2BRequest } from '../../models/b2b-request.model';

@Component({
  selector: 'peca-b2b-form',
  templateUrl: './b2b-form.component.html',
  styleUrls: ['./b2b-form.component.scss'],
})
export class B2bFormComponent implements OnInit {
  changeDetector: ChangeDetectorRef;
  route: ActivatedRoute;
  toast: ToastService;
  service: B2BService;
  confirmable: ConfirmableActionService;
  form: B2BForm;
  paymentMethods$: BehaviorSubject<any[]>;
  buyers$: BehaviorSubject<AutocompleteItemSource>;
  vouchers$: BehaviorSubject<AutocompleteItemSource>;
  cartStrategies$: BehaviorSubject<MultipleItem[]>;
  breadcrumbItems: BreadcrumbItem[];

  constructor() {
    this.changeDetector = inject(ChangeDetectorRef);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.service = inject(B2BService);
    this.confirmable = inject(ConfirmableActionService);
    this.form = new B2BForm();
    this.paymentMethods$ = new BehaviorSubject<any[]>([]);
    this.buyers$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.vouchers$ = new BehaviorSubject<AutocompleteItemSource>({});
    this.cartStrategies$ = new BehaviorSubject<MultipleItem[]>([
      { id: 'BEST_PRICE', label: 'BEST_PRICE' },
      { id: 'BEST_PRICE_WITH_STOCK', label: 'BEST_PRICE_WITH_STOCK' },
      { id: 'SELLER_AGGREGATE', label: 'SELLER_AGGREGATE' },
    ]);
    this.breadcrumbItems = [{ label: 'Listagem de B2B', path: '/b2b' }];
  }

  get isOperationUpdate() {
    return this.route.snapshot.data['update'] as boolean;
  }

  get value() {
    return this.form.getValue();
  }

  ngOnInit() {
    if (this.isOperationUpdate) {
      this.initUpdateOperation();
    } else {
      this.initCreateOperation();
    }
  }

  onClickSubmit() {
    if (this.form.group.invalid) return;

    const value = this.form.getValue();

    this.service.save(value, this.isOperationUpdate).subscribe({
      next: () => {
        this.toast.success('B2B salvo com sucesso.');
        this.ngOnInit();
      },
      error: (e) => {
        let message = 'Falha ao salvar B2B.';
        if (e.error.errors) message = e.error.errors[0].message;
        this.toast.failure(message);
      },
    });
  }

  onAutocompleteBuyers(search: string) {
    if (search.length < 3) return;

    this.buyers$.next({ loading: true });
    this.service.suggestBuyers(search).subscribe({
      next: (data) => this.buyers$.next({ data }),
      error: () => this.buyers$.next({ failure: true }),
    });
  }

  onAutocompleteVouchers(search: string) {
    if (search.length < 3) return;

    this.vouchers$.next({ loading: true });
    this.service.suggestVouchers(search).subscribe({
      next: (data) => this.vouchers$.next({ data }),
      error: () => this.vouchers$.next({ failure: true }),
    });
  }

  onClickCancel() {
    this.ngOnInit();
  }

  onClickEnable() {
    this.form.group.enable();
  }

  private initCreateOperation() {
    this.breadcrumbItems.push({
      label: 'Novo B2B',
    });

    this.form.addClientIdValidation((value: string) => this.service.validateClientIdAvailability(value));

    this.form.addBuyerValidators((value: string) => this.service.validateBuyerAvailability(value));

    this.form.controls.buyer.valueChanges
      .pipe(
        filter((value) => !!value),
        distinctUntilChanged(),
        mergeMap((value) => this.service.fetchBuyer(value.id)),
        catchError((e) => {
          this.toast.failure('Ocorreu um erro ao obter os dados do comprador.');
          return [];
        })
      )
      .subscribe({
        next: (methods) => {
          this.paymentMethods$.next(methods);
          this.form.group.enable();
        },
      });
  }

  onClickRefreshToken() {
    const message = `Você está atualizando as credenciais do parceiro B2B "${this.value.buyer.label}".`;
    const b2b = this.form.getValue();

    this.confirmable.confirm<B2BRequest>(message, b2b, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      this.service.refreshClientSecret(confirmation.payload.id).subscribe({
        next: (response: B2B) => {
          this.form.setValue(response);
          this.toast.success('Cliente secret atualizado com sucesso.');
          this.ngOnInit();
        },
        error: () => {
          this.toast.failure('Falha ao atualizar o client secret');
        },
      });
    });
  }

  onClickChangeStatus() {
    const { active, buyer } = this.value;
    const operation = active ? 'desativando' : 'ativando';
    const message = `Você está ${operation} o parceiro B2B "${buyer.label}"`;
    const payload = this.value;

    this.confirmable.confirm<B2BRequest>(message, payload).subscribe((confirmation) => {
      if (!confirmation.confirmed) return;

      const b2b = confirmation.payload;

      this.form.controls.active.setValue(!b2b.active);
      this.service.updateStatus(b2b.id, !b2b.active).subscribe({
        next: () => this.toast.success('Status alterado com sucesso'),
        error: () => {
          this.toast.success('Falha ao alterar o status.');
          this.form.controls.active.setValue(b2b.active);
        },
      });
    });
  }

  private initUpdateOperation() {
    const b2bId = this.route.snapshot.params['b2bId'] as number;
    this.breadcrumbItems.push({
      label: 'Editar B2B',
    });
    this.fetchB2BDetails(b2bId);
  }

  private fetchB2BDetails(b2bId: number) {
    this.service.fetchDetails(b2bId).subscribe({
      next: this.onFetchB2BDetailsSuccess.bind(this),
      error: this.onFetchB2BDetailsFailure.bind(this),
    });
  }

  private onFetchB2BDetailsSuccess(b2b: B2B) {
    this.breadcrumbItems[1].label = b2b.buyer.name;
    const strategies = this.cartStrategies$.value.map((v) => ({
      ...v,
      selected: b2b.cartStrategy.includes(v.id as string),
    }));

    this.form.setValue(b2b);
    this.paymentMethods$.next([]);
    this.cartStrategies$.next(strategies);
    this.form.group.disable();
  }

  private onFetchB2BDetailsFailure(e: unknown) {
    this.toast.failure('Ocorreu um erro ao obter os dados do B2B');
    this.form.group.disable();
  }
}
