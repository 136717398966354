import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ProductService } from '../../services/product.service';
import {
  ProductDetails,
  ProductDetailsState,
} from '../../models/product-details.model';
import { ProductDetailsMenu } from '../../models/product-details-menu.model';
import { BreadcrumbItem } from '@peca/design-system';
import { ProductDescriptionPipe } from '../../pipes/product-description.pipe';

@Component({
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  product$ = new BehaviorSubject<ProductDetailsState>({ loading: true });
  destroy$ = new Subject<void>();
  productDescriptionPipe = new ProductDescriptionPipe();

  menu: ProductDetailsMenu[] = [
    { active: true, label: 'Detalhes', key: 'details' },
    { active: false, label: 'Distribuidores', key: 'distributors' },
    { active: false, label: 'Imagens', key: 'images' },
    { active: false, label: 'Similares', key: 'cross' },
  ];
  breadcrumbItems: BreadcrumbItem[] = [
    {
      label: 'Pesquisa de Produtos',
      path: '/produtos',
    },
    {
      label: 'Ver Produto',
    },
  ];

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute
  ) {}

  get activatedMenu() {
    const activated = this.menu.find(({ active }) => active);

    if (activated) return activated;

    return this.menu[0];
  }

  ngOnInit() {
    const { id } = this.route.snapshot.params;
    this.getProductDetails(id);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getProductDetails(id: string) {
    this.product$.next({ loading: true });

    return this.productService
      .getProductDetails(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: this.onGetProductDetailsSuccess.bind(this),
        error: this.onGetProductDetailsFailure.bind(this),
      });
  }

  onGetProductDetailsSuccess(data: ProductDetails) {
    this.product$.next({ data });
    this.breadcrumbItems[1].label = this.productDescriptionPipe.transform(
      data.applications
    );
  }

  onGetProductDetailsFailure() {
    this.product$.next({ failure: true });
  }

  handleItemMenuClickEvent(item: ProductDetailsMenu) {
    this.activatedMenu.active = false;
    item.active = true;
  }
}
