<div class="product-details-distributors">
  <h3 class="mb-24">Distribuidores</h3>

  <pds-form-row [formGroup]="form">
    <pds-checkbox label="Exibir somente distribuidores com estoque" formControlName="onlyInStock"></pds-checkbox>
  </pds-form-row>
  <pds-table>
    <table class="mt-16">
      <thead>
        <tr>
          <th colspan="2">Seller</th>
          <th>Filial</th>
          <th>Preço</th>
          <th>Estoque</th>
          <th>Atualização</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="distributors$ | async; let distributors">
          <ng-container *ngFor="let distributor of distributors">
            <tr>
              <td>{{ distributor.seller }}</td>
              <td>{{ distributor.sellerCode }}</td>
              <td>{{ distributor.branch }}</td>
              <td>{{ distributor.salePrice | currency : 'BRL' }}</td>
              <td>{{ distributor.stock }}</td>
              <td>{{ distributor.updatedAt | date : 'dd/MM/yyyy HH:mm' }}</td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </pds-table>
</div>
