<ng-container *ngIf="products$ | async; let products">
  <ng-container *ngIf="products.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="products.failure">
    <peca-retry context="lista de produtos da compra" (retry)="onRetry()" />
  </ng-container>

  <ng-container *ngIf="products.data && !products.data.length">
    <strong>Este pedido de compra não possui produtos. </strong>
  </ng-container>

  <ng-container *ngIf="products.data && products.data.length">
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Descrição</th>
            <th>PN</th>
            <th>Código</th>
            <th>Marca</th>
            <th>Quantidade</th>
            <th>Custo unt.</th>
            <th>Custo total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of products.data">
            <td>{{ product.description }}</td>
            <td>{{ product.partNumber }}</td>
            <td>{{ product.code || '-' }}</td>
            <td>{{ product.brand }}</td>
            <td>{{ product.deliveredQuantity || product.quantity }}</td>
            <td>{{ product.costPrice | currency : 'BRL' }}</td>
            <td>{{ product.finalCostPrice | currency : 'BRL' }}</td>
          </tr>
        </tbody>
      </table>
    </pds-table>
  </ng-container>
</ng-container>
