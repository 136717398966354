<pds-module-header title="Usuários" ctaLabel="Novo Usuário" ctaPath="/usuarios/novo">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <pds-panel *ngIf="users$ | async; let users">
    <pds-form [formGroup]="form">
      <pds-form-row grid="2-1">
        <pds-form-field label="Busca">
          <input type="text" formControlName="search" placeholder="Nome, sobrenome, e-mail ou CPF" />
        </pds-form-field>

        <pds-form-field label="Status">
          <select formControlName="active">
            <option value="">Selecione</option>
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </select>
        </pds-form-field>
      </pds-form-row>

      <pds-form-actions>
        <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="form.pristine">Limpar filtros</button>
        <button pdsButton type="submit" (click)="onClickSearch()">Buscar</button>
      </pds-form-actions>
    </pds-form>

    <hr />

    <ng-container *ngIf="users.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="users.failure">
      <strong>Não foi possível obter os usuários.</strong>
    </ng-container>

    <ng-container *ngIf="users.data && !users.data?.items?.length">
      <strong>Nenhum usuário cadastrado.</strong>
    </ng-container>

    <ng-container *ngIf="users.data && users.data?.items?.length">
      <pds-table>
        <h3 class="mb-16">{{ users.data.totalItems }} usuários encontrados</h3>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>E-mail</th>
              <th>Cadastro</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users.data.items">
              <td>{{ user.firstName }} {{ user.lastName }}</td>
              <td>{{ user.document }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.audit?.createdAt | date : 'dd/MM/yyyy HH:mm' }}</td>
              <td pdsColActions>
                <pds-switch [(ngModel)]="user.active" (ngModelChange)="onChangeB2BStatus(user)" />
              </td>
              <td pdsColActions>
                <button pdsButton="icon" (click)="onClickDetails(user.id)">
                  <span class="icon">visibility</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)"></pds-pagination>
      </pds-table>
    </ng-container>
  </pds-panel>
</pds-module-header>
