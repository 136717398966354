<pds-module-header class="order-list" title="Devoluções">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel>
    <peca-orders-return-search (search)="onSearchEvent($event)" />

    <hr />

    <ng-container *ngIf="returns.state$ | async; let returns">
      <ng-container *ngIf="returns.data && returns.data.length">
        <pds-table>
          <table>
            <thead>
              <tr>
                <th>Comprador</th>
                <th>Pedido</th>
                <th>Valor Devolução</th>
                <th>Data / Hora</th>
                <th>Status</th>
                <th>Pagamento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let retturn of returns.data" [class.--has-warning]="retturn | invoiceWarning">
                <td>
                  <div class="flex gap-2">
                    <span *ngIf="retturn | invoiceWarning" class="icon invoice-warning" pdsTooltip="Pendente inserção no número da NF de devolução">warning</span>
                    <div class="flex flex-column gap-2">
                      <strong>{{ retturn.buyer.tradingName }}</strong>
                      <small>{{ retturn.buyer.companyName }}</small>
                    </div>
                  </div>
                </td>
                <td>
                  <a pdsChipLink [routerLink]="['/vendas', retturn.order.id]">{{ retturn.order.commerceReference }}</a>
                </td>
                <td>{{ retturn.totalRefundAmount | currency : 'BRL' }}</td>
                <td>{{ retturn.audit.createdAt | date : 'dd/MM/yyyy HH:mm' }}</td>
                <td>
                  <span [pdsChip]="retturn.status | statusColor">{{ retturn.status | status }}</span>
                </td>
                <td>
                  <span [pdsChip]="retturn.payment | statusColor">{{ retturn.payment | status }}</span>
                </td>
                <td pdsColActions>
                  <button pdsButton="icon" title="Ver detalhes" (click)="onClickDetails(retturn)">
                    <span class="icon">visibility</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </pds-table>

        <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)" />
      </ng-container>

      <ng-container *ngIf="returns.loading">
        <strong>carregando...</strong>
      </ng-container>

      <ng-container *ngIf="returns.failed">
        <peca-retry context="Devoluções" (retry)="onRetryEvent()" />
      </ng-container>

      <ng-container *ngIf="returns.data && !returns.data.length">
        <strong>Nenhuma devolução encontrada.</strong>
      </ng-container>
    </ng-container>
  </pds-panel>
</pds-module-header>
