import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Validators } from '@peca/commons';
import { Responsible } from './responsible.model';

type ContactType = 'EMAIL' | 'CELLPHONE' | 'PHONE' | 'WHATSAPP';

export class ResponsibleForm {
  private _group: FormGroup;

  constructor() {
    const email = this.getContactGroup('EMAIL', Validators.email);
    const whatsapp = this.getContactGroup('WHATSAPP', Validators.contactType('WHATSAPP'));
    const cellphone = this.getContactGroup('CELLPHONE', Validators.contactType('CELLPHONE'));
    const phone = this.getContactGroup('PHONE', Validators.contactType('PHONE'));
    const contacts = new FormGroup({ email, phone, cellphone, whatsapp });

    this._group = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      observation: new FormControl(''),
      contacts,
    });
  }

  get controls() {
    return {
      firstName: this.group.get('firstName') as FormControl,
      lastName: this.group.get('lastName') as FormControl,
      observation: this.group.get('observation') as FormControl,
      email: this.group.get('contacts.email') as FormControl,
      phone: this.group.get('contacts.phone') as FormControl,
      cellphone: this.group.get('contacts.cellphone') as FormControl,
      whatsapp: this.group.get('contacts.whatsapp') as FormControl,
      contacts: this.group.get('contacts') as FormGroup,
    };
  }

  get errors() {
    return {
      contacts: {
        contact: this.group.get('contacts')?.errors,
      },
      firstName: {
        required: this.controls.firstName.hasError('required'),
      },
    };
  }

  get value() {
    const firstName = this.controls.firstName.value.trim();
    const lastName = this.controls.lastName.value.trim();
    const observation = this.controls.observation.value.trim();
    const name = `${firstName} ${lastName}`;
    const email = this.controls.email.value;
    const phone = this.controls.phone.value;
    const cellphone = this.controls.cellphone.value;
    const whatsapp = this.controls.whatsapp.value;
    const contacts = [];

    if (email.contact) contacts.push(email);
    if (phone.contact) contacts.push(phone);
    if (cellphone.contact) contacts.push(cellphone);
    if (whatsapp.contact) contacts.push(whatsapp);

    return { name, observation, contacts } as Responsible;
  }

  set value(value: Responsible | null) {
    if (!value) return;

    const { name, observation, contacts } = value;
    const [firstName, ...lastName] = name.trim().split(' ');

    this.controls.firstName.setValue(firstName);
    this.controls.lastName.setValue(lastName.join(' '));
    this.controls.observation.setValue(observation);

    contacts.forEach((c) => {
      switch (c.type) {
        case 'EMAIL':
          this.controls.email.setValue(c);
          break;
        case 'CELLPHONE':
          this.controls.cellphone.setValue(c);
          break;
        case 'PHONE':
          this.controls.phone.setValue(c);
          break;
        case 'WHATSAPP':
          this.controls.whatsapp.setValue(c);
          break;
      }
    });

    this.group.disable();
  }

  get group() {
    return this._group;
  }

  setContactValidators(requireds: any[] = []) {
    if (requireds.includes('EMAIL')) this.group.get('contacts.email.contact')?.addValidators(Validators.required);
    if (requireds.includes('CELLPHONE')) this.group.get('contacts.cellphone.contact')?.addValidators(Validators.required);
    if (requireds.includes('PHONE')) this.group.get('contacts.phone.contact')?.addValidators(Validators.required);
    if (requireds.includes('WHATSAPP')) this.group.get('contacts.whatsapp.contact')?.addValidators(Validators.required);
    if (requireds.includes('AT_LEAST_ONE_PHONE')) this.group.get('contacts')?.setValidators(this.atLeastOneIsRequired());
  }

  private getContactGroup(type: ContactType, ...validators: ValidatorFn[]) {
    return new FormGroup({
      type: new FormControl(type, Validators.required),
      contact: new FormControl('', validators),
    });
  }

  private atLeastOneIsRequired() {
    return () => {
      const { phone, cellphone } = this.controls;

      if (phone.value.contact || cellphone.value.contact) return null;

      return { atLeastOneIsRequired: { message: 'É necessário informar telefone ou celular' } };
    };
  }
}
