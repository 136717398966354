import { inject } from '@angular/core';
import { KeycloakCredentialsService } from '@peca/keycloak';
import { Router } from '@angular/router';
import { environment } from 'apps/backoffice/src/environments/environment';
import { AnalyticsService } from '@peca/commons';

export function securityGuard() {
  return () => {
    const keycloak = inject(KeycloakCredentialsService);
    const analytics = inject(AnalyticsService);
    const router = inject(Router);

    if (!keycloak.credentials) return true;

    const [, credentials] = keycloak.credentials.token.split('.');
    const { resource_access } = JSON.parse(atob(credentials));

    if (!resource_access['backoffice-admin']) {
      setTimeout(() => (window.location.href = environment.storefront.url), 2000);
      return false;
    }

    if (!resource_access['backoffice-admin']['roles'].length) {
      router.navigate(['/403']);
      return false;
    }

    return true;
  };
}
