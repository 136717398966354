import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutocompleteItem, AutocompleteItemSource } from '@peca/design-system';
import { BehaviorSubject, Subject } from 'rxjs';

import { ProductAutocompleteService } from '../../services/product-autocomplete.service';
import { ProductSearchQuery } from '../../models/product-search-query.model';
import { SearchMode } from '../../models/search-mode.model';

@Component({
  selector: 'peca-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent implements OnInit {
  @Input('loading')
  loading$?: Subject<boolean>;

  @Output('search')
  searchEventEmitter: EventEmitter<ProductSearchQuery>;

  @Output('alternate')
  alternate: BehaviorSubject<SearchMode>;

  loading: boolean;

  form: FormGroup;

  brands$: BehaviorSubject<AutocompleteItemSource>;

  brandsHistory$: BehaviorSubject<AutocompleteItemSource>;

  groups$: BehaviorSubject<AutocompleteItemSource>;

  groupsHistory$: BehaviorSubject<AutocompleteItemSource>;

  constructor(
    private fb: FormBuilder,
    private autocompleteService: ProductAutocompleteService
  ) {
    this.searchEventEmitter = new EventEmitter();

    this.alternate = new BehaviorSubject<SearchMode>('STOREFRONT');

    this.brands$ = new BehaviorSubject<AutocompleteItemSource>({});

    this.groups$ = new BehaviorSubject<AutocompleteItemSource>({});

    this.form = this.fb.group({
      brand: this.fb.control(null),
      group: this.fb.control(null),
      search: this.fb.control(''),
      storefrontSearch: this.fb.control(true),
      plate: this.fb.control(''),
      zipcode: this.fb.control(''),
    });

    this.brandsHistory$ = new BehaviorSubject<AutocompleteItemSource>({
      data: this.brandsHistoryFromStorage,
    });

    this.groupsHistory$ = new BehaviorSubject<AutocompleteItemSource>({
      data: this.groupsHistoryFromStorage,
    });

    this.loading = false;
  }

  get brand() {
    return this.form.controls['brand'];
  }

  get group() {
    return this.form.controls['group'];
  }

  get search() {
    return this.form.controls['search'];
  }

  get brandsHistoryFromStorage() {
    return this.autocompleteService.getBrandsHistoryFromStorage();
  }

  get groupsHistoryFromStorage() {
    return this.autocompleteService.getGroupsHistoryFromStorage();
  }

  get disabled() {
    return this.form.invalid || !!this.loading;
  }

  get storefrontSearch() {
    return this.form.controls['storefrontSearch'];
  }

  get plate() {
    return this.form.controls['plate'];
  }

  get zipcode() {
    return this.form.controls['zipcode'];
  }

  ngOnInit(): void {
    this.brand.valueChanges.subscribe(this.onBrandValueChanges.bind(this));
    this.group.valueChanges.subscribe(this.onGroupValueChanges.bind(this));
    this.storefrontSearch.valueChanges.subscribe(
      this.onStorefrontSearchValueChanges.bind(this)
    );
    this.form.setValidators(this.searchValidator.bind(this));
    this.plate.setValidators([Validators.required, this.plateValidator()]);
    this.zipcode.setValidators([Validators.required, this.zipcodeValidator()]);
    this.storefrontSearch.valueChanges.subscribe((value) =>
      this.alternate.next(value ? 'STOREFRONT' : 'BACKOFFICE')
    );

    if (this.loading$) {
      this.loading$.subscribe((loading) => (this.loading = loading));
    }
  }

  onStorefrontSearchValueChanges(selected: boolean) {
    if (!selected) {
      this.plate.clearValidators();
      this.zipcode.clearValidators();
    } else {
      this.plate.setValidators([Validators.required, this.plateValidator()]);
      this.zipcode.setValidators([
        Validators.required,
        this.zipcodeValidator(),
      ]);
    }

    this.plate.updateValueAndValidity();
    this.zipcode.updateValueAndValidity();
  }

  onBrandValueChanges(value: AutocompleteItem) {
    if (!value) return;

    const history = this.autocompleteService.storeBrandsHistory(value);
    this.brandsHistory$.next({ data: history });
  }

  onGroupValueChanges(value: AutocompleteItem) {
    if (!value) return;

    const history = this.autocompleteService.storeGroupsHistory(value);
    this.groupsHistory$.next({ data: history });
  }

  handleGroupsAutocomplete(search: string) {
    if (!search || search.length < 3) {
      this.group.setValue(null);
      this.groups$.next({});

      return;
    }

    this.groups$.next({ loading: true });
    this.autocompleteService.suggestGroups(search).subscribe({
      next: (data) => this.groups$.next({ data }),
      error: () => this.groups$.next({ failure: true }),
    });
  }

  handleBrandsAutocomplete(search: string) {
    if (!search || search.length < 2) {
      this.brand.setValue(null);
      this.brands$.next({});

      return;
    }

    this.brands$.next({ loading: true });
    this.autocompleteService.suggestBrands(search).subscribe({
      next: (data) => this.brands$.next({ data }),
      error: () => this.brands$.next({ failure: true }),
    });
  }

  handleSearchClickEvent() {
    const brand = this.brand.value?.id;
    const group = this.group.value?.id;
    const zipcode = this.zipcode.value.replace(/\D/g, '');

    this.searchEventEmitter.next({ ...this.form.value, zipcode, brand, group });
  }

  plateValidator() {
    return Validators.pattern(
      /[a-z]{3}[0-9]{4}|[a-z]{3}[0-9]{1}[a-z]{1}[0-9]{2}/i
    );
  }

  zipcodeValidator() {
    return Validators.pattern(/\d{5}-?\d{3}/);
  }

  searchValidator() {
    if (this.storefrontSearch.value) {
      return null;
    }

    if (this.brand.value || this.group.value || this.search.value) {
      return null;
    }

    return { invalidSearch: true };
  }
}
