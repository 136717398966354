<div class="order-details-deliveries" *ngIf="freights$ | async; let freights">
  <ng-container *ngIf="freights.data && freights.data.length">
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Transportadora</th>
            <th>Status</th>
            <th>Código</th>
            <th>Data</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let freight of freights.data">
            <tr>
              <td>{{ freight.carrier.companyName }}</td>
              <td>
                <span pdsChip>{{ freight.shipping?.status | freightStatus }}</span>
              </td>
              <td>
                <ng-container *ngIf="freight.shipping && freight.shipping.externalReference; else noShipping">
                  <a [href]="freight.shipping.trackingCode" target="_blank">{{ freight.shipping.externalReference }}</a>
                </ng-container>
                <ng-template #noShipping>
                  <span>-</span>
                </ng-template>
              </td>
              <td>{{ freight.createdAt | date : 'dd/MM/yyyy HH:mm' }}</td>
              <td pdsColActions>
                <button pdsButton="icon" (click)="onClickToggleProducts(freight)">
                  <span class="icon">{{ freight.expanded ? 'expand_less' : 'expand_more' }}</span>
                </button>
              </td>
            </tr>
            <tr [hidden]="!freight.expanded">
              <td colspan="5">
                <h4 class="mb-12">Produtos</h4>
                <table>
                  <thead>
                    <tr>
                      <th>PN</th>
                      <th>Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of freight.products">
                      <td>{{ product.partNumber }}</td>
                      <td>{{ product.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </pds-table>
  </ng-container>

  <ng-container *ngIf="freights.data && !freights.data.length">
    <strong>Este pedido ainda não possuí uma entrega.</strong>
  </ng-container>

  <ng-container *ngIf="freights.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="freights.failed">
    <strong>Não foi possível carregas as entregas do pedido.</strong>
  </ng-container>
</div>
