import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../models/order.model';

type PRICE = 'PRODUCTS' | 'FREIGHT' | 'DISCOUNTS' | 'SUBTOTAL' | 'TOTAL';

@Pipe({ name: 'orderPricing' })
export class OrderPricingPipe implements PipeTransform {
  transform(order: Order | null, price: PRICE) {
    if (!order) return null;

    switch (price) {
      case 'PRODUCTS':
        return order.productsPrice || 0;
      case 'FREIGHT':
        return order.freightAmount || 0;
      case 'DISCOUNTS':
        return order.discounts.amount || 0;
      case 'SUBTOTAL':
        return order.productsPrice + order.freightAmount;
      case 'TOTAL':
        return order.productsPrice + order.freightAmount - order.discounts.amount;
      default:
        return null;
    }
  }
}
