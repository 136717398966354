import { MultipleItem } from '@peca/design-system';

export const BuyerGroupSource: MultipleItem[] = [
  { id: 'Bosch Car Service', label: 'Bosch Car Service' },
  { id: 'Campneus', label: 'Campneus' },
  { id: 'DPaschoal', label: 'DPaschoal' },
  { id: 'Módulos Bosch', label: 'Módulos Bosch' },
  { id: 'Oficina Independente', label: 'Oficina Independente' },
  { id: 'Ultracar', label: 'Ultracar' },
  { id: 'Outros', label: 'Outros' },
];
