<div class="product-details-crossreference">
  <h3 class="mb-24">Similares</h3>

  <ng-container *ngIf="!product.crossReferences.length">
    <h4>Este produto não possui similares</h4>
  </ng-container>

  <ng-container *ngIf="product.crossReferences.length">
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Marca</th>
            <th>Código</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let ref of product.crossReferences">
            <tr>
              <td>{{ ref.brand.name }}</td>
              <td>{{ ref.partNumber }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </pds-table>
  </ng-container>
</div>
