import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators, numeric } from '@peca/commons';
import { Buyer } from '../models/buyer.model';

export class BuyerForm {
  group: FormGroup;

  constructor() {
    this.group = this.getForm();
    this.listenRepeatAddresses();
    this.listenBillingAddress();
  }

  get value() {
    const representativesIds = this.controls.representativesIds.value.map((farmer: any) => farmer.id);

    return {
      create: {
        companyName: this.controls.companyName.getRawValue(),
        tradingName: this.controls.tradingName.getRawValue(),
        cnpj: this.controls.cnpj.getRawValue(),
        averageDailyOccurrences: this.controls.averageDailyOccurrences.getRawValue(),
        officeSize: this.controls.officeSize.getRawValue(),
        responsible: this.controls.responsible.getRawValue(),
        representativesIds: representativesIds,
        billingAddress: this.controls.billingAddress.getRawValue(),
        shippingAddresses: [this.controls.shippingAddress.getRawValue()],
        paymentMethodsIds: this.controls.paymentMethodsIds.getRawValue(),
        buyersGroups: this.controls.buyersGroups.getRawValue(),
        branchOfActivity: this.controls.branchOfActivity.getRawValue(),
        creditLimit: numeric(this.controls.creditLimit.getRawValue()),
        billingRecurrence: this.controls.billingRecurrence.getRawValue(),
        employeeCount: this.controls.employeeCount.getRawValue(),
        carServicedPerDay: this.controls.carServicedPerDay.getRawValue(),
        elevatorCount: this.controls.elevatorCount.getRawValue(),
        productTypes: this.controls.productTypes.getRawValue(),
        stateRegistration: this.controls.stateRegistration.getRawValue(),
        allowFinancingWithOverdueBonds: this.controls.allowFinancingWithOverdueBonds.getRawValue(),
        allowPurchasesWithOverdueBonds: this.controls.allowPurchasesWithOverdueBonds.getRawValue(),
        freeTaxPayer: this.controls.freeTaxPayer.getRawValue(),
        observations: this.controls.observations.getRawValue(),
        origin: this.controls.origin.getRawValue(),
      },
      update: {
        shippingAddress: this.controls.shippingAddress.getRawValue(),
        companyName: this.controls.companyName.getRawValue(),
        tradingName: this.controls.tradingName.getRawValue(),
        averageDailyOccurrences: this.controls.averageDailyOccurrences.getRawValue(),
        officeSize: this.controls.officeSize.getRawValue(),
        responsible: this.controls.responsible.getRawValue(),
        billingAddress: this.controls.billingAddress.getRawValue(),
        representativesIds: representativesIds,
        paymentMethodsIds: this.controls.paymentMethodsIds.getRawValue(),
        buyersGroups: this.controls.buyersGroups.getRawValue(),
        branchOfActivity: this.controls.branchOfActivity.getRawValue(),
        creditLimit: numeric(this.controls.creditLimit.getRawValue()),
        billingRecurrence: this.controls.billingRecurrence.getRawValue(),
        employeeCount: this.controls.employeeCount.getRawValue(),
        carServicedPerDay: this.controls.carServicedPerDay.getRawValue(),
        elevatorCount: this.controls.elevatorCount.getRawValue(),
        productTypes: this.controls.productTypes.getRawValue(),
        stateRegistration: this.controls.stateRegistration.getRawValue(),
        allowFinancingWithOverdueBonds: this.controls.allowFinancingWithOverdueBonds.getRawValue(),
        allowPurchasesWithOverdueBonds: this.controls.allowPurchasesWithOverdueBonds.getRawValue(),
        freeTaxPayer: this.controls.freeTaxPayer.getRawValue(),
        observations: this.controls.observations.getRawValue(),
        origin: this.controls.origin.getRawValue(),
      },
    };
  }

  setValue(buyer: Buyer) {
    this.controls.id.setValue(buyer.id);
    this.controls.active.setValue(buyer.active);
    this.controls.companyName.setValue(buyer.companyName);
    this.controls.tradingName.setValue(buyer.tradingName);
    this.controls.cnpj.setValue(buyer.cnpj);
    this.controls.averageDailyOccurrences.setValue(buyer.averageDailyOccurrences);
    this.controls.officeSize.setValue(buyer.officeSize);
    this.controls.branchOfActivity.setValue(buyer.branchOfActivity);
    this.controls.creditLimit.setValue(buyer.creditLimit);
    this.controls.lastCreditAnalysis.setValue(buyer.lastCreditAnalysis);
    this.controls.responsible.setValue(buyer.responsible);
    this.controls.billingAddress.setValue(buyer.billingAddress);
    this.controls.paymentMethodsIds.setValue(buyer.paymentMethods.map((p) => p.id));
    this.controls.shippingAddress.setValue(buyer.shippingAddresses[0] || null);
    this.controls.buyersGroups.setValue(buyer.buyersGroups);
    this.controls.repeatAddresses.setValue(this.compareAddressess(buyer.billingAddress, buyer.shippingAddresses[0]));
    this.controls.billingRecurrence.setValue(buyer.billingRecurrence || '');
    this.controls.representativesIds.setValue(buyer.representatives || []);
    this.controls.employeeCount.setValue(buyer.employeeCount || '');
    this.controls.carServicedPerDay.setValue(buyer.carServicedPerDay || '');
    this.controls.elevatorCount.setValue(buyer.elevatorCount || '');
    this.controls.productTypes.setValue(buyer.productTypes || []);
    this.controls.stateRegistration.setValue(buyer.stateRegistration || '');
    this.controls.allowFinancingWithOverdueBonds.setValue(buyer.allowFinancingWithOverdueBonds || false);
    this.controls.allowPurchasesWithOverdueBonds.setValue(buyer.allowPurchasesWithOverdueBonds || false);
    this.controls.freeTaxPayer.setValue(buyer.freeTaxPayer || false);
    this.controls.observations.setValue(buyer.observations || '');
    this.controls.origin.setValue(buyer.origin || '');
  }

  get controls() {
    return {
      id: this.group.get('id') as FormControl,
      active: this.group.get('active') as FormControl,
      companyName: this.group.get('companyName') as FormControl,
      tradingName: this.group.get('tradingName') as FormControl,
      cnpj: this.group.get('cnpj') as FormControl,
      averageDailyOccurrences: this.group.get('averageDailyOccurrences') as FormControl,
      officeSize: this.group.get('officeSize') as FormControl,
      branchOfActivity: this.group.get('branchOfActivity') as FormControl,
      creditLimit: this.group.get('creditLimit') as FormControl,
      lastCreditAnalysis: this.group.get('lastCreditAnalysis') as FormControl,
      buyersGroups: this.group.get('buyersGroups') as FormControl,
      shippingAddress: this.group.get('shippingAddress') as FormControl,
      billingAddress: this.group.get('billingAddress') as FormControl,
      paymentMethodsIds: this.group.get('paymentMethodsIds') as FormControl,
      responsible: this.group.get('responsible') as FormControl,
      representativesIds: this.group.get('representativesIds') as FormControl,
      repeatAddresses: this.group.get('repeatAddresses') as FormControl,
      billingRecurrence: this.group.get('billingRecurrence') as FormControl,
      employeeCount: this.group.get('employeeCount') as FormControl,
      carServicedPerDay: this.group.get('carServicedPerDay') as FormControl,
      elevatorCount: this.group.get('elevatorCount') as FormControl,
      productTypes: this.group.get('productTypes') as FormControl,
      stateRegistration: this.group.get('stateRegistration') as FormControl,
      allowFinancingWithOverdueBonds: this.group.get('allowFinancingWithOverdueBonds') as FormControl,
      allowPurchasesWithOverdueBonds: this.group.get('allowPurchasesWithOverdueBonds') as FormControl,
      freeTaxPayer: this.group.get('freeTaxPayer') as FormControl,
      observations: this.group.get('observations') as FormControl,
      origin: this.group.get('origin') as FormControl,
    };
  }

  addBillingRecurrenceValidation() {
    this.controls.billingRecurrence.setValidators([Validators.required]);
    this.controls.billingRecurrence.updateValueAndValidity();
  }

  removeBillingRecurrenceValidation() {
    this.controls.billingRecurrence.setValidators([]);
    this.controls.billingRecurrence.updateValueAndValidity();
  }

  removeFarmer(id: string) {
    const value = this.controls.representativesIds.value;

    this.controls.representativesIds.setValue(value.filter((farmer: any) => farmer.id !== id));
    this.controls.representativesIds.updateValueAndValidity();
  }

  addFarmer(farmer: any) {
    const value = this.controls.representativesIds.value;

    this.controls.representativesIds.setValue([...value, farmer]);
    this.controls.representativesIds.updateValueAndValidity();
  }

  private getForm() {
    return new FormGroup({
      id: new FormControl(null),
      active: new FormControl(true),
      companyName: new FormControl('', [Validators.required]),
      tradingName: new FormControl('', [Validators.required]),
      cnpj: new FormControl('', [Validators.required, Validators.cnpj]),
      averageDailyOccurrences: new FormControl('', [Validators.required, Validators.number.min(0), Validators.number.max(999)]),
      officeSize: new FormControl('', [Validators.required, Validators.oneOf(['PEQUENO', 'MÉDIO', 'GRANDE'])]),
      branchOfActivity: new FormControl('', [Validators.required]),
      creditLimit: new FormControl('', [Validators.required, Validators.number.min(0, true), Validators.number.max(999999, true)]),
      lastCreditAnalysis: new FormControl(''),
      buyersGroups: new FormControl([], [Validators.required]),
      shippingAddress: new FormControl(null, [Validators.required]),
      billingAddress: new FormControl(null, [Validators.required]),
      paymentMethodsIds: new FormControl([], []),
      responsible: new FormControl(null, [this.responsibleValidator]),
      representativesIds: new FormControl([]),
      repeatAddresses: new FormControl(false),
      billingRecurrence: new FormControl('', [Validators.required, Validators.oneOf(['DAILY', 'WEEKLY'])]),
      employeeCount: new FormControl('', [Validators.required, Validators.number.min(0), Validators.number.max(1000)]),
      carServicedPerDay: new FormControl('', [Validators.required, Validators.number.min(0), Validators.number.max(1000)]),
      elevatorCount: new FormControl('', [Validators.required, Validators.number.min(0), Validators.number.max(1000)]),
      productTypes: new FormControl([]),
      stateRegistration: new FormControl('', []),
      allowFinancingWithOverdueBonds: new FormControl(false, []),
      allowPurchasesWithOverdueBonds: new FormControl(false, []),
      freeTaxPayer: new FormControl(false, []),
      observations: new FormControl(''),
      origin: new FormControl(''),
    });
  }

  private compareAddressess(x: any, y: any) {
    if (!x || !y) return false;
    return !Object.keys(x).some((key: any) => x[key] !== y[key]);
  }

  private listenRepeatAddresses() {
    this.controls.repeatAddresses.valueChanges.subscribe((value: boolean) => {
      if (this.controls.repeatAddresses.pristine) return;

      if (value) {
        const { id } = this.controls.shippingAddress.value || {};
        this.controls.shippingAddress.setValue({ ...this.controls.billingAddress.value, id });
        this.controls.shippingAddress.disable();
      } else {
        this.controls.shippingAddress.enable();
        this.group.updateValueAndValidity();
      }
    });
  }

  private listenBillingAddress() {
    this.controls.billingAddress.valueChanges.subscribe((value: any) => {
      if (!this.controls.repeatAddresses.value) return;
      const { id } = this.controls.shippingAddress.value || {};
      this.controls.shippingAddress.setValue({ ...value, id });
    });
  }

  private responsibleValidator(control: AbstractControl) {
    if (!control.value) return { responsible: true };
    return null;
  }
}
