import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
  ProductDetails,
  ProductDistributorBranch,
} from '../../../models/product-details.model';

@Component({
  selector: 'peca-product-details-distributors[product]',
  templateUrl: './product-details-distributors.component.html',
  styleUrls: ['./product-details-distributors.component.scss'],
})
export class ProductDetailsDistributorsComponent implements OnInit, OnDestroy {
  @Input()
  product!: ProductDetails;
  form: FormGroup = new FormGroup({ onlyInStock: new FormControl(false) });
  destroy$ = new Subject<void>();
  distributors$ = new BehaviorSubject<ProductDistributorBranch[]>([]);

  get onlyInStock() {
    return this.form.controls['onlyInStock'];
  }

  get branches() {
    let branches: ProductDistributorBranch[] = [];

    for (const distributor of this.product.distributors) {
      branches = [
        ...branches,
        ...distributor.branches.map((branch) => ({
          ...branch,
          sellerCode: distributor.code,
        })),
      ];
    }

    return branches;
  }

  ngOnInit() {
    this.distributors$.next(this.branches);
    this.onlyInStock.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.onOnlyInStockValueChanges.bind(this));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onOnlyInStockValueChanges(value: boolean) {
    if (!value) return this.distributors$.next(this.branches);

    this.distributors$.next(this.branches.filter((branch) => branch.stock > 0));
  }
}
