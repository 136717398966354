import { Routes } from '@angular/router';
import { ShippingListComponent } from './components/shipping-list/shipping-list.component';

export const routes: Routes = [
  {
    path: '',
    component: ShippingListComponent,
  },
];

export const ShippginsModuleLoad = () => import('./shippings.module').then(({ ShippingsModule }) => ShippingsModule);
