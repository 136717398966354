import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ConfirmableActionService } from '../../services/confirmable-action.service';
import { ConfirmableAction } from '../../models/confirmable-action.model';

@Component({
  selector: 'peca-confirmable-action-2',
  templateUrl: './confirmable-action.component.html',
  styleUrls: ['./confirmable-action.component.scss'],
})
export class ConfirmableActionComponent implements OnInit, OnDestroy {
  destroyRef$: Subject<void>;
  service: ConfirmableActionService;
  action: ConfirmableAction<any> | null;

  constructor() {
    this.service = inject(ConfirmableActionService);
    this.destroyRef$ = new Subject();
    this.action = null;
  }

  ngOnInit() {
    this.service.message
      .pipe(takeUntil(this.destroyRef$))
      .subscribe((action) => (this.action = action));
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  onClickConfirm(confirmed: boolean) {
    const payload = this.action?.payload;
    this.action?.confirmation.next({ confirmed, payload });
    this.action = null;
  }
}
