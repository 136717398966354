import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { environment } from 'apps/backoffice/src/environments/environment';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { BuyerLegalPersonResponse } from '../models/buyer-legal-person/buyer-legal-person-response.model';
import { BuyerLegalPersonQuery } from '../models/buyer-legal-person/buyer-legal-person-query.model';
import { BuyerDocument } from '../models/buyer-document.model';
import { catchError, combineLatest, map, of } from 'rxjs';
import { Buyer } from '../models/buyer.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BuyerLegalPersonService extends BaseHttpClient {
  create(payload: unknown) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers`;
    return this.http.post(resource, payload);
  }

  update(id: string, payload: unknown) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${id}`;
    return this.http.patch(resource, payload);
  }

  fetchDetails(id: string) {
    const details = this.http.get<Buyer>(`${environment.gateway.url}/backoffice/v1/buyers/${id}`);
    const files = this.http.get<Array<BuyerDocument>>(`${environment.gateway.url}/backoffice/v1/buyers/${id}/files`);

    return combineLatest({ details, files });
  }

  fetchBuyers(query: BuyerLegalPersonQuery) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers`;
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<BuyerLegalPersonResponse>>(resource, { params });
  }

  fetchInsights() {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/insights`;
    return this.http.get<any>(resource);
  }

  updateStatus(id: string, status: boolean) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${id}/active`;
    return this.http.patch(resource, { active: status });
  }

  downloadFile(buyerId: string, fileId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${buyerId}/files/${fileId}`;
    return this.download(resource);
  }

  uploadDocument(id: string, files: FormData) {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${id}/files`;
    return this.http.post<Array<BuyerDocument>>(resource, files, { headers });
  }

  updateShippingAddress(buyer: string, address: string, payload: unknown) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${buyer}/addresses`;

    if (!address) {
      return this.http.post(resource, payload);
    }

    return this.http.put(`${resource}/${address}`, payload);
  }

  toogleStatus(id: string, active: boolean) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${id}/active`;
    return this.http.patch(resource, { active });
  }

  deleteDocument(buyer: string, file: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${buyer}/files/${file}`;
    return this.http.delete(resource);
  }

  downloadDocument(buyer: string, file: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${buyer}/files/${file}`;
    const responseType = 'arraybuffer';
    const observe = 'response';

    return this.http.get(resource, { responseType, observe }).pipe(map(this.mapFileDownload));
  }

  suggestFarmers(search: string) {
    const resource = `${environment.gateway.url}/accounts/v1/users/suggestions?search=${search}`;

    return this.http.get<{ firstName: string; lastName: string; id: string; email: string }[]>(resource).pipe(
      map((items) =>
        items.map((item) => ({
          id: item.id,
          email: item.email,
          name: `${item.firstName} ${item.lastName}`,
        }))
      ),
      catchError(() => of(null))
    );
  }

  fetchProductTypes() {
    const resource = `${environment.gateway.url}/backoffice/v1/product-types`;
    return this.http.get<Array<string>>(resource);
  }

  insights() {
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/insights`;
    return this.http.get<any>(resource);
  }

  reject(buyerId: string, reason: string) {
    const status = 'REJECTED';
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${buyerId}/administrative-statuses`;
    return this.http.patch(resource, { reason, status });
  }

  approve(buyerId: string) {
    const status = 'APPROVED';
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/${buyerId}/administrative-statuses`;
    return this.http.patch(resource, { status });
  }
}
