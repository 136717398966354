<pds-modal #modal [width]="960" title="Nova entrega" (dismiss)="onDismissModal()">
  <pds-form [formGroup]="form.group">
    <pds-form-section title="Coletas">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th>Coleta</th>
              <th>Pedidos</th>
              <th>Transportadora</th>
              <th>Ordem</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of form.value.origins; let i = index">
              <td>
                <div class="delivery-aggregation-location">
                  <strong>{{ item.name }}</strong>
                  <span>{{ item.address | deliveryAddress }}</span>
                </div>
              </td>
              <td>
                <div class="delivery-aggregation-orders">
                  <ng-container *ngFor="let reference of item.commerceReferences">
                    <span pdsChip>{{ reference }}</span>
                  </ng-container>
                </div>
              </td>
              <td>{{ item.carrier.companyName }}</td>
              <td>{{ i + 1 }}</td>
              <td pdsColActions>
                <button pdsButton="icon" [disabled]="i === 0" (click)="onClickMoveUp(i, 'origins')"><span class="icon">arrow_upward</span></button>
                <button pdsButton="icon" [disabled]="i === form.value.origins.length - 1" (click)="onClickMoveDown(i, 'origins')"><span class="icon">arrow_downward</span></button>
              </td>
            </tr>
          </tbody>
        </table>
      </pds-table>
    </pds-form-section>

    <pds-form-section title="Destinos">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th>Entrega</th>
              <th>Pedidos</th>
              <th>Tempo</th>
              <th>Distância</th>
              <th>Ordem</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of form.value.destinations; let i = index">
              <td>
                <div class="delivery-aggregation-location">
                  <strong>{{ item.name }}</strong>
                  <span>{{ item.address | deliveryAddress }}</span>
                </div>
              </td>
              <td>
                <div class="delivery-aggregation-orders">
                  <ng-container *ngFor="let ref of item.commerceReferences">
                    <span pdsChip>{{ ref }}</span>
                  </ng-container>
                </div>
              </td>
              <td>{{ item.time }}h</td>
              <td>{{ item.distance | number : '1.1-1' }}km</td>
              <td>{{ i + 1 }}</td>
              <td pdsColActions>
                <button pdsButton="icon" [disabled]="i === 0" (click)="onClickMoveUp(i, 'destinations')"><span class="icon">arrow_upward</span></button>
                <button pdsButton="icon" [disabled]="i === form.value.destinations.length - 1" (click)="onClickMoveDown(i, 'destinations')"><span class="icon">arrow_downward</span></button>
              </td>
            </tr>
          </tbody>
        </table>
      </pds-table>

      <pds-form-row grid="1-1-1">
        <pds-form-field label="Tipo de entrega">
          <select formControlName="deliveryTypeId">
            <option value="">Automático</option>
            <option *ngFor="let type of deliveryTypes$ | async" [value]="type.id">{{ type.publicName }} ({{ type.externalReference }})</option>
          </select>
        </pds-form-field>
      </pds-form-row>

      <pds-form-row grid="1">
        <pds-checkbox label="Entregar no C/D" formControlName="deliveryAtCD" />
      </pds-form-row>

      <pds-form-row grid="1">
        <pds-checkbox label="Integração com transportadora feita manualmente" formControlName="manualShipping" />
      </pds-form-row>

      <pds-form-row grid="1">
        <pds-form-field label="Observações">
          <input type="text" formControlName="observation" />
        </pds-form-field>
      </pds-form-row>
    </pds-form-section>

    <pds-form-actions>
      <pds-button type="button" modifier="outlined" (click)="onClickCancel()">Cancelar</pds-button>
      <pds-button type="submit" (click)="onSubmit()">Acionar transportadora</pds-button>
    </pds-form-actions>
  </pds-form>
</pds-modal>
