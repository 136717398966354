<ng-container *ngIf="roles$ | async as roles">
  <ng-container *ngIf="roles.loading">
    <strong>carregando...</strong>
  </ng-container>
  <ng-container *ngIf="roles.failure">
    <peca-retry context="permissões" (retry)="onRetry()" />
  </ng-container>
  <ng-container *ngIf="roles.data">
    <ul class="roles-groups">
      <li *ngFor="let group of roles.data" class="roles-groups__item">
        <h3 role="button" (click)="onClickExpandGroup(group)">
          <span>{{ group.client | roleName }}</span>
          <span class="icon">{{ group.expanded ? 'unfold_less' : 'unfold_more' }}</span>
        </h3>

        <ul class="roles-modules" [hidden]="!group.expanded">
          <li *ngFor="let module of group.modules" class="roles-modules__item">
            <h4 role="button" (click)="onClickExpandModule(module)">
              <span>{{ module.module | roleName }}</span>
              <span class="icon">{{ module.expanded ? 'unfold_less' : 'unfold_more' }}</span>
            </h4>
            <ul class="roles-permissions" [hidden]="!module.expanded">
              <li *ngFor="let permission of module.roles">
                <pds-checkbox [label]="permission.description" [(ngModel)]="permission.selected" [disabled]="disabled" (ngModelChange)="onChangeRole(permission)" />
              </li>

              <div class="roles-permissions__profiles">
                <button (click)="onClickAllRoles(module)" [disabled]="disabled">todas</button>
                <button (click)="onClickNoRoles(module)" [disabled]="disabled">nenhuma</button>
              </div>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>
</ng-container>
