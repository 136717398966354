import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';

import { environment } from '../../../../environments/environment';
import { DeliveryType } from '../models/delivery-type.model';

@Injectable({ providedIn: 'root' })
export class DeliveryTypeService extends BaseHttpClient {
  private readonly api: string;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1`;
  }

  save(carrierId: string, deliveryType: DeliveryType) {
    if (deliveryType.id) return this.update(carrierId, deliveryType);
    else return this.create(carrierId, deliveryType);
  }

  details(carrierId: string, id: string) {
    const resource = `${this.api}/carriers/${carrierId}/delivery-types/${id}`;
    return this.http.get<DeliveryType>(resource);
  }

  updateStatus(carrierId: string, id: string, active: boolean) {
    const resource = `${this.api}/carriers/${carrierId}/delivery-types/${id}/active`;
    return this.http.patch<DeliveryType>(resource, { active });
  }

  private create(carrierId: string, deliveryType: DeliveryType) {
    const resource = `${this.api}/carriers/${carrierId}/delivery-types`;
    return this.http.post(resource, deliveryType);
  }

  private update(carrierId: string, deliveryType: DeliveryType) {
    const { id } = deliveryType;
    const resource = `${this.api}/carriers/${carrierId}/delivery-types/${id}`;
    return this.http.patch(resource, deliveryType);
  }
}
