<div class="delivery-type" *ngIf="deliveryType">
  <div class="delivery-type__header" [class.--inactive]="!deliveryType.active">
    <h4 class="delivery-type__header__title">
      <span>{{ deliveryType.name }}</span>
      <ng-container *ngIf="!deliveryType.active">
        <small>(inativo)</small>
      </ng-container>
    </h4>
    <div class="delivery-type__header__actions">
      <button title="alterar" (click)="handleEditEvent()">
        <span class="icon">edit</span>
      </button>
      <button title="áreas de atuação" (click)="handleViewRegionsEvent()">
        <span class="icon">route</span>
      </button>
    </div>
  </div>

  <div class="delivery-type__content">
    <div class="delivery-type__content__spec">
      <h5>Custo fixo</h5>
      <span>{{ deliveryType.fixedPrice | currency : 'BRL' }}</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Custo de ativação</h5>
      <span>{{ deliveryType.callPrice | currency : 'BRL' }}</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Custo por KM</h5>
      <span>{{ deliveryType.kmPrice | currency : 'BRL' }}</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Custo por parada</h5>
      <span>{{ deliveryType.stopPrice | currency : 'BRL' }}</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Tempo fixo</h5>
      <span>{{ deliveryType.fixedTime }} min</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Tempo por KM</h5>
      <span>{{ deliveryType.kmTime }} min</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Distância mínima</h5>
      <span>{{ deliveryType.minDistanceKm }}km</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Distância máxima</h5>
      <span>{{ deliveryType.maxDistanceKm }}km</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Peso mínimo</h5>
      <span>{{ deliveryType.minWeightKg }}kg</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Peso máximo</h5>
      <span>{{ deliveryType.maxWeightKg }}km</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Altura máxima</h5>
      <span>{{ deliveryType.maxHeightCm }}cm</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Largura máxima</h5>
      <span>{{ deliveryType.maxWidthCm }}cm</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Comprimento máximo</h5>
      <span>{{ deliveryType.maxLengthCm }}cm</span>
    </div>

    <div class="delivery-type__content__spec">
      <h5>Volume máximo</h5>
      <span>{{ deliveryType.maxDimensionsSumCm }}cm</span>
    </div>
  </div>
</div>
