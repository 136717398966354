import { Pipe, PipeTransform } from '@angular/core';
import { BuyerLegalPersonAdministrativeStatus } from '../models/buyer-legal-person/buyer-legal-person-administrative-status.model';

@Pipe({ name: 'adminStatus' })
export class BuyerAdminStatusPipe implements PipeTransform {
  transform(value: BuyerLegalPersonAdministrativeStatus) {
    switch (value) {
      case 'PENDING':
        return 'Pendente';
      case 'APPROVED':
        return 'Aprovado';
      case 'REJECTED':
        return 'Reprovado';
      default:
        return value;
    }
  }
}
