import { Pipe, PipeTransform } from '@angular/core';
import { Log, LogDirectionType } from '../../models/logs/log.model';

@Pipe({
  standalone: true,
  name: 'log',
})
export class LogPipe implements PipeTransform {
  transform(log: Log, direction: 'from' | 'to') {
    const { changeType } = log;
    let change = undefined;

    switch (changeType) {
      case 'UPDATE_STATUS':
        change = log.internalStatus || log.status;
        break;
      case 'REMOVE_PRODUCT':
      case 'UPDATE_PRODUCT':
        change = log.product;
        break;
      case 'UPDATE_PRICE':
        change = log.finalPrice || log.price;
        break;
      case 'PARTIAL_ORDER':
        change = { from: null, to: log.complementaryOrder };
    }

    if (!change || !change[direction]) return '-' as LogDirectionType;

    return change[direction] as LogDirectionType;
  }
}
