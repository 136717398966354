import { AfterViewInit, Component, inject } from '@angular/core';
import { forwardRefProvider } from '@peca/backoffice/providers/forward-ref.provider';
import { ControlValueAccessor } from '@angular/forms';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { BehaviorSubject } from 'rxjs';
import { isNull } from '@peca/commons';
import { BuyerLegalPersonService } from '../../services/buyer-legal-person.service';

type ProductType = { selected: boolean; value: string };
type State = AsyncState<Array<ProductType>>;

@Component({
  selector: 'peca-buyer-product-types',
  templateUrl: './buyer-product-types.component.html',
  styleUrls: ['./buyer-product-types.component.scss'],
  providers: [forwardRefProvider(BuyerProductTypesComponent)],
})
export class BuyerProductTypesComponent implements AfterViewInit, ControlValueAccessor {
  buyerService: BuyerLegalPersonService;
  value: string[];
  disabled: boolean;
  types$: BehaviorSubject<State>;
  newValue: string;
  changed!: (value: string[]) => void;
  touched!: () => void;

  constructor() {
    this.value = [];
    this.disabled = false;
    this.newValue = '';
    this.types$ = new BehaviorSubject<State>({ loading: true });
    this.buyerService = inject(BuyerLegalPersonService);
  }

  writeValue(value: string[]) {
    this.value = value || [];
  }

  registerOnChange(fn: (value: string[]) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngAfterViewInit() {
    this.buyerService.fetchProductTypes().subscribe({
      next: (data) => {
        this.types$.next({ data: data.map((type) => ({ selected: this.value.includes(type), value: type })) });
      },
      error: () => this.types$.next({ failure: true }),
    });
  }

  onClickAdd() {
    const value = this.newValue;

    if (isNull(value)) return;

    this.value.push(value);
    this.newValue = '';
    this.changed(this.value);
    this.types$.next({ data: [...(this.types$.value.data || []), { selected: true, value: value }] });
    this.touched();
  }

  onCheckType() {
    const values = this.types$.value.data?.filter((type) => type.selected).map((type) => type.value) || [];
    this.value = values;
    this.changed(this.value);
    this.touched();
  }
}
