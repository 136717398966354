<pds-module-header class="order-list" title="Entregas">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <pds-panel>
    <pds-form [formGroup]="searchForm">
      <pds-form-row grid="1-2">
        <pds-form-field label="Status">
          <pds-multiple-select [source]="status$" formControlName="status" />
        </pds-form-field>
        <pds-form-field label="Data do pedido">
          <pds-range-form formControlName="date" />
        </pds-form-field>
      </pds-form-row>
      <pds-form-actions>
        <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="searchForm.pristine">Limpar filtros</button>
        <button pdsButton type="submit" (click)="onClickSearch()">Buscar</button>
      </pds-form-actions>
    </pds-form>

    <hr />

    <ng-container *ngIf="shippings$ | async as shippings">
      <pds-table *ngIf="shippings.data && shippings.data.length">
        <table>
          <thead>
            <tr>
              <th>PA</th>
              <th>Data/Hora</th>
              <th>Status</th>
              <th>Transportadora</th>
              <th>Rastreio</th>
              <th>Observações</th>
            </tr>
          </thead>
          <tr *ngFor="let shipping of shippings.data">
            <td>
              <div style="display: inline-flex; gap: 4px; flex-direction: column">
                <ng-container *ngFor="let order of shipping.orders">
                  <a pdsChipLink="default" [routerLink]="['/vendas', order.id]">{{ order.commercialReference }}</a>
                </ng-container>
              </div>
            </td>
            <td>{{ shipping.createdAt | date : 'dd/MM/yyyy HH:mm' }}</td>
            <td>
              <span [pdsChip]="shipping.status | shippingStatusColor">{{ shipping.status | shippingStatus }}</span>
            </td>
            <td>{{ shipping.commercialName }}</td>
            <td>
              <ng-container *ngIf="shipping.externalReference; else noExternalRefTemplate">
                <a pdsChipLink [href]="shipping.trackingCode">{{ shipping.externalReference }}</a>
              </ng-container>
              <ng-template #noExternalRefTemplate>
                <span>-</span>
              </ng-template>
            </td>
            <td>{{ shipping.observations }}</td>
          </tr>
        </table>
      </pds-table>

      <ng-container *ngIf="shippings.loading">
        <strong>carregando...</strong>
      </ng-container>

      <ng-container *ngIf="shippings.failed">
        <peca-retry context="Entregas" (retry)="onClickSearch()" />
      </ng-container>

      <ng-container *ngIf="shippings.data && !shippings.data.length">
        <strong>Nenhuma entrega encontrada.</strong>
      </ng-container>
    </ng-container>

    <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)" />
  </pds-panel>
</pds-module-header>
