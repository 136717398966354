import { PageableQuery } from '@peca/backoffice/models/pageable-query.model';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { BaseHttpClient } from '@peca/commons';

import { environment } from 'apps/backoffice/src/environments/environment';
import { ShippingListResponse } from '../models/shipping-list-response.model';

export class ShippgingService extends BaseHttpClient {
  private readonly api;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1/shippings`;
  }

  fetchShippings(query: PageableQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<ShippingListResponse>>(this.api, { params });
  }
}
