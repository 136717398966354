<pds-module-header title="Cupons" ctaLabel="Novo Cupom" ctaPath="/cupons/novo">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <div *ngIf="vouchers$ | async; let vouchers">
    <pds-panel>
      <div>
        <pds-form [formGroup]="searchForm">
          <pds-form-row grid="2-1-1">
            <pds-form-field label="Busca">
              <input type="text" formControlName="search" placeholder="Pesquise por nome ou código do cupom" />
            </pds-form-field>
            <pds-form-field label="Data da campanha">
              <pds-date-input formControlName="campaignDate"></pds-date-input>
            </pds-form-field>
            <pds-form-field label="Status">
              <select formControlName="active">
                <option value="">Todos</option>
                <option value="true">Ativos</option>
                <option value="false">Inativos</option>
              </select>
            </pds-form-field>
          </pds-form-row>

          <pds-form-actions>
            <button pdsButton="primary" (click)="clearFilters()" type="button" [disabled]="searchForm.pristine">Limpar filtros</button>
            <button pdsButton type="submit" (click)="onSubmitSearch()">Buscar</button>
          </pds-form-actions>
        </pds-form>
      </div>
    </pds-panel>

    <ng-container *ngIf="vouchers.data?.items?.length">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th rowspan="2">Nome</th>
              <th rowspan="2">Código</th>
              <th colspan="2">Campanha</th>
              <th rowspan="2">Publicado</th>
              <th rowspan="2">Status</th>
              <th rowspan="2"></th>
            </tr>
            <tr>
              <th>Início</th>
              <th>Fim</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of vouchers.data?.items">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.campaignInit ? (item.campaignInit | date : 'dd/MM/yyyy') : '-' }}</td>
                <td>{{ item.campaignEnd ? (item.campaignEnd | date : 'dd/MM/yyyy') : '-' }}</td>
                <td>{{ item.published ? 'Sim' : 'Não' }}</td>
                <td pdsColActions>
                  <pds-switch [(ngModel)]="item.active" (ngModelChange)="onChangeVoucherStatus(item)"></pds-switch>
                </td>
                <td pdsColActions>
                  <button pdsButton="icon" (click)="onClickDetails(item)">
                    <span class="icon">visibility</span>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </pds-table>

      <pds-pagination [pagination]="pagination$" (paginate)="handlePaginateEvent($event)"></pds-pagination>
    </ng-container>

    <ng-container *ngIf="vouchers.data && !vouchers.data?.items?.length">
      <strong>Nenhum cupom encontrado.</strong>
    </ng-container>

    <ng-container *ngIf="vouchers.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="vouchers.failure">
      <strong>Falha ao carregar cupons.</strong>
    </ng-container>
  </div>
</pds-module-header>
