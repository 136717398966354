import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { environment } from 'apps/backoffice/src/environments/environment';

import { OrdersReturnQuery } from '../models/orders-return-search.model';
import { OrderReturn } from '../models/orders-return.model';
import { OrderReturnDetails } from '../models/orders-return-details.model';
import { OrderReturnLog } from '../models/orders-return-logs.model';
import { OrderReturnProduct } from '../models/orders-return-product.model';
import { OrderReturnRequest } from '../models/orders-return-request.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class OrdersReturnService extends BaseHttpClient {
  fetchOrdersReturn(query: OrdersReturnQuery) {
    const params = this.prepareQuery(query);
    const resource = `${environment.gateway.url}/backoffice/v1/returns`;
    return this.http.get<Pageable<OrderReturn>>(resource, { params });
  }

  fetchOrdersReturDetails(id: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/returns/${id}`;
    return this.http.get<OrderReturnDetails>(resource);
  }

  fetchOrderReturnLogs(id: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/returns/${id}/logs`;
    return this.http.get<OrderReturnLog[]>(resource);
  }

  fetchOrderReturnProducts(id: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/returns/${id}/products`;
    return this.http.get<OrderReturnProduct[]>(resource);
  }

  updateOrderReturn(id: string, payload: OrderReturnRequest) {
    const resource = `${environment.gateway.url}/backoffice/v1/returns/${id}`;
    return this.http.patch(resource, payload);
  }

  downloadReturnFile(orderId: string, fileId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/returns/${orderId}/files/${fileId}`;
    return this.download(resource);
  }

  deleteReturnFile(orderId: string, fileId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/returns/${orderId}/files/${fileId}`;
    return this.http.delete(resource);
  }

  uploadReturnFile(orderId: string, file: File) {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    const resource = `${environment.gateway.url}/backoffice/v1/returns/${orderId}/files`;
    const formData = new FormData();

    formData.append('files', file);

    return this.http.post(resource, formData, { headers });
  }
}
