import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'allowedStatus' })
export class OrderReturnAllowedStatusPipe implements PipeTransform {
  transform(current: string) {
    switch (current) {
      case 'PROCESSING':
        return ['PROCESSING', 'IN_TRANSIT'];
      case 'PENDING_COLLECTION':
        return ['PENDING_COLLECTION', 'IN_TRANSIT'];
      case 'IN_TRANSIT':
        return ['IN_TRANSIT', 'PENDING_COLLECTION', 'UNDER_ANALYSIS'];
      case 'UNDER_ANALYSIS':
        return ['UNDER_ANALYSIS', 'APPROVED', 'REJECTED'];
      case 'APPROVED':
        return ['APPROVED'];
      case 'REJECTED':
        return ['REJECTED'];
      default:
        return [current];
    }
  }
}
