<div class="main-navigation">
  <header class="main-navigation__header">
    <div class="main-navigation__header__col --logotype">
      <a routerLink="/" title="Peça Aí - Backoffice">
        <pds-logotype></pds-logotype>
      </a>
    </div>
    <div class="main-navigation__header__col --toolbar"></div>
    <div class="main-navigation__header__col --user-chip"></div>
  </header>
  <div class="main-navigation__content">
    <div class="forbidden">
      <h1>Acesso Negado</h1>
      <p>Você ainda não tem permissões cadastradas para acessar os módulos do backoffice.</p>
      <p>Entre em contato com o suporte para regularizar seus acessos.</p>
    </div>
  </div>
</div>
