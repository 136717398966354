import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[pecaPurchaseToClipboard]',
})
export class PurchaseToClipboardDirective {
  @Input('pecaPurchaseToClipboard')
  purchase: any;

  @Output()
  copy: EventEmitter<boolean>;

  constructor() {
    this.copy = new EventEmitter<boolean>();
  }

  @HostListener('click')
  onClick() {
    if (!this.purchase) return;
    const message = this.generatePurchaseMessage(this.purchase);
    navigator.clipboard
      .writeText(message)
      .then(() => this.copy.emit(true))
      .catch(() => this.copy.emit(false));
  }

  private generatePurchaseMessage(purchase: any) {
    const order = `Novo pedido ${purchase.order.commerceReference}-${purchase.branch.tradingName}-${purchase.branch.externalReference.toLowerCase()}`;
    const products = purchase.products.map((p: any) => `${p.quantity} - ${p.description} - ${p.branch.code}\r\n`).join('');
    const message = `${order}\r\n\r\n${products}`;

    return message;
  }
}
