import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { environment } from '../../../../environments/environment';
import { ActingRegion } from '../models/acting-region.model';
import { switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActingRegionService extends BaseHttpClient {
  private readonly api: string;

  constructor() {
    super();
    this.api = `${environment.gateway.url}/backoffice/v1`;
  }

  create(carrier: string, type: string, region: ActingRegion) {
    const resource = `${this.api}/carriers/${carrier}/delivery-types/${type}/acting-regions`;
    return this.http
      .post(resource, region)
      .pipe(switchMap(() => this.list(carrier, type)));
  }

  list(carrier: string, type: string) {
    const resource = `${this.api}/carriers/${carrier}/delivery-types/${type}/acting-regions`;
    return this.http.get<ActingRegion[]>(resource);
  }

  updateStatus(carrier: string, type: string, region: string, active: boolean) {
    const resource = `${this.api}/carriers/${carrier}/delivery-types/${type}/acting-regions/${region}/active`;
    return this.http
      .patch(resource, { active })
      .pipe(switchMap(() => this.list(carrier, type)));
  }

  delete(carrier: string, type: string, region: string) {
    const resource = `${this.api}/carriers/${carrier}/delivery-types/${type}/acting-regions/${region}`;
    return this.http
      .delete(resource)
      .pipe(switchMap(() => this.list(carrier, type)));
  }

  upload(carrier: string, type: string, file: FormData) {
    const resource = `${this.api}/carriers/${carrier}/delivery-types/${type}/acting-regions`;
    return this.http
      .post(resource, file)
      .pipe(switchMap(() => this.list(carrier, type)));
  }
}
