import { Pipe, PipeTransform } from '@angular/core';
import { Delivery } from '../models/delivery.model';

@Pipe({ name: 'activationTimeColor' })
export class DeliveryActivationTimeColorPipe implements PipeTransform {
  transform(delivery: Delivery) {
    const createdAt = new Date(delivery.createdAt);
    const today = new Date();
    const diff = (today.getTime() - createdAt.getTime()) / 60000;
    const activationTime = delivery.activationTime - diff;

    if (activationTime > 5) return { color: '#f4f4f4', background: '#16a085' };
    if (activationTime > 0) return { color: '#f4f4f4', background: '#f39c12' };

    return { color: '#f4f4f4', background: '#c0392b' };
  }
}
