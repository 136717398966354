import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { BusinessHours, ToastService } from '@peca/commons';
import { MultipleItem } from '@peca/design-system';
import { BehaviorSubject, Subject } from 'rxjs';
import { CarrierService } from '../../services/carrier.service';
import { CarrierForm } from './carrier-form';

@Component({
  selector: 'peca-carrier-form[form]',
  templateUrl: './carrier-form.component.html',
  styleUrls: ['./carrier-form.component.scss'],
})
export class CarrierFormComponent implements OnInit, OnDestroy {
  @Input()
  form!: CarrierForm;

  readonly enabled$: BehaviorSubject<boolean>;
  readonly service: CarrierService;
  readonly toast: ToastService;
  readonly destroyRef$: Subject<void>;
  readonly whiteList$: BehaviorSubject<MultipleItem[]>;
  readonly blackList$: BehaviorSubject<MultipleItem[]>;

  constructor() {
    this.enabled$ = new BehaviorSubject<boolean>(true);
    this.service = inject(CarrierService);
    this.toast = inject(ToastService);
    this.destroyRef$ = new Subject();
    this.whiteList$ = new BehaviorSubject<MultipleItem[]>([]);
    this.blackList$ = new BehaviorSubject<MultipleItem[]>([]);
  }

  ngOnInit() {
    this.service.fetchSellers().subscribe({
      next: this.onFetchSellersSuccess.bind(this),
      error: this.onFetchSellersFailure.bind(this),
    });
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  onFetchSellersSuccess(items: MultipleItem[]) {
    this.blackList$.next([
      ...items.map((item) => ({
        ...item,
        selected: this.form.value.sellersBlacklistIds.includes(item.id),
      })),
    ]);

    this.whiteList$.next([
      ...items.map((item) => ({
        ...item,
        selected: this.form.value.sellersWhitelistIds.includes(item.id),
      })),
    ]);
  }

  onFetchSellersFailure() {
    this.toast.failure('Ocorreu um erro ao listar os vendedores.');
  }

  handleBusinessHoursChanges(businessHours: BusinessHours[]) {
    this.form.setBusinessHours(businessHours);
  }
}
